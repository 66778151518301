import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarComponentList from "./SidebarComponentList";

const AdminSidebarContent = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }
  const list = [
    {
      to: "/orders",
      icon: <i className="mdi mdi-calendar-text"></i>,
      label: props.t("Orders"),
    },
    {
      to: "/quotations",
      icon: <i className="mdi mdi-format-quote-open"></i>, //icon pending
      label: props.t("Quotations"),
    },
    {
      to: "/messenger",
      icon: <i className="mdi mdi-wechat"></i>,
      label: props.t("Messenger"),
    },
    {
      to: "/notifications",
      icon: <i className="mdi mdi-bell-ring-outline"></i>,
      label: props.t("Notifications"),
    },
    {
      to: "/vendors",
      icon: <i className="mdi mdi-store"></i>,
      label: props.t("Vendors"),
    },
    {
      to: "/riders",
      icon: <i className="mdi mdi-bike"></i>,
      label: props.t("Riders"),
    },
    {
      to: "/consumers",
      icon: <i className="mdi mdi-account-multiple"></i>,
      label: props.t("Customers"),
    },
    {
      to: "/transactions",
      icon: <i className="mdi mdi-wallet"></i>,
      label: props.t("Transactions"),
    },
    {
      to: "/promotions",
      icon: <i className="mdi mdi-bullhorn"></i>,
      label: props.t("Vendor Promotions"),
    },
    {
      to: "/categories",
      icon: <i className="mdi  mdi-view-module"></i>,
      label: props.t("Categories"),
    },
    {
      to: "/tags",
      icon: <i className="mdi mdi-tag-outline"></i>,
      label: props.t("Tags"),
    },
    {
      to: "/contacts",
      icon: <i className="mdi mdi-phone"></i>, //icon pending
      label: props.t("Contact Queries"),
    },
    {
      to: "/info-pages",
      icon: <i className="mdi mdi-clipboard-text-multiple-outline"></i>,
      label: props.t("Info Pages"),
    },
    {
      to: "/user-manuals",
      icon: <i className="mdi mdi-file-document"></i>,
      label: props.t("User Manuals"),
    },
    {
      to: "/faqs",
      icon: <i className="mdi mdi-comment-question-outline"></i>,
      label: props.t("Faqs"),
    },
    {
      to: "/settings",
      icon: <i className="mdi mdi-key"></i>, //icon pending
      label: props.t("Settings"),
    },
  ];
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Main")} </li> */}
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Components")}</li>
            <SidebarComponentList list={list} />
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

AdminSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AdminSidebarContent));
