import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "contexts";
import SpinnerLoader from "@components/Loader/SpinnerLoader";

const AuthMiddleware = ({ component: Component, layout: Layout, isAuthProtected = true, role, ...rest }) => {
  let { user, loader } = useContext(UserContext);

  if (loader) {
    return <SpinnerLoader />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !user.roles) {
          //if neither user nor roles exist redirect to login
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        if (isAuthProtected && user.roles && !(user.roles.includes("admin") || user.roles.includes("vendor"))) {
          //if user don't have either vendor or admin role redirect to register
          return <Redirect to={{ pathname: "/register", state: { from: props.location } }} />;
        }

        if (isAuthProtected && user.roles && user.roles.includes("vendor") && !user.first_name) {
          //if user did not complete registration step
          return <Redirect to={{ pathname: "/register", state: { from: props.location } }} />;
        }

        if (["/register", "/login"].includes(rest.path) && user.first_name) {
          //user already registered
          return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />;
        }

        return (
          <Layout role={role}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

AuthMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AuthMiddleware;
