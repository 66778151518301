import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getFullDetails } from "@utils/index";

const InfoPages = ({ isAdmin = true }) => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      formatter: (_cell, _row) => {
        return getFullDetails("info-pages", _row._id, _cell);
      },
    },
  ];

  const title = "Info Pages";

  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={title} />
        <Card>
          <CardBody>
            <CustomTable
              columns={columns}
              actions={true}
              edit={isAdmin ? true : false}
              view={true}
              section="info-pages"
              fetchUrl={isAdmin ? "/info-pages" : "/info-pages?type=vendor"}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default InfoPages;
