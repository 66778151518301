import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import CustomDropzone from "@components/Common/CustomDropzone";
import { get, post, del } from "helpers/api_helper";
import CustomImagePreview from "@components/Common/CustomImagePreview";
import { CustomAlertContext } from "contexts/CustomAlertContext";

const Attachments = (props) => {
  const [displayImages, setDisplayImages] = useState([]);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setSelected([]);
  };
  const imageTitle = "Images";
  const fileTitle = "Files";
  const customAlert = useContext(CustomAlertContext);

  useEffect(() => {
    getAttachments(props.vendorId);
  }, []);

  const getAttachments = (vendorId) => {
    get(`/vendors/` + vendorId).then((res) => {
      setDisplayFiles(res.result.files);
      setDisplayImages(res.result.medias);
    });
  };

  const submit = async () => {
    let imagesArray = [],
      filesArray = [];
    selected.map((file) => {
      if (file.type.includes("image/")) {
        imagesArray.push(file);
      } else {
        filesArray.push(file);
      }
    });

    if (imagesArray.length) {
      let mediaRequestFormData = new FormData();
      mediaRequestFormData.append("vendor_id", props.vendorId);

      imagesArray.map((data) => {
        mediaRequestFormData.append("files", data);
      });

      await post(`/vendors/upload/media`, mediaRequestFormData)
        .then((res) => {
          customAlert.success(res.message);
        })
        .catch((err) => {
          customAlert.error(err.response.data.message);
        });
    }
    if (filesArray.length) {
      let fileRequestFormData = new FormData();

      fileRequestFormData.append("vendor_id", props.vendorId);

      filesArray.map((data) => {
        fileRequestFormData.append("files", data);
      });
      await post(`/vendors/upload/file`, fileRequestFormData)
        .then((res) => {
          customAlert.success(res.message);
        })
        .catch((err) => {
          customAlert.error(err.response.data.message);
        });
    }

    handleClose();
    getAttachments(props.vendorId);
  };

  const handleDiscardSelectedFileFromUploadingList = (file) => {
    let filteredFiles = selected.filter((item) => item.name !== file.name && item.size !== file.size);
    setSelected(filteredFiles);
  };

  const handleDeleteUploadedMedia = (type, id) => {
    del(`/vendor/upload/${type}/${id}?vendor_id=${props.vendorId}`)
      .then((res) => {
        customAlert.success(res.message);
        getAttachments(props.vendorId);
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
      });
  };

  const uploadDrawer = (
    <Offcanvas show={show} onHide={handleClose} placement={"end"} style={{ width: "40vw" }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Upload</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="mt-3">
          <CustomDropzone
            onChange={setSelected}
            allowedTypes={"image/jpeg,image/png,text/plain , application/pdf, application/msword"}
            multiple={true}
          />
        </div>
        <div className="mt-3" style={{ display: "flex", flexWrap: "wrap" }}>
          {selected.map((data, index) => (
            <CustomImagePreview file={data} key={index} handleOnDelete={handleDiscardSelectedFileFromUploadingList} />
          ))}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            right: "0px",
            width: "96%",
          }}
          className="my-2"
        >
          <Button
            color="primary"
            onClick={submit}
            type="submit"
            disabled={!selected.length}
            style={{ width: "inherit" }}
          >
            Upload
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="h4">{imageTitle} </CardTitle>
          <Button color="primary" onClick={handleShow}>
            Upload
          </Button>
          {uploadDrawer}
        </div>
        {displayImages.length ? (
          <div className="my-2" style={{ display: "flex", flexWrap: "wrap" }}>
            {displayImages.map((data) => (
              <CustomImagePreview
                file={data.file_url}
                key={data.id}
                handleOnDelete={() => handleDeleteUploadedMedia("media", data.id)}
              />
            ))}
          </div>
        ) : (
          "No images found!"
        )}
        <CardTitle className="h4 mt-3">{fileTitle} </CardTitle>
        {displayFiles.length ? (
          <div className="my-2" style={{ display: "flex", flexWrap: "wrap" }}>
            {displayFiles.map((data) => (
              <CustomImagePreview
                file={data.file_url}
                key={data.id}
                handleOnDelete={() => handleDeleteUploadedMedia("file", data.id)}
              />
            ))}
          </div>
        ) : (
          "No files found!"
        )}
      </CardBody>
    </Card>
  );
};
export default Attachments;
