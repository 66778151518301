import React from "react";
import Switch from "react-switch";

// const OffSymbol = () => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100%",
//         fontSize: 12,
//         color: "#fff",
//         paddingRight: 2,
//       }}
//     >
//       {" "}
//       off
//     </div>
//   );
// };

// const OnSymbol = () => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100%",
//         fontSize: 12,
//         color: "#fff",
//         paddingRight: 2,
//       }}
//     >
//       {" "}
//       on
//     </div>
//   );
// };

const CustomSwitch = ({ formik, disabled = false, name, label, className = "" }) => {
  return (
    <div className={className}>
      <label htmlFor={name}>{label}</label>
      <div>
        <Switch
          onChange={(value) => formik.setFieldValue(name, value)}
          checked={formik.values[name]}
          disabled={disabled}
          // uncheckedIcon={<OffSymbol />}
          // checkedIcon={<OnSymbol />}
          // onColor="#626ed4"
          // height={20}
          // width={50}
        />
      </div>
    </div>
  );
};

export default CustomSwitch;
