import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import SendDirectMessage from "@components/Messenger/SendDirectMessage";
import { getFullDetails } from "@utils/index";

const columns = [
  {
    dataField: "business_name",
    text: "Business Name",
    formatter: (_cell, _row) => {
      return getFullDetails("vendors", _row._id, _cell);
    },
  },
  {
    dataField: "average_rating",
    text: "Average Rating",
  },
  {
    dataField: "message",
    text: "Message",
    formatter: (_cell, _row) => <SendDirectMessage user_id={_row.user_id} />,
  },
];

const title = "Vendors";

const VendorsPage = () => {
  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={title} btnRedirect="/vendors/add" btnLabel="Add New" />
        <Card>
          <CardBody>
            <CustomTable
              columns={columns}
              actions={true}
              edit={true}
              view={true}
              remove={true}
              section="vendors"
              fetchUrl="vendors"
              searchPlaceholder="Search by business name"
              initialQueryParams={{ status: "all" }}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default VendorsPage;
