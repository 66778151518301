import { APP_NAME } from "utils/index";
import { post, get, errorHandler } from "helpers/api_helper";
import { createContext, useContext, useState } from "react";
import { CustomAlertContext } from "./CustomAlertContext";

export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const customAlert = useContext(CustomAlertContext);
  const [user, setUser] = useState({});
  const [dashboardStatics, setDashboardStatics] = useState({});
  const [loader, setLoader] = useState(true);

  const fetchUser = (snooze_error = false) => {
    if (!loader) {
      setLoader(true);
    }

    return get("consumer/user")
      .then((response) => {
        let { result } = response;
        setUser(result.user);
        return result;
      })
      .catch((err) => {
        if (!snooze_error) {
          //if snooze_error is true means suppress the notification
          customAlert.error(err.response.data.message);
        }
        return null;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const isAdmin = () => {
    if (user && user.roles && user.roles.includes("admin")) {
      return true;
    } else {
      return false;
    }
  };

  const sendMobileOtp = (mobile, country_iso) => {
    return post("vendor/otp-send/mobile", {
      mobile,
      country_iso,
    })
      .then((res) => {
        customAlert.success(res.message);
        return true;
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
        return false;
      });
  };

  const sendVendorMobileOtpUpdate = (mobile, country_iso) => {
    return post("vendors/otp-send/update-mobile", {
      mobile,
      country_iso,
    })
      .then((res) => {
        customAlert.success(res.message);
        return true;
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
        return false;
      });
  };

  const verifyMobileOtp = (mobile, code, countryCode) => {
    return post("vendor/otp-verify/mobile", {
      mobile: mobile,
      otp: code,
      country_iso: countryCode,
      role: "vendor",
    })
      .then((res) => {
        let { result } = res;
        if (result.token) {
          localStorage.setItem(APP_NAME.toLowerCase() + "_token", res.result.token);
        }

        return result;
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
        return null;
      });
  };

  const verifyVendorMobileOtpUpdate = (mobile, code, countryCode) => {
    return post("vendors/otp-verify/update-mobile", {
      mobile: mobile,
      otp: code,
      country_iso: countryCode,
      role: "vendor",
    })
      .then((res) => {
        customAlert.success(res.message);
        return true;
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
        return null;
      });
  };

  const login = (values) => {
    post(`vendor/login`, {
      email: values.email,
      password: values.password,
    })
      .then((res) => {
        customAlert.success(res.message);
        if (res.result.token) localStorage.setItem(APP_NAME.toLowerCase() + "_token", res.result.token);
        window.location.href = "/dashboard";
      })
      .catch((err) => {
        let msg = errorHandler(err);
        customAlert.error(msg);
      });
  };

  const sendEmailOtp = (email) => {
    return post(`vendor/otp-send/email`, {
      email: email,
    })
      .then((res) => {
        customAlert.success(res.message);
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
        return err;
      });
  };

  const verifyEmailOtp = (email, code) => {
    return post("vendor/otp-verify/email", {
      email: email,
      otp: code,
      role: "vendor",
    })
      .then((res) => {
        customAlert.success(res.message);
        if (res.result.token) localStorage.setItem(APP_NAME.toLowerCase() + "_token", res.result.token);
        return res;
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
        return null;
      });
  };

  const handleRegisterVendor = (values) => {
    post(`vendor/`, {
      business_name: values.business_name,
      business_link: values.business_link,
      id_number: values.id_number,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
      passport_number: values.passport_number,
      confirm_password: values.confirm_password,
    })
      .then((res) => {
        customAlert.success(res.message);
        window.location.href = "/dashboard";
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
      });
  };

  const getVendorDashboardStatics = () => {
    get("vendor/dashboard")
      .then((res) => {
        setDashboardStatics(res.result);
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
      });
  };

  const getAdminDashboardStatics = () => {
    get("admin/dashboard")
      .then((res) => {
        setDashboardStatics(res.result);
      })
      .catch((err) => {
        customAlert.error(err.response.data.message);
      });
  };

  return (
    <UserContext.Provider
      value={{
        fetchUser,
        setUser,
        user,
        loader,
        sendMobileOtp,
        verifyMobileOtp,
        login,
        sendEmailOtp,
        verifyEmailOtp,
        handleRegisterVendor,
        getVendorDashboardStatics,
        isAdmin,
        dashboardStatics,
        getAdminDashboardStatics,
        sendVendorMobileOtpUpdate,
        verifyVendorMobileOtpUpdate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
