import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";

import { APP_NAME } from "utils/index";

const CustomMetaTags = ({ title }) => (
  <MetaTags>
    <title>
      {title} | {APP_NAME}
    </title>
  </MetaTags>
);

export default CustomMetaTags;

CustomMetaTags.propTypes = {
  title: PropTypes.string.isRequired,
};
