import React, { useContext } from "react";
import { Card, CardBody } from "reactstrap";

import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { UserContext } from "contexts";
import { getFullDetails } from "@utils/index";

const Promotions = () => {
  const { isAdmin } = useContext(UserContext);
  const columns = [
    {
      dataField: "title",
      text: "Title",
      formatter: (_cell, _row) => {
        return getFullDetails("promotions", _row._id, _cell);
      },
    },
    {
      dataField: "pay_per_click_amt",
      text: "Pay Per Click Amount",
    },
    {
      dataField: "per_day_charges",
      text: "Pay Per Charges",
    },
  ];
  const title = "Promotions";

  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        {isAdmin() ? (
          <Breadcrumb breadcrumbItem={title} btnRedirect="/promotions/add" btnLabel="Add New" />
        ) : (
          <Breadcrumb breadcrumbItem={title} />
        )}
        <Card>
          <CardBody>
            <CustomTable
              columns={columns}
              actions={true}
              edit={isAdmin() ? true : false}
              view={true}
              remove={isAdmin() ? true : false}
              section="promotions"
              fetchUrl="/promotions"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Promotions;
