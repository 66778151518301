import React from "react";
import ChartistGraph from "react-chartist";

// function LineAreaChart({ data }) {
const LineAreaChart = ({ data }) => {
  // var lineChartData = {
  //   labels: data.map((dt) => dt._id.month),
  //   series: [data.map((dt) => dt.total_amount)],
  // };
  var lineChartData = {
    labels: data?.monthly_sell_chart?.labels || [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    series: data?.monthly_sell_chart?.series || [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
  };
  var lineChartOptions = {
    low: 0,
    showArea: true,
  };

  return (
    <React.Fragment>
      <ChartistGraph data={lineChartData} style={{ height: "300px" }} options={lineChartOptions} type={"Line"} />
    </React.Fragment>
  );
};

export default LineAreaChart;
