import React from "react";

const Input = ({
  formik,
  name,
  label,
  placeholder = false,
  type = "text",
  disabled = false,
  child_name = "",
  mywidth,
  setFieldError,
  errorShow = true,
}) => {
  let field_name = name;
  let field_value = child_name && formik.values[name] ? formik.values[name][child_name] : formik.values[name];
  let field_error = child_name && formik.errors[name] ? formik.errors[name][child_name] : formik.errors[name];

  // if (obj && typeof obj.index == "number") {
  //   name = `${obj.p_name}[${obj.index}].${[obj.sub_name]}`;

  //   field_value =
  //     formik.values[obj.p_name] && formik.values[obj.p_name][obj.index]
  //       ? formik.values[obj.p_name][obj.index][obj.sub_name]
  //       : field_value;
  //   field_error =
  //     formik.errors && formik.errors[obj.p_name] && formik.errors[obj.p_name][obj.index]
  //       ? formik.errors[obj.p_name][obj.index][obj.sub_name]
  //       : field_error;
  // } else if (obj && !obj.index) {
  //   name = [obj.p_name][obj.sub_name];
  //   field_value = formik.values[obj.p_name] ? formik.values[obj.p_name][obj.sub_name] : field_value;
  //   field_error = formik.errors && formik.errors[obj.p_name] ? formik.errors[obj.p_name][obj.sub_name] : field_error;
  // }

  const handleChange = (event) => {
    if (!child_name) {
      formik.setFieldValue(name, event.target.value);
    } else {
      let temp = formik.values[name];
      temp[child_name] = event.target.value;
      formik.setFieldValue(name, temp);
    }
  };

  return (
    <div className="my-1">
      <label htmlFor={field_name}>{label}</label>
      <input
        name={field_name}
        onWheel={(e) => e.target.blur()}
        type={type}
        onChange={handleChange}
        value={field_value}
        placeholder={placeholder}
        className="form-control "
        min={0}
        max={mywidth ? 5000 : null}
        disabled={disabled}
        style={{ width: "100%", height: "100%" }}
      />
      {errorShow ? mywidth ? setFieldError(field_error) : <span className="error">{field_error}</span> : null}
    </div>
  );
};

export default Input;
