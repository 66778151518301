import React, { useState, useEffect, useContext, useRef } from "react";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import Chip from "@material-ui/core/Chip";
import { Row, Col, Card, CardBody, Tooltip } from "reactstrap";
import { getFormattedDate } from "@utils/index";
import { CustomAlertContext } from "@contexts/CustomAlertContext";
import { UserContext } from "@contexts/UserContext";
import BootstrapTable from "react-bootstrap-table-next";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getVendorId } from "@utils/index";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getPaymentStatusChip } from "./QuotationsList";

const ItemPrice = ({ disabled = false, defaultValue = 0, handleChange = () => {}, prevPrice = 0, rowIndex }) => {
  const [price, setPrice] = useState(prevPrice ? prevPrice : defaultValue);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const handlePriceChange = (event) => {
    let value = event.target.value;
    if (parseFloat(value) < 0 || isNaN(value) || parseFloat(value) > 5000) {
      return false;
    }

    setPrice(value);
    handleChange(value, rowIndex);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <input
        disabled={disabled}
        onWheel={(e) => e.target.blur()}
        style={{ width: "3rem" }}
        min={0}
        max={50000}
        type="number"
        defaultValue={defaultValue}
        onChange={handlePriceChange}
        value={price}
        onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
      />
      {price ? null : <label style={{ color: "#ec4561", textTransform: "initial" }}>Price must be &gt; 0</label>}
    </div>
  );
};

export const UserDetails = ({ data }) => {
  const senderDetails = data.sender ? (
    <>
      <br />
      {data.sender.name}
      <br />
      {data.sender.mobile}
      <br />
      {data.sender.pickup_address.city}, {data.sender.pickup_address.country}
      <br />
      {data.sender.pickup_address.formatted_address}
      <br />
      {getFormattedDate(data.sender.pickup_time, true)}
    </>
  ) : (
    <p>No data found:</p>
  );

  const receiverDetails = data.recipient ? (
    <>
      <br />
      {data.recipient.name}
      <br />
      {data.recipient.mobile}
      <br />
      {data.recipient.delivery_address.city}, {data.recipient.delivery_address.country}
      <br />
      {data.recipient.delivery_address.formatted_address}
      <br />
      {getFormattedDate(data.recipient.delivery_time, true)}
      <br />
    </>
  ) : (
    <p>No data found:</p>
  );
  return (
    <>
      <div className="col-6">
        <address>
          <strong>Sender:</strong>
          {senderDetails}
        </address>
      </div>
      <div className="col-6 text-end">
        <address>
          <strong>Receiver:</strong>
          {receiverDetails}
        </address>
      </div>
    </>
  );
};
export const Notes = ({ data }) => {
  const instructions = (
    <>
      <strong>Instructions:</strong>
      <p>{data.access_instructions ? data.access_instructions : "Not available."}</p>
    </>
  );

  const description = (
    <>
      <strong>Description:</strong>
      <p>{data.description ? data.description : "No data found:"}</p>
    </>
  );
  return (
    <>
      <div className="col-6 mt-4">
        <address>{instructions}</address>
      </div>
      <div className="col-6 mt-4 text-end">
        <address>{description}</address>
      </div>
    </>
  );
};

const Cancel = ({ Id }) => {
  const [modal, setModal] = useState(false);
  const customAlert = useContext(CustomAlertContext);
  const QuotationStatus = () => {
    put(`/quotations/${Id}/status`, { status: "cancelled" })
      .then((res) => {
        customAlert.setToastNotification(res.message);
        handleModal();
      })
      .catch((err) => {
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
        handleModal();
      });
    window.history.back();
  };
  const handleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      <Button color="primary" className="mb-2" onClick={handleModal}>
        Cancel Quotation
      </Button>
      <Modal isOpen={modal} handleModal={handleModal}>
        <ModalHeader handleModal={handleModal}>Quotation cancellation</ModalHeader>
        <ModalBody>Are you sure to cancel your quotation</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={QuotationStatus}>
            Confirm
          </Button>
          <Button className="btn btn-secondary" onClick={handleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export const PaymentDetails = ({ data, total, subTotal, taxes, adminFee, adminFeePercentage }) => {
  const [showAdminFeeInfo, setShowAdminFeeInfo] = useState(false);
  return (
    <div className="table-responsive">
      <table className="table">
        <tbody>
          <tr>
            <td className="thick-line"></td>
            <td className="thick-line"></td>
            <td className="thick-line text-end">
              <strong>Subtotal</strong>
            </td>
            <td className="thick-line text-end">AU$: {subTotal ? subTotal : 0}</td>
          </tr>
          {data?.payment?.currency_conversion ? (
            <tr>
              <td className="no-line"></td>
              <td className="no-line"></td>
              <td className="no-line text-end">
                <strong>Currency Conversion</strong>
              </td>
              <td className="no-line text-end">AU$: {data?.payment?.currency_conversion}</td>
            </tr>
          ) : null}
          {data?.payment?.stripe_fee > 0 ? (
            <tr>
              <td className="no-line"></td>
              <td className="no-line"></td>
              <td className="no-line text-end">
                <strong>Stripe Fee</strong>
              </td>
              <td className="no-line text-end">AU$: {data?.payment?.stripe_fee}</td>
            </tr>
          ) : null}
          <tr>
            <td className="no-line"></td>
            <td className="no-line"></td>
            <td className="no-line text-end">
              <strong>Local Taxes ({parseFloat(data?.vendor_details?.tax_rate || 0)}%)</strong>
            </td>
            <td className="no-line text-end">AU$: {taxes ? taxes : 0}</td>
          </tr>
          <tr>
            <td className="no-line"></td>
            <td className="no-line"></td>
            <td className="no-line text-end">
              <strong id="adminFeeInfo">
                Admin/Platform Fee ({adminFeePercentage}%) <i className="mdi mdi-information-outline" />
              </strong>
              <Tooltip
                placement="top"
                isOpen={showAdminFeeInfo}
                target="adminFeeInfo"
                toggle={() => setShowAdminFeeInfo(!showAdminFeeInfo)}
              >
                If user choose to pay platform fee then it will not be charged from you.
              </Tooltip>
            </td>
            <td className="no-line text-end">AU$: {adminFee || 0}</td>
          </tr>
          <tr>
            <td className="no-line"></td>
            <td className="no-line"></td>
            <td className="no-line text-end" style={{ textTransform: "unset" }}>
              <strong>Platform Fee (Paid by user?) </strong>
            </td>
            <td className="no-line text-end">{data?.payment?.is_platform_fee_paid_by_user ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td className="no-line"></td>
            <td className="no-line"></td>
            <td className="no-line text-end">
              <strong>Total {data?.payment?.is_platform_fee_paid_by_user ? "" : "(platform fee excluded)"} </strong>
            </td>
            <td className="no-line text-end">
              <h4 className="m-0">AU$: {total || 0}</h4>
            </td>
          </tr>
          {data?.payment?.refund_amount ? (
            <tr>
              <td className="no-line"></td>
              <td className="no-line"></td>
              <td className="no-line text-end">
                {/* {getPaymentStatusChip("accepted", "Refunded Amount")} */}
                <Chip style={{ backgroundColor: "#D8F0CC", color: "#3C9055" }} label={"Refunded Amount"}></Chip>
              </td>
              <td className="no-line text-end">AU$: {data?.payment?.refund_amount}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export const ItemTable = ({ data, quotationId, adminFeePercentage, order = false }) => {
  const [itemsPrices, setItemsPrices] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [adminFee, setAdminFee] = useState(0);
  const [total, setTotal] = useState(0);

  const [disabled, setDisabled] = useState(false);
  const pricesRef = useRef();

  const customAlert = useContext(CustomAlertContext);

  useEffect(() => {
    if (data.id && !order) {
      let prices = [];
      let sub_total = 0;
      let tax = 0;
      let admin_fee = 0;
      let temp_total = 0;
      data.items.map((item, index) => {
        prices.push(item.price || 0);
        sub_total += typeof prices[index] === "string" ? parseInt(prices[index]) : prices[index];
      });
      tax = (sub_total * parseFloat(data?.vendor_details?.tax_rate || 0)) / 100;
      admin_fee = (sub_total * adminFeePercentage) / 100;
      temp_total = sub_total + tax;
      setSubTotal(sub_total);
      setItemsPrices(prices);
      pricesRef.current = prices;
      setTotal(temp_total);

      if (sub_total > 0) {
        setTaxes(tax);
        setAdminFee(admin_fee);
      }
    }
  }, [data]);

  const Columns = [
    {
      dataField: "S.No.",
      text: "S. No.",
      formatter: (_cell, _row, rowIndex) => {
        return <p key={rowIndex}>{++rowIndex}</p>;
      },
    },
    {
      dataField: "name",
      text: "Item",
      formatter: (_cell, _row) => {
        return (
          <>
            <p>Name: {_row.name}</p>
            {_row.weight ? <span> Weight: {`${_row.weight.count} ${_row.weight.unit}`} | </span> : null}
            {_row.volume ? (
              <span>Dimension: {`H(${_row.volume.height}) x L(${_row.volume.length}) x W(${_row.volume.width})`}</span>
            ) : null}
          </>
        );
      },
    },
    {
      dataField: "condition",
      text: "Condition",
    },
    {
      dataField: "quantity",
      text: "Quantity",
    },
    {
      dataField: "price",
      text: "Price",
      formatter: (_cell, _row, rowIndex) => (
        <ItemPrice
          disabled={data.payment_request_status === "sent" || data.payment_request_status === "accepted" || order}
          defaultValue={_row.price ? _row.price : 0}
          handleChange={handleChangePriceSum}
          prevPrice={itemsPrices[rowIndex] || 0}
          key={rowIndex}
          rowIndex={rowIndex}
        />
      ),
    },
  ];

  const handleChangePriceSum = (value, index) => {
    let temp = pricesRef.current;
    temp[index] = parseFloat(value);

    let sub_total = 0;
    let tax = 0;
    let admin_fee = 0;
    let temp_total = 0;

    temp.map((item) => {
      sub_total += item;
    });

    tax = (sub_total * parseFloat(data?.vendor_details?.tax_rate || 0)) / 100;
    admin_fee = (sub_total * adminFeePercentage) / 100;

    temp_total = sub_total + tax;
    setSubTotal(sub_total);
    setTotal(temp_total.toFixed(2));
    setTaxes(tax);
    setAdminFee(admin_fee);

    setItemsPrices(temp);
    pricesRef.current = temp;
  };

  const handleClick = () => {
    setDisabled(true);
    put(`/quotations/${quotationId}/send-request`, { items: itemsPrices })
      .then((res) => {
        customAlert.setToastNotification(res.message);
        window.history.back();
        setDisabled(false);
      })
      .catch((err) => {
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
        setDisabled(false);
      });
    window.reload();
  };

  const paymentButton = !order ? (
    <div className="d-print-none">
      <div className="float-end">
        {data.payment_request_status === "sent" || data.payment_request_status === "accepted" ? (
          getPaymentStatusChip(data.payment_request_status, "Payment Request Already Sent")
        ) : (
          <Button
            color="primary mdi mdi-arrow-right-bold"
            onClick={handleClick}
            disabled={disabled || !itemsPrices.every((item) => item > 0)}
          >
            Send Payment request
          </Button>
        )}
      </div>
    </div>
  ) : null;

  return (
    <>
      <strong>Summary:</strong>
      {data.items ? (
        <BootstrapTable
          keyField="id"
          data={data.items}
          columns={Columns}
          striped
          hover
          wrapperClasses="table-responsive my-2"
        />
      ) : null}
      {data.items && data.items.length ? null : (
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>No data found.</div>
      )}
      <PaymentDetails
        data={data}
        subTotal={subTotal || data?.payment?.sub_total}
        taxes={taxes || data?.payment?.tax_rate}
        adminFee={adminFee || data?.payment?.platform_fee}
        total={total || data?.payment?.final_amount}
        adminFeePercentage={adminFeePercentage}
      />
      {paymentButton}
    </>
  );
};

const Form = (props) => {
  const { data = {}, quotationId, adminFeePercentage } = props;

  const photo =
    data.photos && data.photos.length ? (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.photos.map((item, index) =>
          item.file_url ? (
            <a href={item.file_url} target="_blank" rel="noreferrer" key={index}>
              <Chip style={{ marginRight: "4px", marginBottom: "4px" }} label={`Image ${index + 1}`} />
            </a>
          ) : null
        )}
      </div>
    ) : (
      <p>Not available.</p>
    );

  const attachments =
    data.attachments && data.attachments.length ? (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.attachments.map((item, index) => (
          <div key={index} style={{ margin: "4px" }}>
            {item.file_url ? (
              <a href={item.file_url} target="_blank" rel="noreferrer">
                <Chip style={{ marginRight: "4px", marginBottom: "4px" }} label={`Attachment ${index + 1}`} />
              </a>
            ) : null}
          </div>
        ))}
      </div>
    ) : (
      <p>Not available.</p>
    );

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: "-70px" }}>
        <Row>
          <div className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <div className="col-12">
                    <Row>
                      <div className="invoice-title">
                        <div className="float-end">
                          <h4 className="float-end font-size-16 mb-0">Quotation No: {data?.order_ref_no}</h4>
                          <br />
                          <small style={{ color: "#8c8c8c", float: "right", fontSize: "14px" }}>
                            {data?.created_at ? getFormattedDate(data.created_at, true) : null}
                          </small>
                        </div>
                        <span>
                          <b>{data?.vendor_details?.business_name || ""}</b>
                        </span>
                      </div>
                      <hr />
                    </Row>
                    <Row>
                      <UserDetails data={data} />
                    </Row>
                    <Row>
                      <Notes data={data} />
                    </Row>
                  </div>
                </Row>
                <ItemTable data={data} quotationId={quotationId} adminFeePercentage={adminFeePercentage} />
                <Cancel Id={quotationId} />
                <Row className="col-12">
                  <Col md={12}>
                    <label className="mr-2">Images</label>
                    {photo}
                  </Col>
                  <Col md={12}>
                    <label className="mr-2">Attachments</label>
                    {attachments}
                  </Col>
                  <Col md={12}>
                    <strong>Notes:</strong>
                    <p>{data.notes || "Not available."}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

const QuotationsForm = ({ disabled = false, isAdmin = false, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [adminFeePercentage, setAdminFeePercentage] = useState(3);
  const { user } = useContext(UserContext);
  useEffect(() => {
    get("/settings/platform-fee/slug")
      .then((res) => {
        if (res?.result?.value) {
          setAdminFeePercentage(parseFloat(res?.result?.value));
        }
      })
      .catch(() => {});

    let vendor_id = getVendorId();
    let url;
    if (vendor_id) {
      url = `/quotations/${props.match.params.id}?vendor_id=${vendor_id}`;
    } else {
      url = `/quotations/${props.match.params.id}`;
    }

    get(url).then((res) => {
      const { result } = res;
      setData(result);
      setLoading(false);
    });
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title="View Quotation" />
      <div className="container-fluid">
        {isAdmin ? (
          <Breadcrumb breadcrumb1={{ title: "Quotations", href: `/quotations` }} breadcrumbItem={"View Quotation"} />
        ) : (
          <Breadcrumb
            breadcrumb1={
              user.vendor_id
                ? { title: "Vendor", href: "/vendors/view/" + user.vendor_id }
                : { title: "Quotations", href: "/quotations" }
            }
            breadcrumbItem="View Quotation"
          />
        )}
        {loading ? (
          <SpinnerLoader />
        ) : (
          <>
            <Form
              data={data}
              disabled={disabled}
              isAdmin={isAdmin}
              quotationId={props.match.params.id}
              adminFeePercentage={adminFeePercentage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default QuotationsForm;
