import { APP_NAME } from "@utils/index";
import { API_BASE_URL } from "helpers/api_helper";
import React, { useEffect } from "react";
import io from "socket.io-client";

const socket = io(API_BASE_URL, {
  transports: ["websocket"],
  forceNew: true,
  reconnection: false,
  autoConnect: false,
  auth: {
    authorization: "Bearer " + localStorage.getItem(APP_NAME.toLowerCase() + "_token"),
  },
  query: {
    authorization: "Bearer " + localStorage.getItem(APP_NAME.toLowerCase() + "_token"),
  },
});

const SocketConnection = ({ handleReceivedMessage }) => {
  useEffect(() => {
    socket.on("connect", () => {
      console.log("connect socket");
    });

    socket.on("message-receive", (e) => {
      handleReceivedMessage(e);
    });

    socket.on("message", () => {
      // console.log("socket event ---message ", e);
    });

    socket.on("disconnect", () => {});

    socket.connect();
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);
  return <></>;
};

export default SocketConnection;
