import { Link } from "react-router-dom";

const PrimaryColumn = ({ section, item_id, label, vendor_id }) =>
  vendor_id ? (
    <Link to={`/${section}/view/${item_id}?vendor_id=${vendor_id}`}>{label}</Link>
  ) : (
    <Link to={`/${section}/view/${item_id}`}>{label}</Link>
  );

export default PrimaryColumn;
