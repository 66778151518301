import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
//i18n
import { withTranslation } from "react-i18next";
import { get, put } from "helpers/api_helper";

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  // const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    getNotifications();
    // getUnReadNotificationsCount();
  }, []);

  const getNotifications = () => {
    get(`/notifications?page_no=1&page_size=5`)
      .then((res) => {
        const { result } = res;
        setNotificationData(result.data || []);
      })
      .catch(() => {});
  };

  // const getUnReadNotificationsCount = () => {
  //   get(`/notifications/count`)
  //     .then((res) => {
  //       setNotificationCount(res.result);
  //     })
  //     .catch(() => {
  //       //
  //     });
  // };

  const readNotification = () => {
    put("view-all-notification").then(() => {
      //
    });
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" tag="li">
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          {/* {notificationCount ? (
            <span className="badge bg-danger rounded-pill">
              <span className="notification-count">{notificationCount}</span>
            </span>
          ) : null} */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {props.t("Notifications")}
                  {/* {notificationCount ? `(${notificationCount})` : null} */}
                </h6>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: "230px" }}>
            {notificationData.length ? (
              notificationData.map((notification, index) => (
                <div className="d-flex" key={index}>
                  <div className="avatar-xs m-1 mr-2">
                    <span className="avatar-title rounded-circle">
                      <i className="ti-bookmark" />
                    </span>
                  </div>
                  <div className="flex-1">
                    <h6 className="mt-0 mb-1">{notification.data.title}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{notification.data.message}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="m-1 ml-3">No notifications available.</div>
            )}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications"
              onClick={readNotification}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i> {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
