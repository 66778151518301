import React from "react";
import { Modal } from "reactstrap";

const ConfirmationModal = ({ isOpenModal, toggleModal, deleteConfirmItem, label = "this" }) => {
  const handleClose = () => toggleModal(!isOpenModal);
  return (
    <Modal isOpen={isOpenModal} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" style={{ color: "#fdab00" }}>
          Delete
        </h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose} />
      </div>
      <div className="modal-body">
        <p>Are you sure you want to delete {label}?</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={deleteConfirmItem}>
          Confirm
        </button>
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
