import React from "react";
import { Card, CardBody } from "reactstrap";
import { UserContext } from "contexts";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import SendDirectMessage from "@components/Messenger/SendDirectMessage";
import { getPaymentStatusChip } from "@pages/Quotations/QuotationsList";
import PrimaryColumn from "@components/Common/PrimaryColumn";

const Riders = () => {
  let { user, isAdmin } = React.useContext(UserContext);

  const columns = [
    {
      dataField: "first_name",
      text: "Name",
      formatter: (_cell, _row) => (
        <PrimaryColumn
          section={"riders"}
          item_id={_row._id}
          label={_row.first_name || "Not Available" + " " + (_row.last_name || "")}
        />
      ),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "mobile",
      text: "Mobile",
    },
    {
      dataField: "message",
      text: "Message",
      formatter: (_cell, _row) => <SendDirectMessage user_id={_row.id} />,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (_cell) => {
        if (_cell == true) {
          return getPaymentStatusChip("accepted", "Active");
        }
        return getPaymentStatusChip("declined", "In-Active");
      },
    },
  ];

  const title = "Riders";

  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={title} btnRedirect="/riders/add" btnLabel="Add New" />
        <Card>
          <CardBody>
            <CustomTable
              columns={columns}
              actions={true}
              edit
              view={true}
              remove={isAdmin() ? true : false}
              section="riders"
              fetchUrl="/riders"
              searchPlaceholder="Search by name or email"
              showVendorSelection={isAdmin()}
              vendorId={isAdmin() ? "" : user.vendor_id}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Riders;
