import React from "react";
import Dropzone from "react-dropzone";

const CustomDropzone = ({
  onChange = () => {},
  allowedTypes = "",
  maxSize = 10 * 1000000, // 10mb
  multiple = true,
}) => {
  return (
    <Dropzone onDrop={onChange} accept={allowedTypes} maxSize={maxSize} multiple={multiple}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps()}
            style={{
              border: "1px dashed #ced4da",
              borderRadius: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
            className="my-1"
          >
            <input {...getInputProps()} />
            <span>Drag &apos;n&apos; drop some files here, or click to select files</span>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default CustomDropzone;
