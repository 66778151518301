import React, { useState, useEffect, useContext } from "react";
import { Button, Card, CardBody, CardHeader, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { errorHandler, get, post } from "helpers/api_helper";
import { CustomAlertContext } from "@contexts/CustomAlertContext";
import { UserContext } from "@contexts/UserContext";
import { Spinner, Badge } from "react-bootstrap";

const StripeConnect = ({ vendorId }) => {
  const [connectedAccount, setConnectedAccount] = useState({});
  const [showStripeConnectedAccount, setShowStripeConnectedAccount] = useState(false);
  const customAlert = useContext(CustomAlertContext);
  const { isAdmin } = useContext(UserContext);

  useEffect(() => {
    getVendorConnectedStripeAccountDetails();
  }, []);

  const getVendorConnectedStripeAccountDetails = () => {
    get("vendors/stripe/" + vendorId)
      .then((response) => {
        if (response?.result?.capabilities) {
          setConnectedAccount(response.result);
        }
      })
      .catch(() => {
        //
      });
  };

  const handleConnectPayoutAccount = () => {
    setShowStripeConnectedAccount(true);
    post("vendors/stripe", { vendor_id: vendorId, redirect_link: window.location.href })
      .then((response) => {
        if (response?.result?.url) {
          window.open(response.result.url, "_blank");
        }
      })
      .catch((error) => {
        let msg = errorHandler(error);
        customAlert.error(msg);
      })
      .finally(() => {
        setShowStripeConnectedAccount(false);
      });
  };

  const manageAccountBtn = !isAdmin() ? (
    <div style={{ display: "flex", justifyContent: "end", marginBottom: "1rem" }}>
      <Button color="primary" onClick={handleConnectPayoutAccount} disabled={showStripeConnectedAccount}>
        {showStripeConnectedAccount ? <Spinner size="sm" color="primary" /> : "Manage Payout Account?"}
      </Button>
    </div>
  ) : null;

  return (
    <Row style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
      <Col md={9}>
        <Card>
          {manageAccountBtn}
          <CardHeader>Connected Payout Account Details</CardHeader>
          <CardBody>
            {connectedAccount.capabilities ? (
              <div>
                <ListGroup>
                  <ListGroupItem>
                    Account Capabilities:{" "}
                    <Button
                      color={connectedAccount?.capabilities?.card_payments == "active" ? "success" : "warning"}
                      size="sm"
                      outline
                      disabled
                    >
                      Card Payments : {connectedAccount?.capabilities?.card_payments}
                    </Button>{" "}
                    <Button
                      color={connectedAccount?.capabilities?.transfers == "active" ? "success" : "warning"}
                      size="sm"
                      outline
                      disabled
                    >
                      A/c Transfers : {connectedAccount?.capabilities?.transfers}
                    </Button>
                  </ListGroupItem>
                  <ListGroupItem>Country : {connectedAccount.country} </ListGroupItem>
                  <ListGroupItem>Currency : {connectedAccount.default_currency} </ListGroupItem>
                  <ListGroupItem>Stripe connected email : {connectedAccount.email} </ListGroupItem>
                  <ListGroupItem>
                    Attached account ({connectedAccount.external_accounts?.data?.[0]?.object}):{" "}
                    {connectedAccount.external_accounts?.data?.[0]?.bank_name}
                  </ListGroupItem>
                  <ListGroupItem>
                    Requirements Due :{" "}
                    {connectedAccount.requirements &&
                    connectedAccount.requirements.currently_due &&
                    connectedAccount?.requirements?.currently_due.length
                      ? connectedAccount.requirements.currently_due.map((item) => (
                          <Badge key={item} className="mr-1">
                            {item}
                          </Badge>
                        ))
                      : "No Due"}
                  </ListGroupItem>
                </ListGroup>
              </div>
            ) : (
              <p>Yet, vendor did not connect there bank account details.</p>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default StripeConnect;
