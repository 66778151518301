import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import ListTopBar from "@components/Common/ListTopBar";
import { getFormattedDate } from "@utils/index";
// import Switch from "react-switch";
import { getPaymentStatusChip } from "@pages/Quotations/QuotationsList";
import { getFullDetails } from "@utils/index";

const OffersList = (props) => {
  const Columns = [
    {
      dataField: "code",
      text: "Code",
      formatter: (_cell, _row) => {
        return getFullDetails("offers", _row._id, _cell, props.vendorId);
      },
    },
    {
      dataField: "value",
      text: "Value",
      formatter: (_cell, _row) => {
        if (_row.type == "percentage") {
          return <p>{_cell}%</p>;
        } else {
          return <p>${_cell}/-</p>;
        }
      },
    },
    {
      dataField: "started_at",
      text: "Started at",
      formatter: (_cell) => getFormattedDate(_cell),
    },
    {
      dataField: "expired_at",
      text: "Expired by",
      formatter: (_cell) => getFormattedDate(_cell),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (_cell) => {
        if (_cell == true) {
          return getPaymentStatusChip("accepted", "Active");
        }
        return getPaymentStatusChip("declined", "Deactivate");
      },
    },
  ];
  return (
    <Card>
      <CardBody>
        <ListTopBar btnRedirect={`/offers/add?vendor_id=${props.vendorId}`} />
        <CustomTable
          columns={Columns}
          actions={true}
          edit={true}
          view={true}
          vendorId={props.vendorId}
          section="offers"
          fetchUrl={`/offers`}
        />
      </CardBody>
    </Card>
  );
};

export default OffersList;
