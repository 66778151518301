import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import { getFormattedDate, getVendorId } from "@utils/index";
import CustomTable from "@components/Common/Table";

const columns = [
  {
    dataField: "cv_url",
    text: "Applicants CV",
    formatter: (_cell) => (
      <a href={_cell} target="_blank" rel="noreferrer">
        <i className="ti-file mr-1" />
        CV
      </a>
    ),
  },
  {
    dataField: "created_at",
    text: "Applied on",
    formatter: (_cell) => getFormattedDate(_cell),
  },
];

const FormSchema = Yup.object().shape({
  title: Yup.string()
    .strict()
    .required(yupMessages.required)
    .max(30)
    .matches(/^[a-zA-Z ]+$/, "Title should not contain special characters and number"),
  status: Yup.boolean().required(yupMessages.required),
});

const Form = (props) => {
  let vendor_id = getVendorId();

  const { disabled = false, item_id, data = {} } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/jobs/${item_id}`, data) : (data) => post(`/jobs`, data);
    let data = new FormData();
    data.append("title", values.title);
    data.append("status", values.status);
    data.append("vendor_id", vendor_id);

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = `/vendors/view/${vendor_id}?tab=4`;
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      title: data.title || "",
      status: typeof data.status === "boolean" ? data.status : true,
      total_applicants: data.total_applicants || 0,
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const title = (
    <Col className="col-6">
      <CustomInput formik={formik} name="title" label={"Title"} placeholder="Enter title" disabled={disabled} />
    </Col>
  );

  const totalApplicants = (
    <Col className="col-6">
      <CustomInput formik={formik} name="total_applicants" label={"Total Applicants"} placeholder="" disabled={true} />
    </Col>
  );

  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {title}
            {disabled ? totalApplicants : null}
            {submit_btn}
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

const JobsForm = ({ disabled = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);
  let vendor_id = getVendorId();

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/jobs/${props.match.params.id}?vendor_id=${vendor_id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Jobs"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Vendor", href: `/vendors/view/${vendor_id}` }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Jobs"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form data={data} item_id={editInfo ? props.match.params.id : undefined} disabled={disabled} />
        )}
        {disabled && data.total_applicants ? (
          <>
            <div>
              <b>Applicants</b>
            </div>
            <CustomTable columns={columns} section="jobs" initialData={data.applicants || []} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default JobsForm;
