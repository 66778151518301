import React, { useState, useEffect, useContext } from "react";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import Breadcrumb from "@components/Common/Breadcrumb";

const FormSchema = Yup.object().shape({
  value: Yup.string().required(yupMessages.required),
  title: Yup.string().strict().trim().required(yupMessages.required).max(200),
  slug: Yup.string().strict().trim().required(yupMessages.required).max(200),
});

const Form = (props) => {
  const { disabled = false, item_id, data = {} } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/settings/${item_id}`, data) : (data) => post(`/settings`, data);

    let data = new FormData();
    data.append("title", values.title);
    data.append("value", values.value);
    if (!item_id) {
      data.append("slug", values.slug);
    }
    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = "/settings";
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      title: data.title || "",
      slug: data.slug || "",
      value: data.value || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const title = (
    <Col className="col-6">
      <CustomInput formik={formik} name="title" label={"Title"} placeholder="Enter title" disabled={disabled} />
    </Col>
  );
  const slug = (
    <Col className="col-6">
      <CustomInput formik={formik} name="slug" label={"Slug"} placeholder="Enter slug" disabled={true} />
    </Col>
  );

  const value = (
    <Col className="col-6">
      <CustomInput formik={formik} name="value" label={"Value"} placeholder="Enter value" disabled={disabled} />
    </Col>
  );
  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {title}
            {value}
            {item_id ? null : slug}
            {submit_btn}
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

const SettingsForm = ({ disabled = false, isAdmin = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/settings/${props.match.params.id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);
  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Settings"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Settings", href: "/settings" }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Settings"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form
            data={data}
            item_id={editInfo ? props.match.params.id : undefined}
            disabled={disabled}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </div>
  );
};

export default SettingsForm;
