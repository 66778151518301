import React from "react";
import { Card, CardBody } from "reactstrap";
import { UserContext } from "contexts";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import Chip from "@material-ui/core/Chip";
import { getFullDetails } from "@utils/index";

export const getPaymentStatusChip = (value, label = "") => {
  let styling = { backgroundColor: "#fcfcf9", color: "#719093", textTransform: "uppercase" };

  if (value == "accepted") {
    styling.backgroundColor = "#d8f0cc";
    styling.color = "#3c9055";
  } else if (value == "sent") {
    styling.backgroundColor = "#fbf2cf";
    styling.color = "#eca213";
  } else if (value == "declined") {
    styling.backgroundColor = "#fbd9ce";
    styling.color = "#e93641";
  } else if (value == "confirmed") {
    styling.backgroundColor = "#FAAB02";
    styling.color = "#FFFFFF";
  }

  return <Chip style={styling} label={label || value || "Pending"} />;
};

const Quotations = (props) => {
  let { user, isAdmin } = React.useContext(UserContext);

  const columns = [
    {
      dataField: "id",
      text: "Quotation Number",
      formatter: (_cell, _row) => getFullDetails("quotations", _row.id, _row.order_ref_no || _row.id, props.vendorId),
    },
    {
      dataField: "items",
      text: "Item's Count",
      formatter: (_cell) => {
        return <p>{_cell.length}</p>;
      },
    },
    // {
    //   dataField: "payment",
    //   text: "Quote Amount",
    //   formatter: (_cell) => {
    //     return <p>{_cell?.final_amount ? _cell?.final_amount : "N/A"}</p>;
    //   },
    // },
    {
      dataField: "payment_request_status",
      text: "Payment Request",
      formatter: (_cell) => getPaymentStatusChip(_cell),
    },
  ];

  const title = "Quotations";
  return (
    <>
      {props.vendorId ? (
        <Card>
          <CardBody>
            <CustomTable
              columns={columns}
              actions={true}
              view={true}
              vendorId={props.vendorId}
              section="quotations"
              fetchUrl={`/quotations?vendor_id=${props.vendorId}`}
            />
          </CardBody>
        </Card>
      ) : (
        <div className="page-content">
          {<CustomMetaTags title={title} />}
          <div className="container-fluid">
            {<Breadcrumb breadcrumbItem={title} />}
            <Card>
              <CardBody>
                <CustomTable
                  columns={columns}
                  actions={true}
                  view={true}
                  section="quotations"
                  fetchUrl={`/quotations`}
                  searchPlaceholder="Search by quotation number"
                  showVendorSelection={isAdmin()}
                  vendorId={isAdmin() ? "" : user.vendor_id}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default Quotations;
