import React, { useState, useEffect, useContext } from "react";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { API_PDF_URL, errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import Chip from "@material-ui/core/Chip";
import { Row, Col, Card, CardBody, InputGroup, Input, Alert } from "reactstrap";
import { getFormattedDate } from "@utils/index";
import { CustomAlertContext } from "@contexts/CustomAlertContext";
import BootstrapTable from "react-bootstrap-table-next";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getVendorId } from "@utils/index";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getPaymentStatusChip } from "@pages/Quotations/QuotationsList";
import Select from "react-select";
import StarRatings from "react-star-ratings";
import { Spinner } from "react-bootstrap";
import { UserContext } from "contexts";
import SendDirectNotification from "@components/Common/SendDirectNotification";
import SendDirectMessage from "@components/Messenger/SendDirectMessage";
import Stepper from "react-stepper-horizontal";

import cancel from "../../assets/cancel.png";
import check from "../../assets/check.png";
import pending from "../../assets/pending.gif";

import { UserDetails } from "@pages/Quotations/QuotationForm";
import { Notes } from "@pages/Quotations/QuotationForm";
import { ItemTable } from "@pages/Quotations/QuotationForm";

const HandleOrderStatus = ({ order, handleCancelClick }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(
    order.status == "confirmed"
      ? { label: "Confirmed", value: "confirmed" }
      : { label: "Picked Up", value: "picked-up" }
  );
  const customAlert = useContext(CustomAlertContext);

  if (!order.status) {
    return null;
  }

  let options =
    order.status == "confirmed"
      ? [
          { label: "Confirmed", value: "confirmed" },
          { label: "Picked Up", value: "picked-up" },
        ]
      : [
          { label: "Confirmed", value: "confirmed", isDisabled: true },
          { label: "Picked Up", value: "picked-up" },
          { label: "Delivered", value: "delivered" },
        ];
  const handleConfirmChangeStatus = () => {
    setLoading(true);
    put(`/orders/${order.id}/status`, { status: status.value })
      .then((res) => {
        customAlert.setToastNotification(res.message);
        window.history.back();
      })
      .catch((err) => {
        customAlert.setToastNotification(errorHandler(err), "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Row>
      {order.request_riders.length ? <strong>Change order status:</strong> : null}
      <Col md={3}>
        {order.request_riders.length ? <Select value={status} onChange={setStatus} options={options} /> : null}
      </Col>
      <Col>
        {order.request_riders.length ? (
          <Button
            onClick={handleConfirmChangeStatus}
            disabled={status.value === order.status || loading}
            color={status.value === order.status ? "secondary" : "success"}
          >
            {loading ? <Spinner size="sm" color="primary" /> : "Save"}
          </Button>
        ) : null}
      </Col>
      <Col md={2} style={{ display: "flex", justifyContent: "end" }}>
        <Button className="text-end" color="primary" onClick={handleCancelClick}>
          Cancel Order?
        </Button>
      </Col>
    </Row>
  );
};

const HandleOrderAssignRequests = ({ order }) => {
  const [riders, setRiders] = useState([]);
  const [ridersList, setRidersList] = useState([]);
  const [ridersPlainList, setRidersPlainList] = useState([]);
  const [sentRequestsList, setSentRequestsList] = useState(order.request_riders || []);
  const [loading, setLoading] = useState(false);
  const customAlert = useContext(CustomAlertContext);

  useEffect(() => {
    getOrderSentRidersRequests();
    getVendorRidersList();
  }, []);

  useEffect(() => {
    setSentRequestsList(order.request_riders);
  }, [order.request_riders]);

  const getOrderSentRidersRequests = () => {
    get("/orders/rider/request/" + order.id)
      .then((res) => {
        if (res?.result && res.result.length) setSentRequestsList(res.result);
      })
      .catch(() => {
        //
      });
  };

  const getVendorRidersList = () => {
    get(`/riders?rider_vendor_id=${order.vendor_id}&page_size=10000`).then((res) => {
      const { result } = res;
      let riderOptions = { options: [{ label: "Select Rider", value: "", isDisabled: true }] };

      if (result.data && result.data.length) {
        result.data.map((data) => {
          riderOptions.options.push({ label: `${data.first_name} ${data.last_name}`, value: data.id });
        });
      } else {
        riderOptions = { options: [{ label: "No Rider Available", value: "", isDisabled: true }] };
      }

      setRidersList(riderOptions);
      setRidersPlainList(result.data);
    });
  };

  const handleSendRiderRequests = () => {
    let data = {
      order_id: order.id,
      rider_ids: [],
    };
    riders.map((item) => {
      data.rider_ids.push(item.value);
    });

    setLoading(true);
    post("/orders/rider/request", data)
      .then((res) => {
        setSentRequestsList(res.result);
        setRiders([]);
        customAlert.setToastNotification(res.message);
      })
      .catch((err) => {
        customAlert.setToastNotification(errorHandler(err), "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      dataField: "name",
      text: "Rider Name",
      formatter: (_cell, _row) => _row.name || _row.id,
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "created_at",
      text: "Sent at",
      formatter: (_cell) => getFormattedDate(_cell, true),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <h5>
            <strong>Select riders for order request:</strong>
          </h5>
        </Col>
      </Row>
      {ridersPlainList.length ? (
        <Row>
          <Col md={3}>
            <Select
              value={riders}
              onChange={(event) => {
                setRiders(event || []);
              }}
              options={[ridersList]}
              isMulti
            />
          </Col>
          <Col md={2}>
            <Button
              disabled={!riders.length || loading}
              color={riders.length ? "success" : "secondary"}
              onClick={handleSendRiderRequests}
            >
              {loading ? <Spinner size="sm" color="primary" /> : "Send"}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <h5 className="error">Vendor do not have any active rider. Please add one to complete the order.</h5>
          </Col>
        </Row>
      )}
      {sentRequestsList.length ? (
        <Row>
          <BootstrapTable
            keyField="id"
            data={sentRequestsList}
            columns={columns}
            striped
            hover
            wrapperClasses="table-responsive mt-3"
            noDataIndication={"No data found."}
          />
        </Row>
      ) : null}
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
    </>
  );
};

const HandleDispute = ({ order }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { isAdmin } = useContext(UserContext);

  const customAlert = useContext(CustomAlertContext);

  const resolvedDispute = () => {
    put(`/disputes/${order.dispute_id}`, {
      status: "resolved",
      resolved_message: message,
      resolved_by: isAdmin ? "admin" : "vendor",
    })
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setLoading(true);
        window.history.back();
      })
      .catch((err) => {
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };

  return (
    <>
      <Row>
        <Col md={2}>
          <strong>DISPUTE</strong>
        </Col>
        <Col>
          <span className="float-end">
            {getPaymentStatusChip(order.dispute_status == "resolved" ? "accepted" : "pending", order.dispute_status)}
          </span>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: "8px" }}>
          <strong>Customer Concern :</strong> {order.dispute?.message}{" "}
        </Col>
      </Row>
      {order.dispute.status == "resolved" ? (
        <Row>
          <Col>
            <strong>Dispute closing details:</strong> Resolved by {order.dispute.resolved_by} |{" "}
            {getFormattedDate(order.dispute.updated_at, order.dispute.updated_at)} | Closing message -{" "}
            {order.dispute.resolved_message}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={6}>
            <InputGroup>
              <Input
                placeholder="Please mention remark for resolve the dispute."
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              <Button
                color={message && message.length > 50 ? "primary" : "secondary"}
                disabled={loading || !(message && message.length)}
                onClick={resolvedDispute}
              >
                {loading ? <SpinnerLoader /> : "Submit Resolve"}
              </Button>
            </InputGroup>
          </Col>
        </Row>
      )}
      <hr />
    </>
  );
};

const Form = (props) => {
  const { data = {}, orderId, adminFeePercentage } = props;
  const [modal, setModal] = useState(false);

  const customAlert = useContext(CustomAlertContext);
  const { isAdmin } = useContext(UserContext);

  const OrderStatus = () => {
    put(`/orders/${orderId}/status`, { status: "cancelled" })
      .then((res) => {
        customAlert.setToastNotification(res.message);
        handleModal();
        window.history.back();
      })
      .catch((err) => {
        customAlert.setToastNotification(errorHandler(err), "error");
        handleModal();
      });
  };

  const handleModal = () => {
    setModal(!modal);
  };
  const cancelModal = (
    <Modal isOpen={modal} handleModal={handleModal}>
      <ModalHeader handleModal={handleModal}>Order cancellation</ModalHeader>
      <ModalBody>Are you sure to cancel this order?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={OrderStatus}>
          Confirm
        </Button>
        <Button color="primary" onClick={handleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  const photo =
    data.photos && data.photos.length ? (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.photos.map((item, index) =>
          item.file_url ? (
            <a href={item.file_url} target="_blank" rel="noreferrer" key={index} style={{ cursor: "pointer" }}>
              <Chip style={{ marginRight: "4px", marginBottom: "4px" }} label={`Image ${index + 1}`} />
            </a>
          ) : null
        )}
      </div>
    ) : (
      <p>Not available.</p>
    );

  const attachments =
    data.attachments && data.attachments.length ? (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.attachments.map((item, index) =>
          item.file_url ? (
            <a href={item.file_url} target="_blank" rel="noreferrer" style={{ cursor: "pointer" }} key={index}>
              <Chip style={{ marginRight: "4px", marginBottom: "4px" }} label={`Attachment ${index + 1}`} />
            </a>
          ) : null
        )}
      </div>
    ) : (
      <p>Not available.</p>
    );

  const riderReview =
    data.rider_review && data.status === "delivered" ? (
      <div className="col-6">
        <label className="mr-2">Rider Review</label>
        <br />
        <StarRatings
          rating={data.rider_review.rating || 0}
          starRatedColor="#fdab00"
          numberOfStars={5}
          name="rating"
          starDimension="16px"
          starSpacing="3px"
        />
        <span className="ml-2">{data?.rider_review?.review || "No data found:"}</span>
      </div>
    ) : null;

  const vendorReview =
    data.vendor_review && data.status === "delivered" ? (
      <div className="col-6 text-end">
        <label>Vendor Review</label>
        <br />
        <StarRatings
          rating={data.vendor_review.rating || 0}
          starRatedColor="#fdab00"
          numberOfStars={5}
          name="rating"
          starDimension="16px"
          starSpacing="3px"
        />
        <span className="ml-2">{data?.vendor_review?.review ? data.vendor_review.review : "No data found:"}</span>
      </div>
    ) : null;

  const orderDate = data.created_at ? getFormattedDate(data.created_at, true) : null;

  const downloadInvoice = (
    <div className="float-end">
      <a
        href={`${API_PDF_URL}/php_html_to_pdf/download_pdf.php?order_id=${data.id}&order_ref_no=${data.order_ref_no}`}
        target="_blank"
        rel="noreferrer"
      >
        <button className="btn btn-primary waves-effect waves-light">
          Download Invoice
          <i className="fa fa-download"></i>
        </button>
      </a>
    </div>
  );

  const sendNotification = (
    <>
      <Row style={{ marginBottom: "10px" }}>
        <div className="col-6">
          <SendDirectNotification label="Customer" user_id={data.user_id} />
        </div>
        <div className="col-6 text-end">
          {data?.vendor_details?.user_id && isAdmin() ? (
            <SendDirectNotification label="Vendor" user_id={data.vendor_details.user_id} />
          ) : null}
        </div>
      </Row>
      <hr />
    </>
  );

  const sendMessageRow = (
    <>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <SendDirectMessage name="Customer" user_id={data.user_id} className="me-3" />
          {data.assigned_rider && !data.status === "confirmed" ? (
            <SendDirectMessage name="Rider" user_id={data.assigned_rider_id} className="ms-3" color="info" />
          ) : null}
          {data?.vendor_details?.user_id && isAdmin() ? (
            <SendDirectMessage name="Vendor" user_id={data.vendor_details.user_id} className="ms-3" color="warning" />
          ) : null}
        </Col>
      </Row>
      <hr />
    </>
  );

  const assignedRider =
    data.assigned_rider && data.status === "confirmed" ? (
      <>
        <Row>
          <Col>
            <strong>Assigned Rider Details:</strong>{" "}
            {`${data.assigned_rider.first_name} ${data.assigned_rider.last_name} (+${data.assigned_rider.country_iso}-${data.assigned_rider.mobile})`}
            &ensp;
            <SendDirectNotification label="Rider" user_id={data.assigned_rider_id} />
          </Col>
        </Row>
        <hr />
      </>
    ) : null;
  const paymentMethod = (
    <p>
      {data.payment_details?.txn_id
        ? `${data?.payment_details?.txn_id}| ${getFormattedDate(data?.payment_details?.transaction_date, true)}`
        : "Not available"}
    </p>
  );

  const OrderStepper = (delivery) => {
    let confirmOrder = (
      <span>
        Confirmed
        <br style={{ display: "block" }} />
        <small style={{ color: "#5B626B", fontSize: "70%" }}>
          {delivery?.order_confirm ? getFormattedDate(delivery?.order_confirm, true) : null}
        </small>
      </span>
    );
    let picked_up = (
      <span>
        Picked-Up
        <br />
        <small style={{ color: "#5B626B", fontSize: "70%" }}>
          {delivery?.picked_up ? getFormattedDate(delivery?.picked_up, true) : null}
        </small>
      </span>
    );
    let cancelled = (
      <span>
        Cancelled
        <br />
        <small style={{ color: "#5B626B", fontSize: "70%" }}>
          {delivery?.cancelled ? getFormattedDate(delivery?.cancelled, true) : null}
        </small>
      </span>
    );
    let Delivered = (
      <span>
        Delivered
        <br />
        <small style={{ color: "#5B626B", fontSize: "70%" }}>
          {delivery?.delivered ? getFormattedDate(delivery?.delivered, true) : null}
        </small>
      </span>
    );

    if (delivery.delivered) {
      //confirm-delivered
      return (
        <Stepper
          steps={[
            {
              title: confirmOrder,
              icon: check,
            },
            { title: picked_up, icon: check },
            { title: Delivered, icon: check },
          ]}
          activeStep={2}
          activeColor={"#3C9055"}
          completeColor={"#3C9055"}
        />
      );
    } else if (delivery.picked_up) {
      if (delivery.cancelled) {
        //confirm-pickUp-cancel
        return (
          <Stepper
            steps={[
              {
                title: confirmOrder,
                icon: check,
              },
              { title: picked_up, icon: check },
              { title: cancelled, icon: cancel },
            ]}
            activeStep={2}
            activeColor={"#D55D5B"}
            completeColor={"#3C9055"}
          />
        );
      }
      //confirm-pickUp-pendingDelivery
      return (
        <Stepper
          steps={[
            {
              title: confirmOrder,
              icon: check,
            },
            { title: picked_up, icon: check },
            { title: Delivered, icon: pending },
          ]}
          activeStep={1}
          activeColor={"#3C9055"}
          completeColor={"#3C9055"}
        />
      );
    } else if (delivery.order_confirm) {
      if (delivery.cancelled) {
        //confirm-cancel
        return (
          <Stepper
            steps={[
              {
                title: confirmOrder,
                icon: check,
              },
              { title: cancelled, icon: cancel },
            ]}
          />
        );
      }
      //confirm only
      return (
        <Stepper
          steps={[
            {
              title: confirmOrder,
              icon: check,
            },
            { title: picked_up, icon: pending },
            { title: Delivered, icon: pending },
          ]}
          activeStep={0}
          activeColor={"#3C9055"}
          completeColor={"#3C9055"}
        />
      );
    }
  };

  const vendorPaymentStatus =
    data.pay_vendor_status || data.vendor_transfer_error_msg ? (
      <Alert color={data.pay_vendor_status ? "success" : "danger"} className="text-center">
        {data.pay_vendor_status
          ? "Vendor's payment has been transferred to him successfully."
          : "Vendor's payment transfer has been failed. " + data.vendor_transfer_error_msg}
      </Alert>
    ) : null;

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: "-70px" }}>
        <Card>
          <CardBody>
            <div className="invoice-title">
              <h4 className="float-end font-size-16">
                <strong style={{ border: "groove", padding: "8px", borderRadius: "16px" }}>
                  Order # {data.order_ref_no || data.id}
                </strong>
              </h4>
              <span style={{ textTransform: "uppercase" }}>
                <b>{data.vendor ? data.vendor[0]?.business_name : ""}</b>
              </span>
            </div>
            <hr />
            {vendorPaymentStatus}
            {assignedRider}
            {data.status == "confirmed" ? <HandleOrderAssignRequests order={data} /> : null}
            {data?.delivery ? (
              <>
                <div className="order-status-stepper"> {OrderStepper(data.delivery)}</div>
                <hr />
              </>
            ) : null}
            {["picked-up", "confirmed"].includes(data.status) ? (
              <>
                <hr />
                <HandleOrderStatus order={data} handleCancelClick={handleModal} />
                {cancelModal}
                <hr />
              </>
            ) : null}
            {data.dispute ? <HandleDispute order={data} /> : null}
            {data.rider_review || data.vendor_review ? (
              <>
                <Row>
                  {riderReview}
                  {vendorReview}
                </Row>
                <hr />
              </>
            ) : null}
            {sendNotification}
            <Row>
              <UserDetails data={data} />
              <hr />
            </Row>
            {sendMessageRow}
            <Row>
              <div className="col-6 mt-4">
                <address>
                  <strong>Payment Method: </strong>
                  <span style={{ textTransform: "uppercase" }}>{data?.payment_details?.type} </span>
                  {paymentMethod}
                </address>
              </div>
              <div className="col-6 mt-4 text-end">
                <address>
                  <strong>Order Date:</strong>
                  <br />
                  {orderDate}
                </address>
              </div>
            </Row>
            <Row>
              <Notes data={data} />
            </Row>
            <ItemTable data={data} quotationId={orderId} adminFeePercentage={adminFeePercentage} order={true} />
            {downloadInvoice}
            <Row className="col-12">
              <Col md={12}>
                <label className="mr-2">Images</label>
                {photo}
              </Col>
              <Col md={12}>
                <label className="mr-2">Attachments</label>
                {attachments}
              </Col>
              <Col md={12}>
                <strong>Notes:</strong>
                <p>{data.notes || "Not available."}</p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

const OrderDetails = ({ disabled = false, isAdmin = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [adminFeePercentage, setAdminFeePercentage] = useState(3);

  useEffect(() => {
    get("/settings/platform-fee/slug")
      .then((res) => {
        if (res?.result?.value) {
          setAdminFeePercentage(parseFloat(res?.result?.value));
        }
      })
      .catch(() => {});

    let vendor_id = getVendorId();
    let url;
    if (vendor_id) {
      url = `/orders/${props.match.params.id}?vendor_id=${vendor_id}`;
    } else {
      url = `/orders/${props.match.params.id}`;
    }

    get(url).then((res) => {
      const { result } = res;
      setData(result);
      setLoading(false);
    });
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={"View" + " Order"} />
      <div className="container-fluid">
        <Breadcrumb breadcrumb1={{ title: "Orders", href: `/orders` }} breadcrumbItem={"View" + " Orders"} />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form
            data={data}
            disabled={disabled}
            isAdmin={isAdmin}
            orderId={props.match.params.id}
            adminFeePercentage={adminFeePercentage}
          />
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
