import React, { useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { CustomInput } from "@components/Common/Form";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { useJsApiLoader } from "@react-google-maps/api";

const getAddressFromArray = (autoCompleteData) => {
  let newAddress = {
    line_1: "",
  };

  autoCompleteData.address_components.map((addrComps) => {
    if (addrComps.types.includes("locality")) {
      newAddress.city = addrComps.long_name;
    } else if (addrComps.types.includes("administrative_area_level_1")) {
      newAddress.state = addrComps.long_name;
    } else if (addrComps.types.includes("country")) {
      newAddress.country = addrComps.long_name;
    } else if (addrComps.types.includes("postal_code")) {
      newAddress.zip_code = addrComps.long_name;
    } else if (
      addrComps.types.includes("sublocality_level_1") ||
      addrComps.types.includes("sublocality_level_2") ||
      addrComps.types.includes("sublocality_level_3")
    ) {
      newAddress.line_2 = addrComps.long_name;
    } else {
      newAddress.line_1 = `${newAddress.line_1}${newAddress.line_1 ? ", " : ""}${addrComps.long_name}`;
    }
  });

  return newAddress;
};

const VendorAddressForm = ({ formik, disabled = false }) => {
  const [addressSearchInput, setAddressSearchInput] = useState("");

  const handleChange = (address) => {
    setAddressSearchInput(address);
  };

  const GoogleAddressSuggestionListItem = ({ custom_index, main_text, secondary_text, ...props }) => (
    <div key={custom_index} {...props}>
      <span className="d-flex align-items-center">
        <span className="google-address-suggestion">
          <b>{main_text}</b>
          <span className="google-address-suggestion-secondaryText">{secondary_text}</span>
        </span>
      </span>
    </div>
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD8hzPS-bNQklD2o66NGuYp_tlS1AvXc5o",
    libraries: ["places"],
  });

  const handleSelectAddress = (address) => {
    let getAddressByFields = {};
    geocodeByAddress(address).then(async (results) => {
      if (results.length) {
        getAddressByFields = getAddressFromArray(results[0]);
      }

      let latLng = await getLatLng(results[0]);

      let address = {
        tag: "office",
        type: "business",
        location: { type: "Point", coordinates: [latLng.lng, latLng.lat] },
        ...getAddressByFields,
      };
      if (getAddressByFields.sublocality) {
        address.line_2 = getAddressByFields.sublocality;
      }
      formik.setFieldValue("address", address);
    });
  };

  const address = (
    <>
      <hr
        style={{
          marginTop: "10px",
        }}
      />
      <h5>Address</h5>
      <Col className="col-12">
        <Row>
          {!disabled && isLoaded ? (
            <>
              <PlacesAutocomplete value={addressSearchInput} onChange={handleChange} onSelect={handleSelectAddress}>
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        placeholder: "Search the location or nearby landmark.",
                        className: "location-input-field",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {suggestions.map((suggestion, i) => (
                        <GoogleAddressSuggestionListItem
                          custom_index={i}
                          key={i}
                          main_text={suggestion.formattedSuggestion.mainText}
                          secondary_text={suggestion.formattedSuggestion.secondaryText}
                          {...getSuggestionItemProps(suggestion, {
                            className: suggestion.active
                              ? "google-address-suggestion-item-active"
                              : "google-address-suggestion-item",
                          })}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <span style={{ color: "red" }}>
                {formik.values.address.city ||
                formik.values.address.country ||
                formik.values.address.state ||
                formik.values.address.zip_code ? null : (
                  <span className="error">{formik?.errors?.address?.state}</span>
                )}
              </span>
            </>
          ) : null}
          <Col className="col-6">
            <CustomInput
              formik={formik}
              name="address"
              child_name="line_1"
              label="Line 1"
              placeholder="Enter Address Line 1"
              disabled={disabled}
            />
          </Col>
          <Col className="col-6">
            <CustomInput
              formik={formik}
              name="address"
              child_name="line_2"
              label="Line 2"
              placeholder="Enter Address Line 2"
              disabled={disabled}
            />
          </Col>
          <Col className="col-6">
            <CustomInput
              formik={formik}
              name="address"
              child_name="city"
              label="City"
              placeholder="Enter city"
              disabled={true}
            />
          </Col>
          <Col className="col-6">
            <CustomInput
              formik={formik}
              name="address"
              child_name="state"
              label="State"
              placeholder="Enter state"
              disabled={true}
              errorShow={false}
            />
          </Col>
          <Col className="col-6">
            <CustomInput
              formik={formik}
              name="address"
              child_name="country"
              label="Country"
              placeholder="Enter country"
              disabled={true}
            />
          </Col>
          <Col className="col-6">
            <CustomInput
              formik={formik}
              name="address"
              child_name="zip_code"
              label="Zip Code"
              placeholder="Enter zip code"
              disabled={true}
            />
          </Col>
        </Row>
      </Col>
    </>
  );

  return address;
};

export default VendorAddressForm;
