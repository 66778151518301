import * as dateFns from "date-fns";
import { Link } from "react-router-dom";

export const APP_NAME = "Xturra";

export const handleClearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const getFullDetails = (section, item_id, label, vendor_id) => {
  return (
    <>
      {vendor_id ? (
        <Link to={`/${section}/view/${item_id}?vendor_id=${vendor_id}`}>{label}</Link>
      ) : (
        <Link to={`/${section}/view/${item_id}`}>{label}</Link>
      )}
    </>
  );
};

export const getFormattedDate = (date, is_time) => {
  let formattedString = dateFns.format(dateFns.parseISO(date), "dd-MMM-yyyy");

  if (is_time) {
    formattedString = dateFns.format(dateFns.parseISO(date), "dd-MMM-yyyy, hh:mm aa");
  }
  return formattedString;
};

export const getFormattedTime = (date) => {
  return dateFns.format(dateFns.parseISO(date), "hh:mm aa");
};

export const getVendorId = () => {
  let query = window.location.search.substring(1);
  const queryParams = new URLSearchParams(query);
  const vendor_id = queryParams.get("vendor_id");
  return vendor_id;
};

export const getKeyFromRouteQueryParams = (key) => {
  let query = window.location.search.substring(1);
  const queryParams = new URLSearchParams(query);
  const value = queryParams.get(key);
  return value;
};

export const handleAppendQueryParam = (link, key, value) => {
  link += link.includes("?") ? "&" : "?";
  link += `${key}=${value}`;
  return link;
};
