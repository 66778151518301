import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";

const columns = [
  {
    dataField: "type",
    text: "Type",
  },
  {
    dataField: "front_url",
    text: "Front side",
    formatter: (_cell) => (
      <a href={_cell} target="_blank" rel="noreferrer">
        <i className="ti-file mr-1" />
        Front side
      </a>
    ),
  },
  {
    dataField: "back_url",
    text: "Back side",
    formatter: (_cell) => (
      <>
        {_cell ? (
          <a href={_cell} target="_blank" rel="noreferrer">
            <i className="ti-file mr-1" />
            Back side
          </a>
        ) : (
          "Not available"
        )}
      </>
    ),
  },
];

const Kyc = (props) => {
  return (
    <Card>
      <CardBody>
        <CustomTable
          columns={columns}
          actions={false}
          vendorId={props.vendorId}
          section="kyc"
          fetchUrl={`/riders/kyc?rider_id=${props.riderId}`}
        />
      </CardBody>
    </Card>
  );
};

export default Kyc;
