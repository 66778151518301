import React, { useContext, useEffect, useState, useRef } from "react";
import { InputGroup, Input, Spinner, Button } from "reactstrap";
import { UserContext } from "@contexts/UserContext";
import { getFormattedDate } from "@utils/index";
import Select from "react-select";
import { get } from "helpers/api_helper";

export const SendMessage = (props) => {
  const [message, setMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const hiddenFileInput = useRef();

  const handleMessageSend = () => {
    setShowLoader(true);
    if (props.handleMessageSend) {
      props.handleMessageSend(
        selectedFile.name ? selectedFile : message,
        () => {
          setMessage("");
          setShowLoader(false);
          setSelectedFile({});
        },
        () => {
          setShowLoader(false);
        }
      );
    } else {
      setMessage("");
      setShowLoader(false);
      setSelectedFile({});
    }
  };

  const handleFileSelection = (event) => {
    const fileUploaded = event.target.files[0];
    setSelectedFile(fileUploaded);
  };

  return (
    <div className="border col-12 px-2 pb-2 border-top-0">
      <InputGroup className="d-flex justify-content-end align-items-center">
        <Input
          className="w-100 rounded-3 p-3 position-relative ps-3 pe-2"
          style={{ zIndex: "1" }}
          placeholder={"Type your message here..."}
          onChange={(e) => setMessage(e.target.value)}
          value={selectedFile.name || message}
          disabled={selectedFile.name || showLoader}
        />
        <div
          className="position-absolute d-flex justify-content-end align-items-center bg-white me-2"
          style={{ zIndex: "1" }}
        >
          {selectedFile.name ? (
            <span
              className="material-symbols-outlined m-2"
              style={{ right: "90px", cursor: "pointer" }}
              onClick={() => setSelectedFile({})}
            >
              <i className="mdi mdi-close" style={{ fontSize: "24px" }} />
            </span>
          ) : (
            <span
              className="material-symbols-outlined m-2"
              style={{ right: "90px", cursor: "pointer" }}
              onClick={() => hiddenFileInput.current.click()}
            >
              <i className="mdi mdi-attachment" style={{ fontSize: "24px" }} />
              <input
                type="file"
                hidden
                ref={hiddenFileInput}
                onChange={handleFileSelection}
                accept="image/jpeg,image/png,text/plain,application/pdf,application/msword"
              />
            </span>
          )}
          <Button
            color="primary"
            className="border-0 px-3 py-2"
            disabled={selectedFile.name ? false : showLoader || !message}
            onClick={handleMessageSend}
          >
            Send
          </Button>
        </div>
      </InputGroup>
    </div>
  );
};

const OwnMessage = ({ item }) => (
  <div className=" d-flex flex-column align-items-end ">
    <div className="d-flex pb-0">
      {item.is_file ? (
        <a
          className="ms-auto text-white px-3 py-2 me-2 mt-3"
          style={{
            backgroundColor: "#fdab00",
            borderRadius: " 8px 0px 8px 8px",
            marginBottom: "14px",
          }}
          href={item.file_url}
          target="_blank"
          rel="noreferrer"
        >
          <i className="mdi mdi-file-document me-1" />
          {item.message.substring(item.message.lastIndexOf("/") + 1)}
        </a>
      ) : (
        <p
          className="ms-auto text-white px-3 py-2  me-2 mt-3"
          style={{
            backgroundColor: "#fdab00",
            borderRadius: " 8px 0px 8px 8px",
          }}
        >
          {item.message}
        </p>
      )}
    </div>
    <div className="d-flex align-items-center me-4 position-relative" style={{ fontSize: "14px", top: "-10px" }}>
      <small style={{ color: "#8c8c8c" }} className="mt-0">
        {getFormattedDate(item.created_at, true)}
      </small>
    </div>
  </div>
);

const OpponentMessage = ({ item }) => (
  <div className="ms-2 d-flex flex-column align-items-start ">
    <div className="d-flex pb-0">
      <div>
        {item.is_file ? (
          <a
            className="px-3 py-1 shadow border"
            style={{
              display: "block",
              borderRadius: "0px 8px 8px 8px",
              marginBottom: "16px",
              color: "initial",
            }}
            href={item.file_url}
            target="_blank"
            rel="noreferrer"
          >
            <i className="mdi mdi-file-document me-1" />
            {item.message.substring(item.message.lastIndexOf("/") + 1)}
          </a>
        ) : (
          <p className="px-3 py-1 shadow border" style={{ borderRadius: "0px 8px 8px 8px" }}>
            {item.message}
          </p>
        )}
        <div
          className="d-flex align-items-center me-4"
          style={{ fontSize: "14px", marginTop: "-12px", marginBottom: "16px" }}
        >
          <small style={{ color: "#8c8c8c" }} className="ms-3 mt-0">
            {getFormattedDate(item.created_at, true)}
          </small>
        </div>
      </div>
    </div>
  </div>
);

export const Messages = ({ items = [], loader }) => {
  const { user } = useContext(UserContext);
  const bottomRef = useRef(null);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [items, loader]);

  return (
    <div
      className="col-12 border border-bottom-0 position-relative"
      style={{ overflowY: "scroll", height: "calc(((100vh - 70px) - 56px) - 200px - 47px)", paddingTop: "8px" }}
    >
      {loader ? (
        <div style={{ display: "flex", justifyContent: "space-around" }} className="mt-3 text-warning">
          <Spinner />
        </div>
      ) : items.length ? (
        items.map((item) =>
          item.sender_user_id == user.id ? (
            <OwnMessage item={item} key={item.id} />
          ) : (
            <OpponentMessage item={item} key={item.id} />
          )
        )
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>Select a Chat.</div>
      )}
      <div ref={bottomRef} />
    </div>
  );
};

const getNameShorthand = (name = "") => {
  let alias = "";
  name.split(" ").map((item) => {
    if (item && item[0] && alias.length < 2) {
      alias += item[0].toUpperCase();
    }
  });

  return alias || "XT";
};

const ChatRoomInfoCard = ({ handleUpdateActiveRoom, activeRoom, ...props }) => {
  const [item, setItem] = useState(props.item);

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  const { user } = useContext(UserContext);
  const getChatRoomName = (room) => {
    let name = "";

    room.member_users.map((member) => {
      if (member.id !== user.id) {
        if (member.first_name || member.last_name) {
          name = (member.first_name || "") + (" " + (member.last_name || ""));
        } else {
          name = member.id;
        }
      }
    });
    return name || room.id;
  };

  return (
    <div
      className={`hover d-flex rounded-1 my-2`}
      onClick={() => handleUpdateActiveRoom(item)}
      style={{ cursor: "pointer", border: activeRoom.id == item.id ? "1px solid #fdab00" : "initial" }}
    >
      <div className="d-flex align-items-center ms-1" style={{ height: 42, width: 42 }}>
        <div className="bg-primary text-white p-2 rounded-circle position-relative">
          {getNameShorthand(getChatRoomName(item))}
        </div>
        {item.new_msg ? (
          <span
            className="rounded-circle p-1 border-1 border translate-middle badge rounded-pill"
            style={{ backgroundColor: "red", marginTop: "-12px", marginLeft: "-4px" }}
          >
            <span className="visually-hidden">unread messages</span>
          </span>
        ) : null}
      </div>
      <div className="ms-3 w-100">
        <div className="d-flex justify-content-between">
          <p className="m-0">{getChatRoomName(item)}</p>
        </div>
        {item?.last_message?.message && !item.last_message.is_file ? (
          <span style={{ color: "rgb(140, 140, 140)" }}>{item?.last_message?.message} </span>
        ) : (
          <span style={{ color: "rgb(140, 140, 140)" }}>File </span>
        )}
      </div>
    </div>
  );
};

export const ChatRoomsList = ({ items, handleUpdateActiveRoom, activeRoom = {} }) =>
  items.map((item) => (
    <ChatRoomInfoCard
      item={item}
      handleUpdateActiveRoom={handleUpdateActiveRoom}
      activeRoom={activeRoom}
      key={item.id}
    />
  ));

export const OpponentOrdersList = ({ activeOpponentUserId }) => {
  const [orderList, setOrderList] = useState({ options: [{ label: "No Orders", value: "", isDisabled: true }] });

  useEffect(() => {
    if (activeOpponentUserId)
      get(`/orders?page_size=100&user_id=${activeOpponentUserId}`)
        .then((res) => {
          const { result } = res;
          let orderOptions = { options: [{ label: "Orders", value: "", isDisabled: true }] };
          if (result.data?.length) {
            result.data.map((data) => {
              orderOptions.options.push({ label: data.order_ref_no, value: data._id });
            });
            setOrderList(orderOptions);
          } else {
            setOrderList({ options: [{ label: "No Orders", value: "", isDisabled: true }] });
          }
        })
        .catch(() => {
          //
        });
  }, [activeOpponentUserId]);

  return (
    <div style={{ width: "220px", marginLeft: "16px" }}>
      <Select
        onChange={(event) => {
          if (event.value) window.location.href = "/orders/view/" + event.value;
        }}
        options={[orderList]}
        placeholder="Select Order"
      />
    </div>
  );
};
