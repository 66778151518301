import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getFullDetails } from "@utils/index";

const Settings = () => {
  const columns = [
    {
      dataField: "title",
      text: "Title",
      formatter: (_cell, _row) => {
        return getFullDetails("settings", _row._id, _cell);
      },
    },
    {
      dataField: "value",
      text: "Value",
    },
    {
      dataField: "slug",
      text: "Slug",
    },
  ];
  const title = "Settings";

  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={title} />
        <Card>
          <CardBody>
            <CustomTable
              columns={columns}
              actions={true}
              edit={true}
              view={true}
              section="settings"
              fetchUrl="/settings"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Settings;
