import React, { useEffect, useState } from "react";
import ReactPhoneInput from "react-phone-input-2";
import verifyIcon from "../../assets/images/verify.png";
import "react-phone-input-2/lib/style.css";

const countryDialCodeByIso = {
  1: "us",
  // 1: "ca",
  30: "gr",
  31: "nl",
  32: "be",
  33: "fr",
  34: "es",
  36: "hu",
  39: "it",
  40: "ro",
  49: "de",
  41: "ch",
  43: "at",
  44: "gb",
  45: "dk",
  46: "se",
  47: "no",
  48: "pl",
  52: "mx",
  55: "br",
  60: "my",
  61: "au",
  62: "id",
  63: "ph",
  65: "sg",
  64: "nz",
  66: "th",
  91: "in",
  81: "jp",
  350: "gi",
  351: "pt",
  352: "lu",
  353: "ie",
  356: "mt",
  357: "cy",
  358: "fi",
  359: "bg",
  370: "lt",
  371: "lv",
  372: "ee",
  385: "hr",
  386: "si",
  420: "cz",
  421: "sk",
  423: "li",
  852: "hk",
  971: "ae",
};

const CustomMobileInput = ({
  label,
  country_iso,
  number,
  disabled = false,
  handleChange = () => {},
  className = "",
  errorMsg = "",
  phoneVerified = false,
  handleSendOtp = () => {},
  sendOtp = false,
  showOTPSendVerify = false,
  checkReEnterPhone,
}) => {
  if (checkReEnterPhone != number) {
    sendOtp = true;
  }

  const [PhoneNumber, setPhoneNumber] = useState(number);

  useEffect(() => {
    setPhoneNumber(number);
  }, [number]);

  return (
    <div className={`phone-input ${className}`}>
      {label ? <label>{label}</label> : null}
      <ReactPhoneInput
        country={countryDialCodeByIso[country_iso || "61"]}
        preferredCountries={["au", "in"]}
        inputStyle={{
          width: "100%",
        }}
        value={country_iso + PhoneNumber}
        onlyCountries={Object.values(countryDialCodeByIso)}
        onChange={(phone, country) => {
          handleChange(phone, country);
        }}
        disabled={disabled}
        countryCodeEditable={false}
      />
      {showOTPSendVerify ? (
        phoneVerified ? (
          <img className="verify-icon" src={verifyIcon} />
        ) : (
          <button
            className="btn btn-primary waves-effect waves-light send-otp-button"
            onClick={handleSendOtp}
            disabled={!number.length}
          >
            {sendOtp ? "Send" : "Re-send"} OTP
          </button>
        )
      ) : null}
      {errorMsg ? <span className="error">{errorMsg}</span> : null}
    </div>
  );
};
export default CustomMobileInput;
