import React, { useEffect, useState } from "react";
import logo from "@assets/images/xturra-logo-512x512.png";
import fileIcon from "@assets/images/file-icon.png";
import CloseButton from "react-bootstrap/CloseButton";

const aspectRatioStyle = { aspectRatio: "3/2", maxHeight: "180px", height: "180px" };

const CustomImagePreview = ({ file, handleOnDelete, height = 133.33, width = 200, isAspectRatio = false }) => {
  const [thumb, setThumb] = useState(file);

  useEffect(() => {
    if (file && typeof file != "string") {
      let reader = new FileReader();
      if (file.type.split("/")[0] != "image") {
        setThumb(fileIcon);
        return false;
      }
      reader.onloadend = () => {
        setThumb(reader.result);
      };

      reader.readAsDataURL(file);
    }
  }, [file]);

  const deleteBtn =
    handleOnDelete && typeof handleOnDelete == "function" ? (
      <CloseButton
        style={{
          position: "absolute",
          top: "4px",
          right: "4px",
          backgroundColor: "#fdac00",
          borderRadius: "1rem",
          fontSize: "12px",
          padding: "8px",
        }}
        onClick={() => handleOnDelete(file)}
      />
    ) : null;

  if (file && typeof file != "string") {
    return (
      <div className="m-1" style={{ display: "flex", position: "relative", width: "fit-content" }}>
        <img
          src={thumb || logo}
          alt={"Not available"}
          className="img-thumbnail"
          style={isAspectRatio ? aspectRatioStyle : { height, width }}
        />
        {deleteBtn}
      </div>
    );
  }

  if (thumb) {
    return (
      <div className="m-1" style={{ display: "flex", position: "relative", width: "fit-content" }}>
        {isAspectRatio ? (
          <embed src={thumb} style={aspectRatioStyle} />
        ) : (
          <embed src={thumb} height={height} width={width} />
        )}
        {deleteBtn}
      </div>
    );
  }

  return <p>Not available</p>;
};
export default CustomImagePreview;
