import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Button } from "reactstrap";

import { withRouter, Link } from "react-router-dom";

// import images
import { useFormik } from "formik";
import * as Yup from "yup";
import { textField } from "components/Common/Form/InputFields";
import logo from "../../assets/images/xturra-logo-512x512.png";
import { APP_NAME, handleClearStorage } from "utils/index";
import { UserContext } from "contexts";
// import VerifyNumber from "@pages/VerifyNumber";
import CustomMetaTags from "components/Common/CustomMetaTags";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Email is not is proper format").required("Please enter an email"),
  password: Yup.string().required("Please enter password"),
});

// const or_divider = (
//   <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
//     <div style={{ flex: 1, height: "1px", backgroundColor: "#ced4da" }} />
//     <div>
//       <p style={{ width: "25px", textAlign: "center", fontWeight: "bold", marginBottom: "4px" }}>or</p>
//     </div>
//     <div style={{ flex: 1, height: "1px", backgroundColor: "#ced4da" }} />
//   </div>
// );

const Login = (props) => {
  const { login, user = {} } = useContext(UserContext);

  useEffect(() => {
    handleClearStorage();
  }, []);

  useEffect(() => {
    if (user.mobile && !user.email) {
      window.location.href = "/register";
    } else if (user.mobile) {
      window.location.href = "/dashboard";
    }
  }, [user]);

  const handleValidSubmit = (values) => {
    login(values);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleValidSubmit(values);
    },
  });

  const login_form = (
    <form onSubmit={formik.handleSubmit} className="form-horizontal m-4 mt-0">
      {props.error && typeof props.error === "string" ? <Alert color="danger">{props.error}</Alert> : null}
      <div className="mb-3">{textField(formik, "email", "Email", "Enter your Email", false, "email")}</div>
      <div className="mb-3">{textField(formik, "password", "Password", "Enter your Password", "password")}</div>
      <div className="text-end">
        <Button type="submit" color="primary" className="">
          Log In
        </Button>
      </div>
      {/* <Row className="mt-2 mb-0 row">
        <div className="col-12 mt-4">
          <Link to="/forgot-password">
            <i className="mdi mdi-lock"></i> Forgot your password?
          </Link>
        </div>
      </Row> */}
    </form>
  );

  return (
    <React.Fragment>
      <CustomMetaTags title="Login" />
      <div className="account-pages d-flex align-items-center" style={{ height: "100vh" }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#E8E8E8" }}>
                  <div className="text-center p-4">
                    <h5 className="font-size-20">Welcome to {APP_NAME}</h5>
                    <p>Sign in to continue to {APP_NAME}.</p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                </div>
                {/* <CardBody className="p-4 pb-0 m-4">
                  <VerifyNumber isLogin={true} />
                </CardBody>
                {or_divider} */}
                <CardBody className="p-4 mt-4">{login_form}</CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Do not have an account?{" "}
                  <Link to="/register" className="fw-medium link-color">
                    Signup now{" "}
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
