import React from "react";
import { CardTitle, Button } from "reactstrap";

const ListTopBar = ({ title = "", btnLabel = "Add New", btnRedirect = "" }) => (
  <div className="d-flex justify-content-between">
    <CardTitle className="h4">{title} </CardTitle>
    {btnRedirect && (
      <Button color="primary" className="mb-2">
        <a href={btnRedirect} style={{ color: "white" }}>
          {btnLabel}
        </a>
      </Button>
    )}
  </div>
);

export default ListTopBar;
