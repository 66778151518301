import { handleClearStorage } from "utils/";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const Logout = () => {
  useEffect(() => {
    handleClearStorage();
    window.location.href = "/login";
  }, []);

  return <></>;
};

export default withRouter(Logout);
