import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { CustomInput } from "@components/Common/Form";
import { selectField } from "components/Common/Form/InputFields";

const FormSchema = Yup.object().shape({
  title: Yup.string().required().label("FAQ title"),
  description: Yup.string().required().label("FAQ description"),
  type: Yup.string().required().label("FAQ type"),
});

const Form = (props) => {
  const { disabled = false, item_id, data = {}, isAdmin = false } = props;
  const [loader, setLoader] = useState(false);
  const customAlert = useContext(CustomAlertContext);
  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/faqs/${item_id}`, data) : (data) => post(`/faqs`, data);
    request(values)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = "/faqs";
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };
  const formik = useFormik({
    initialValues: {
      type: data ? data.type : "",
      title: data ? data.title : "",
      description: data ? data.description : "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  let options = [
    { name: "All", value: "all" },
    { name: "Vendors", value: "vendor" },
    { name: "Rider", value: "rider" },
    { name: "User", value: "user" },
  ];
  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          {isAdmin ? selectField(formik, "type", "Type", options, "Please select type", disabled) : null}
          <CustomInput formik={formik} name="title" label={"Title"} placeholder="Enter title" disabled={disabled} />
          <div style={{ display: "flex", flexDirection: "column", marginBottom: "2rem", marginTop: 8 }}>
            <label>Description</label>
            <textarea
              value={formik.values.description}
              onChange={(event) => formik.setFieldValue("description", event.target.value)}
              rows="5"
              disabled={disabled}
            />
            {formik.errors["description"] && <span className="error">{formik.errors["description"]}</span>}
          </div>
          {disabled ? null : (
            <div className="d-flex flex-wrap gap-2">
              <Button type="submit" color="primary" disabled={loader}>
                Submit
              </Button>
            </div>
          )}
        </form>
      </CardBody>
    </Card>
  );
};

const FaqsForm = ({ disabled = false, isAdmin = true, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);
  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/faqs/${props.match.params.id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Faq"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Faqs", href: "/faqs" }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Faq"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form
            data={data}
            item_id={editInfo ? props.match.params.id : undefined}
            disabled={disabled}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </div>
  );
};

export default FaqsForm;
