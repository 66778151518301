import PropTypes from "prop-types";
import React from "react";
import { AvField } from "availity-reactstrap-validation";

const CustomTextField = ({
  name,
  label,
  className = "",
  placeholder,
  type = "text",
  required = false,
  onChange = () => {},
  disabled = false,
  validate = {},
  containerClassName = "",
  ...props
}) => (
  <div className={containerClassName}>
    <AvField
      name={name}
      label={label}
      className={className}
      placeholder={placeholder}
      type={type}
      required={required}
      onChange={onChange}
      disabled={disabled}
      validate={validate}
      {...props}
    />
  </div>
);

CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  validate: PropTypes.object,
};

export default CustomTextField;
