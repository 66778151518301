import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import CustomImagePreview from "@components/Common/CustomImagePreview";
import CustomDropzone from "@components/Common/CustomDropzone";
import { selectField } from "components/Common/Form/InputFields";
import { UserContext } from "contexts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";

const FormSchema = Yup.object().shape({
  title: Yup.string().required().max(50).label("Title"),
  banner_text: Yup.string().label("Banner text").max(15),
  is_pay_per_click: Yup.boolean().required(yupMessages.required).label("Pay per click"),
  pay_per_click_amt: Yup.number()
    .min(0)
    .max(10000)
    .when("is_pay_per_click", {
      is: true,
      then: Yup.number().required().label("Pay per click amount "),
    }),
  per_day_charges: Yup.number().label("Per day charges").min(0).required().max(200),
  banner: Yup.mixed()
    .required("Required *")
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
    ),
});

const Form = (props) => {
  const { disabled = false, item_id, data = {} } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const { isAdmin } = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/promotions/${item_id}`, data) : (data) => post(`/promotions`, data);

    let data = new FormData();
    data.append("title", values.title.trim());
    if (values.banner_text) {
      data.append("banner_text", values.banner_text.trim());
    }
    data.append("is_pay_per_click", values.is_pay_per_click);
    data.append("pay_per_click_amt", values.pay_per_click_amt);
    data.append("per_day_charges", values.per_day_charges);

    if (values.banner && typeof values.banner != "string") {
      data.append("banner", values.banner);
    }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = `/promotions`;
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_pay_per_click: data.is_pay_per_click,
      title: data.title || "",
      banner_text: data.banner_text || "",
      pay_per_click_amt: data.pay_per_click_amt || "0",
      per_day_charges: data.per_day_charges || "",
      banner: data.banner_url || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (formik.values.is_pay_per_click == "false") {
      formik.setFieldValue("pay_per_click_amt", "0");
    }
  }, [formik.values.is_pay_per_click]);

  let options = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  const is_pay_per_click = (
    <Col className="col-6 mt-1">
      {selectField(formik, "is_pay_per_click", "Pay Per Click", options, "Please select pay per click", disabled)}
    </Col>
  );

  const pay_per_click_amt = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        type="number"
        name="pay_per_click_amt"
        label={"Pay Per Click Amount"}
        placeholder="Enter pay per click amount"
        disabled={formik.values.is_pay_per_click == "false"}
      />
    </Col>
  );
  const per_day_charges = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        type="number"
        name="per_day_charges"
        label={"Per Day Charges"}
        placeholder="Enter per day charges"
        disabled={disabled}
      />
    </Col>
  );

  const banner = (
    <Col className="col-12">
      <label className="mr-2">Banner</label>
      <small>(3:2, ex 660px x 440px.)</small>
      <Row>
        <Col className="col-12">
          <CustomImagePreview file={formik.values.banner} isAspectRatio={true} />
        </Col>
        {formik.errors && formik.errors.banner ? (
          <Col className="col-12">
            <span className="error">{formik.errors.banner}</span>
          </Col>
        ) : null}
        {disabled ? null : (
          <Col className="col-12 mt-2">
            <CustomDropzone
              onChange={(acceptedFiles) => formik.setFieldValue("banner", acceptedFiles[0])}
              allowedTypes="image/*"
              multiple={false}
            />
          </Col>
        )}
      </Row>
    </Col>
  );

  const title = (
    <Col className="col-6 ">
      <CustomInput formik={formik} name="title" label={"Title"} placeholder="Enter title" disabled={disabled} />
    </Col>
  );
  const banner_text = (
    <Col className="col-6 ">
      <CustomInput
        formik={formik}
        name="banner_text"
        label={"Banner Text"}
        placeholder="Enter Banner Text"
        disabled={disabled}
      />
    </Col>
  );

  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );
  const handleModal = () => {
    setModal(!modal);
    setStartDate("");
    setEndDate("");
  };
  const subscribe_btn = isAdmin() ? null : (
    <Col className="col-6">
      <Button color="primary" className="mt-4" onClick={handleModal}>
        Subscribe
      </Button>
    </Col>
  );
  const calculate = (isReturnDiffDays = false) => {
    let diff = endDate.getTime() - startDate.getTime();
    let days = diff / (1000 * 3600 * 24);

    if (isReturnDiffDays) {
      return ++days;
    }
    let finalAmt = formik.values.per_day_charges * ++days;

    return finalAmt;
  };

  const payNow = () => {
    let data = new FormData();
    let loc = window.location.origin;
    data.append("success_url", loc + "/dashboard?payment=success");
    data.append("cancel_url", loc + "/dashboard?payment=failed");
    data.append("promotion_id", props.data.id);
    data.append("started_at", startDate);
    data.append("expired_at", endDate);
    data.append("days", calculate(true));
    data.append("amount", calculate());

    post(`/checkout/session`, data)
      .then((res) => {
        window.location.href = res.result;
      })
      .catch((err) => {
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const subscribeModal = (
    <Modal isOpen={modal} handleModal={handleModal}>
      <ModalHeader>Subscribe Promotion</ModalHeader>
      <ModalBody>
        <Row className="my-2">
          <Col md="6">Starting Date</Col>
          <Col md="6">Ending Date</Col>
        </Row>
        <Row className="my-2">
          <Col md="6">
            <DatePicker
              dateFormat="dd-MMM-yyyy"
              name="started_at"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              minDate={new Date()}
            />
          </Col>
          <Col md="6">
            <DatePicker
              dateFormat="dd-MMM-yyyy"
              name="end_date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              disabled={startDate ? false : true}
              minDate={startDate ? addDays(new Date(startDate), 1) : new Date()}
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>Per day charges:&nbsp;AUD&nbsp;{formik.values.per_day_charges}/-</Col>
        </Row>
        {startDate && endDate ? (
          <Row className="my-2">
            <Col md={6}>Active days : {calculate(true)}</Col>
            <Col md={6}>Total charges : AUD {calculate()}/-</Col>
          </Row>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={payNow}>
          Pay Now
        </Button>
        <Button color="secondary" onClick={handleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {title}
            {per_day_charges}
            {is_pay_per_click}
            {pay_per_click_amt}
            {banner_text}
            {subscribe_btn}
            {subscribeModal}
            {banner}
            {submit_btn}
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

const PromotionsForm = ({ disabled = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/promotions/${props.match.params.id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Promotion"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Promotion", href: `/promotions` }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Promotion"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form data={data} item_id={editInfo ? props.match.params.id : undefined} disabled={disabled} />
        )}
      </div>
    </div>
  );
};

export default PromotionsForm;
