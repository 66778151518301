import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import ListTopBar from "@components/Common/ListTopBar";
import { getFullDetails } from "@utils/index";

const Services = (props) => {
  const columns = [
    {
      dataField: "title",
      text: "Services Name",
      formatter: (_cell, _row) => {
        return getFullDetails("services", _row._id, _cell, props.vendorId);
      },
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "price",
      text: "Price",
    },
  ];

  return (
    <Card>
      <CardBody>
        <ListTopBar btnRedirect={`/services/add?vendor_id=${props.vendorId}`} />
        <CustomTable
          columns={columns}
          actions={true}
          edit={true}
          view={true}
          vendorId={props.vendorId}
          remove={true}
          section="services"
          fetchUrl="/services"
          pagination={true}
        />
      </CardBody>
    </Card>
  );
};

export default Services;
