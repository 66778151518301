import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { vendorRoutes, nonAuthRoutes, adminRoutes } from "./routes/allRoutes";

// Import all middleware
import AuthMiddleware from "./routes/middleware/AuthMiddleware";

// layouts Format
import AuthLayout from "./components/AuthLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { UserContext } from "contexts";

const routes = {
  admin: adminRoutes.map((route, id) => (
    <AuthMiddleware
      path={route.path}
      layout={AuthLayout}
      component={route.component}
      key={"admin" + id}
      exact
      role={"admin"}
    />
  )),
  vendor: vendorRoutes.map((route, id) => (
    <AuthMiddleware
      path={route.path}
      layout={AuthLayout}
      component={route.component}
      key={"vendor" + id}
      exact
      role={"vendor"}
    />
  )),
  non_auth: nonAuthRoutes.map((route, id) => (
    <AuthMiddleware
      path={route.path}
      layout={NonAuthLayout}
      component={route.component}
      key={"non-auth" + id}
      isAuthProtected={false}
      exact={route.exact}
    />
  )),
};

const App = () => {
  const { isAdmin, fetchUser } = useContext(UserContext);

  useEffect(() => {
    fetchUser(true);
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Switch>{isAdmin() ? [...routes.non_auth, ...routes.admin] : [...routes.non_auth, ...routes.vendor]}</Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
