import React, { useContext } from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { UserContext } from "@contexts/UserContext";
import { getFullDetails } from "@utils/index";

const Categories = () => {
  const { isAdmin } = useContext(UserContext);

  const adminColumns = [
    {
      dataField: "label",
      text: "Title",
      formatter: (_cell, _row) => {
        return getFullDetails("categories", _row._id, _cell);
      },
    },
  ];
  const vendorColumns = [
    {
      dataField: "label",
      text: "Title",
    },
  ];

  const title = "Categories";

  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        {isAdmin() ? (
          <Breadcrumb breadcrumbItem={title} btnRedirect="/categories/add" btnLabel="Add New" />
        ) : (
          <Breadcrumb breadcrumbItem={title} />
        )}
        <Card>
          <CardBody>
            <CustomTable
              columns={isAdmin() ? adminColumns : vendorColumns}
              actions={isAdmin() ? true : false}
              edit={isAdmin() ? true : false}
              view={isAdmin() ? true : false}
              remove={isAdmin() ? true : false}
              section="categories"
              fetchUrl="/categories"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Categories;
