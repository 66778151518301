import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getFullDetails } from "@utils/index";

const Tags = () => {
  const columns = [
    {
      dataField: "label",
      text: "Title",
      formatter: (_cell, _row) => {
        return getFullDetails("tags", _row._id, _cell);
      },
    },
    {
      dataField: "icon_url",
      text: "Icon",
      formatter: (_cell) => (
        <a href={_cell} target="_blank" rel="noreferrer">
          <i className="ti-file mr-1" />
          Icon
        </a>
      ),
    },
  ];

  const title = "Tags";

  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={title} btnRedirect="/tags/add" btnLabel="Add New" />
        <Card>
          <CardBody>
            <CustomTable
              columns={columns}
              actions={true}
              edit={true}
              view={true}
              remove={true}
              section="tags"
              fetchUrl="/tags"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Tags;
