import React, { useState, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Spinner } from "reactstrap";
import { CustomAlertContext } from "contexts";
import { post, errorHandler } from "helpers/api_helper";

const SendDirectMessage = ({ user_id, name, role = "", className = "", color = "info" }) => {
  const customAlert = useContext(CustomAlertContext);

  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSendMessage = () => {
    let data = new FormData();
    data.append("to_send", user_id);
    data.append("message", message);
    data.append("is_file", false);
    if (role) {
      data.append("send_to_role", role);
    }
    setShowLoading(true);
    post("chats/send_message", data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setShowLoading(false);
        setMessage("");
        setShowModal(false);
      })
      .catch((err) => {
        setShowLoading(false);
        let msg = errorHandler(err);
        customAlert.error(msg);
      });
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const messageModal = showModal ? (
    <Modal isOpen={showModal} handleModal={handleModal}>
      <ModalHeader handleModal={handleModal}>Send Message {name ? `to ${name}` : ""} </ModalHeader>
      <ModalBody>
        <Input onChange={(e) => setMessage(e.target.value)} value={message} placeholder="Write your message..." />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSendMessage} disabled={showLoading}>
          {showLoading ? <Spinner size="sm" /> : "Send"}
        </Button>
        <Button onClick={handleModal} disabled={showLoading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;

  return (
    <>
      {messageModal}
      <Button color={color} size="sm" onClick={handleModal} className={className}>
        Send Message {name ? `to ${name}` : ""}
      </Button>
    </>
  );
};

export default SendDirectMessage;
