import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem, Button } from "reactstrap";
import { APP_NAME } from "@utils/index";

const Breadcrumb = (props) => (
  <Row className="align-items-center">
    <Col md={9}>
      <div className="page-title-box">
        <h4 className="font-size-18">{props.breadcrumbItem}</h4>
        <ol className="breadcrumb mb-0">
          <BreadcrumbItem>
            <Link to="/">{APP_NAME}</Link>
          </BreadcrumbItem>
          {props.breadcrumb1 ? (
            <BreadcrumbItem>
              <Link to={props.breadcrumb1.href}>{props.breadcrumb1.title}</Link>
            </BreadcrumbItem>
          ) : null}
          <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
        </ol>
      </div>
    </Col>
    {props.btnRedirect && props.btnLabel ? (
      <Col md={3} style={{ display: "flex", justifyContent: "end" }}>
        <a href={props.btnRedirect} style={{ color: "white" }}>
          <Button color="primary" className="mb-2">
            {props.btnLabel}
          </Button>
        </a>
      </Col>
    ) : null}
  </Row>
);

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
