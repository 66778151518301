import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getFormattedTime, getFormattedDate } from "@utils/index";

const columns = [
  {
    dataField: "type",
    text: "Type",
    formatter: (_cell, _row) => {
      return _row.data.link_type;
    },
  },
  {
    dataField: "title",
    text: "Title",
    formatter: (_cell, _row) => {
      return _row.data.title;
    },
  },
  {
    dataField: "message",
    text: "Message",
    formatter: (_cell, _row) => {
      return _row.data.message;
    },
  },
  {
    dataField: "created_at",
    text: "Send At",
    formatter: (_cell) => {
      return (
        <p>
          {getFormattedDate(_cell)}, {getFormattedTime(_cell)}
        </p>
      );
    },
  },
  {
    dataField: "read_at",
    text: "Read At",
    formatter: (_cell) => {
      return (
        <p>
          {getFormattedDate(_cell)}, {getFormattedTime(_cell)}
        </p>
      );
    },
  },
];
const title = "Notifications";

const Notification = () => {
  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={title} />
        <Card>
          <CardBody>
            <CustomTable columns={columns} section="notifications" fetchUrl="/notifications" />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Notification;
