import React, { useState, useContext } from "react";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import Breadcrumb from "@components/Common/Breadcrumb";

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .strict()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .required(yupMessages.required)
    .max(200),
  email: Yup.string().email().required().max(40),
  message: Yup.string().strict().trim().required(yupMessages.required).max(200),
});

const Form = (props) => {
  const { disabled = false, item_id, data = {} } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/contacts/${item_id}`, data) : (data) => post(`/contacts`, data);

    let data = new FormData();
    data.append("email", values.email);
    data.append("name", values.name);
    data.append("message", values.message);
    data.append("role", "vendor");

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.history.back();
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      email: data.email || "",
      message: data.message || "",
      name: data.name || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const email = (
    <Col className="col-6">
      <CustomInput formik={formik} name="email" label={"Email"} placeholder="Enter email" disabled={disabled} />
    </Col>
  );
  const message = (
    <Col className="col-6">
      <CustomInput formik={formik} name="message" label={"Message"} placeholder="Enter message" disabled={disabled} />
    </Col>
  );

  const name = (
    <Col className="col-6">
      <CustomInput formik={formik} name="name" label={"Name"} placeholder="Enter name" disabled={disabled} />
    </Col>
  );
  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          {name}
          {email}
          {message}
          {submit_btn}
        </form>
      </CardBody>
    </Card>
  );
};

const ContactsForm = ({ disabled = false, isAdmin = false }) => {
  const [loading] = useState(false);
  const [data] = useState({});

  return (
    <div className="page-content">
      <CustomMetaTags title={"Raise Contact Query"} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={"Raise Contact Query"} />
        {loading ? <SpinnerLoader /> : <Form data={data} disabled={disabled} isAdmin={isAdmin} />}
      </div>
    </div>
  );
};

export default ContactsForm;
