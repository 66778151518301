import React, { useState, useEffect, useContext } from "react";
import { Button, Card, CardBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { errorHandler, get, put } from "helpers/api_helper";
import Switch from "react-switch";
import { CustomAlertContext } from "@contexts/CustomAlertContext";

let daysOptions = [
  { label: "01:00 AM", value: "01:00" },
  { label: "02:00 AM", value: "02:00" },
  { label: "03:00 AM", value: "03:00" },
  { label: "04:00 AM", value: "04:00" },
  { label: "05:00 AM", value: "05:00" },
  { label: "06:00 AM", value: "06:00" },
  { label: "07:00 AM", value: "07:00" },
  { label: "08:00 AM", value: "08:00" },
  { label: "09:00 AM", value: "09:00" },
  { label: "10:00 AM", value: "10:00" },
  { label: "11:00 AM", value: "11:00" },
  { label: "12:00 PM", value: "12:00" },
  { label: "01:00 PM", value: "13:00" },
  { label: "02:00 PM", value: "14:00" },
  { label: "03:00 PM", value: "15:00" },
  { label: "04:00 PM", value: "16:00" },
  { label: "05:00 PM", value: "17:00" },
  { label: "06:00 PM", value: "18:00" },
  { label: "07:00 PM", value: "19:00" },
  { label: "08:00 PM", value: "20:00" },
  { label: "09:00 PM", value: "21:00" },
  { label: "10:00 PM", value: "22:00" },
  { label: "11:00 PM", value: "23:00" },
];

const Availabilities = (props) => {
  const [availability, setAvailability] = useState([]);
  const [rawData, setRawData] = useState({});
  const [reRender, serReRender] = useState(false);

  const customAlert = useContext(CustomAlertContext);

  useEffect(() => {
    getAvailability(props.vendorId);
  }, []);

  const getAvailability = (vendorId) => {
    get(`/availabilities?vendor_id=` + vendorId).then((res) => {
      let { result } = res;
      try {
        let items = [];
        Object.keys(result).map((item) => {
          if (typeof result[item] !== "string") {
            items.push({
              ...result[item],
              day: item,
              close_time: result[item].close_time || "18:00",
              open_time: result[item].open_time || "09:00",
            });
          }
        });
        setAvailability(items);
        setRawData(result);
      } catch (error) {
        //
      }
    });
  };

  const handleIsClosedToggle = (day, newValue) => {
    let days = availability;
    days.map((item) => {
      if (item.day === day) {
        item.is_closed = newValue;
      }
    });

    serReRender(!reRender);
    setAvailability(days);
  };

  const handleTimeChange = (day, key, value) => {
    let days = availability;
    days.map((item) => {
      if (item.day === day) {
        item[key] = value;
      }
    });

    serReRender(!reRender);
    setAvailability(days);
  };

  const getDropDown = (day, key, value, fullRow) => (
    <select
      key={key}
      value={value}
      onChange={(e) => handleTimeChange(day, key, e.target.value)}
      disabled={fullRow.is_closed}
    >
      {daysOptions.map((item) => (
        <option value={item.value} key={key + item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );

  const handleUpdateAvailabilities = () => {
    let days = JSON.parse(JSON.stringify(availability));
    let input_data = { vendor_id: rawData.vendor_id };

    days.map((item) => {
      let day = item.day;
      delete item.day;
      input_data[day] = item;
    });

    put("availabilities/" + rawData.id, input_data)
      .then((res) => {
        customAlert.success(res.message);
      })
      .catch((error) => {
        let msg = errorHandler(error);
        customAlert.error(msg);
      });
  };

  const columns = [
    {
      dataField: "day",
      text: "Day",
    },
    {
      dataField: "is_closed",
      text: "Open?",
      formatter: (_cell, _row) => {
        return (
          <Switch
            onColor="#fdab00"
            onChange={(value) => handleIsClosedToggle(_row.day, !value)}
            checked={!_cell}
            height={25}
            width={50}
          />
        );
      },
    },
    {
      dataField: "open_time",
      text: "Opening Time",
      formatter: (_cell, _row) => getDropDown(_row.day, "open_time", _cell, _row),
    },
    {
      dataField: "close_time",
      text: "Closing Time",
      formatter: (_cell, _row) => getDropDown(_row.day, "close_time", _cell, _row),
    },
  ];

  return (
    <Card>
      <CardBody key={reRender}>
        <BootstrapTable
          keyField="day"
          data={availability}
          columns={columns}
          striped
          hover
          wrapperClasses="table-responsive my-2"
          noDataIndication={"No data available."}
        />
        <Button color="primary" onClick={handleUpdateAvailabilities}>
          Update
        </Button>
      </CardBody>
    </Card>
  );
};

export default Availabilities;
