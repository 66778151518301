import { createContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CustomAlertContext = createContext("");

export const CustomAlertContextProvider = ({ children }) => {
  const [type, setType] = useState("success");

  const success = (message) => {
    toast.success(message);
    setType("success");
  };

  const setToastNotification = (message, type = "success") => {
    toast[type](message);
    setType(type);
  };

  const error = (message) => {
    toast.error(message);
    setType("error");
  };
  return (
    <CustomAlertContext.Provider value={{ success, error, setToastNotification }}>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        //   newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        type={type}
      />
      {children}
    </CustomAlertContext.Provider>
  );
};
