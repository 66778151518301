import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Privacy from "../pages/Privacy/index";
import TermsCondition from "../pages/TermsCondition/index";
import Disclaimer from "../pages/Disclaimer/index";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Home from "../pages";

//profile
import ProfileForm from "@pages/Profile/Profile";

//FAQ
import Faqs from "@pages/Faqs";
import FaqsForm from "@pages/Faqs/FaqForm";

//Services
import ServicesForm from "@pages/Services/ServicesForm";

//Quotations
import Quotations from "@pages/Quotations/QuotationsList";
import QuotationsForm from "@pages/Quotations/QuotationForm";

//Transactions
import Transactions from "@pages/Transactions/index";
//Offer
import OffersForm from "@pages/Offers/OffersForm";

//Jobs
import JobsForm from "@pages/Jobs/JobsForm";

// Notifications - new
import Notification from "@pages/Notifications-new/NotificationList";

//Admin Routes

// Dashboard
import AdminDashboard from "../pages/Dashboard/AdminDashboard";

//Categories
import Categories from "@pages/Categories";
import CategoryForm from "@pages/Categories/CategoryForm";

//Tags
import Tags from "@pages/Tags";
import TagsForm from "@pages/Tags/TagsForm";

//InfoPages
import InfoPages from "@pages/InfoPages";
import InfoPageForm from "@pages/InfoPages/PageForm";

//vendors
import Vendors from "@pages/Vendors";
import VendorBusinessDetailForm from "@components/Vendors/VendorBusinessDetailForm";

import VendorsForm from "@pages/Vendors/VendorDetails";

//UserManuals
import UserManuals from "@pages/UserManuals";
import UserManualForm from "@pages/UserManuals/UserManualForm";

//Riders
import Riders from "@pages/Rider";
import RiderForm from "@pages/Rider/RiderForm";
import RiderDetails from "@pages/Rider/RiderDetails";
// import RiderFormAdd from "@pages/Rider/RiderAdd";

//Setting
import Settings from "@pages/Settings";
import SettingsForm from "@pages/Settings/SettingsForm";

//Orders
import Orders from "@pages/Orders/OrderList";
import OrderDetails from "@pages/Orders/OrderDetalis";

//Promotions
import Promotions from "@pages/Promotions";
import PromotionsForm from "@pages/Promotions/PromotionsForm";
//Consumer
import Customers from "@pages/Customers-new";
import CustomersForm from "@pages/Customers-new/CustomersForm";
// import ConsumersForm from "@pages/Customers/ConsumerForm";

//Contact
import Contacts from "@pages/Contacts";
import ContactsForm from "@pages/Contacts/ContactsForm";

//Messengers
import MessengersList from "@components/Messenger";

const vendorRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/profile", component: ProfileForm },
  { path: "/notifications", component: Notification },
  { path: "/vendors/view/:id", component: (props) => <VendorsForm disabled={true} {...props} /> },
  { path: "/faqs", component: (props) => <Faqs isAdmin={false} {...props} /> },
  { path: "/faqs/view/:id", component: (props) => <FaqsForm disabled={true} isAdmin={false} {...props} /> },
  { path: "/user-manuals", component: (props) => <UserManuals isAdmin={false} {...props} /> },
  {
    path: "/user-manuals/view/:id",
    component: (props) => <UserManualForm disabled={true} isAdmin={false} {...props} />,
  },
  { path: "/info-pages", component: (props) => <InfoPages isAdmin={false} {...props} /> },
  { path: "/info-pages/view/:id", component: (props) => <InfoPageForm disabled={true} isAdmin={false} {...props} /> },
  { path: "/riders", component: (props) => <Riders isAdmin={false} {...props} /> },
  { path: "/riders/add", component: (props) => <RiderForm isAdmin={false} {...props} /> },
  { path: "/riders/edit/:id", component: RiderDetails },
  { path: "/riders/view/:id", component: (props) => <RiderDetails disabled={true} isAdmin={false} {...props} /> },
  { path: "/orders", component: Orders },
  { path: "/orders/view/:id", component: OrderDetails },
  { path: "/quotations", component: (props) => <Quotations isAdmin={false} {...props} /> },
  { path: "/quotations/view/:id", component: (props) => <QuotationsForm disabled={true} isAdmin={false} {...props} /> },
  { path: "/transactions", component: (props) => <Transactions isAdmin={false} {...props} /> },
  { path: "/services/add", component: ServicesForm },
  { path: "/services/edit/:id", component: ServicesForm },
  { path: "/services/view/:id", component: (props) => <ServicesForm disabled={true} {...props} /> },

  //Offers
  { path: "/offers/add", component: OffersForm },
  { path: "/offers/edit/:id", component: OffersForm },
  { path: "/offers/view/:id", component: (props) => <OffersForm disabled={true} {...props} /> },

  //contacts
  { path: "/contacts/add", component: ContactsForm },

  //Jobs
  { path: "/jobs/add", component: JobsForm },
  { path: "/jobs/edit/:id", component: JobsForm },
  { path: "/jobs/view/:id", component: (props) => <JobsForm disabled={true} {...props} /> },

  //Messenger
  { path: "/messenger", component: MessengersList },
  //promotions
  { path: "/promotions", component: Promotions },
  { path: "/promotions/view/:id", component: (props) => <PromotionsForm disabled={true} {...props} /> },

  //Categories
  { path: "/categories", component: Categories },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];
const adminRoutes = [
  { path: "/dashboard", component: AdminDashboard },
  { path: "/profile", component: ProfileForm },
  { path: "/notifications", component: Notification },
  { path: "/info-pages", component: InfoPages },
  { path: "/info-pages/add", component: InfoPageForm },
  { path: "/info-pages/edit/:id", component: InfoPageForm },
  { path: "/info-pages/view/:id", component: (props) => <InfoPageForm disabled={true} {...props} /> },
  { path: "/vendors", component: Vendors },
  // { path: "/vendors/add", component: VendorAddForm },
  { path: "/vendors/add", component: (props) => <VendorBusinessDetailForm isAdmin={true} {...props} /> },
  { path: "/vendors/edit/:id", component: (props) => <VendorsForm isAdmin={true} {...props} /> },
  { path: "/vendors/view/:id", component: (props) => <VendorsForm isAdmin={true} disabled={true} {...props} /> },
  // { path: "/vendors/view/:id", component: (props) => <InfoPageForm disabled={true} {...props} /> },

  // FAQ's
  { path: "/faqs", component: Faqs },
  { path: "/faqs/add", component: FaqsForm },
  { path: "/faqs/edit/:id", component: FaqsForm },
  { path: "/faqs/view/:id", component: (props) => <FaqsForm disabled={true} {...props} /> },

  //UserManual
  { path: "/user-manuals", component: UserManuals },
  { path: "/user-manuals/add", component: UserManualForm },
  { path: "/user-manuals/edit/:id", component: UserManualForm },
  { path: "/user-manuals/view/:id", component: (props) => <UserManualForm disabled={true} {...props} /> },

  //Category
  { path: "/categories", component: Categories },
  { path: "/categories/add", component: CategoryForm },
  { path: "/categories/edit/:id", component: CategoryForm },
  { path: "/categories/view/:id", component: (props) => <CategoryForm disabled={true} {...props} /> },

  //Tags
  { path: "/tags", component: Tags },
  { path: "/tags/add", component: TagsForm },
  { path: "/tags/edit/:id", component: TagsForm },
  { path: "/tags/view/:id", component: (props) => <TagsForm disabled={true} {...props} /> },

  //Rider
  { path: "/riders", component: Riders },
  // { path: "/riders/add", component: RiderDetails },
  { path: "/riders/add", component: RiderForm },
  { path: "/riders/edit/:id", component: RiderDetails },
  { path: "/riders/view/:id", component: (props) => <RiderDetails disabled={true} {...props} /> },

  // //Orders
  // { path: "/orders/view/:id", component: OrderDetails },

  //Customers
  { path: "/consumers", component: Customers },
  { path: "/consumers/add", component: CustomersForm },
  { path: "/consumers/edit/:id", component: CustomersForm },
  { path: "/consumers/view/:id", component: (props) => <CustomersForm disabled={true} {...props} /> },

  //Setting
  { path: "/settings", component: Settings },
  { path: "/settings/add", component: SettingsForm },
  { path: "/settings/edit/:id", component: SettingsForm },
  { path: "/settings/view/:id", component: (props) => <SettingsForm disabled={true} {...props} /> },

  //Contact
  { path: "/contacts", component: Contacts },

  //promotions
  { path: "/promotions", component: Promotions },
  { path: "/promotions/add", component: PromotionsForm },
  { path: "/promotions/edit/:id", component: PromotionsForm },
  { path: "/promotions/view/:id", component: (props) => <PromotionsForm disabled={true} {...props} /> },

  //Services
  { path: "/services/add", component: ServicesForm },
  { path: "/services/edit/:id", component: ServicesForm },
  { path: "/services/view/:id", component: (props) => <ServicesForm disabled={true} {...props} /> },

  //Quotations
  { path: "/quotations", component: Quotations },
  // { path: "/quotations/add", component: QuotationsForm },
  // { path: "/quotations/edit/:id", component: QuotationsForm },
  { path: "/quotations/view/:id", component: QuotationsForm },

  //Order
  { path: "/orders", component: Orders },
  { path: "/orders/view/:id", component: OrderDetails },

  //Offers
  { path: "/offers/add", component: OffersForm },
  { path: "/offers/edit/:id", component: OffersForm },
  { path: "/offers/view/:id", component: (props) => <OffersForm disabled={true} {...props} /> },

  //Jobs
  { path: "/jobs/add", component: JobsForm },
  { path: "/jobs/edit/:id", component: JobsForm },
  { path: "/jobs/view/:id", component: (props) => <JobsForm disabled={true} {...props} /> },

  //Messenger
  { path: "/messenger", component: MessengersList },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  //Transactions
  { path: "/transactions", component: (props) => <Transactions isAdmin={true} {...props} /> },
];

const nonAuthRoutes = [
  { path: "/", component: Home, exact: true },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/privacy-policy", component: Privacy },
  { path: "/terms-condition", component: TermsCondition },
  { path: "/disclaimer", component: Disclaimer },
];

export { vendorRoutes, adminRoutes, nonAuthRoutes };
