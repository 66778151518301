import React, { useState, useEffect, useContext } from "react";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import CustomImagePreview from "@components/Common/CustomImagePreview";
import CustomDropzone from "@components/Common/CustomDropzone";
import { selectField } from "components/Common/Form/InputFields";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMobileInput from "@components/Common/CustomMobileInput";

const FormSchema = Yup.object().shape({
  type: Yup.string().strict().trim().required().label("Customer type").max(200),
  first_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .label("First name")
    .max(40)
    .required(),
  business_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .when("type", {
      is: "business",
      then: Yup.string().required().label("Business name"),
    }),
  // business_name: Yup.string()
  //   .matches(/^[A-Za-z ]*$/, "Please enter valid name")
  //   .label("Business name")
  //   .max(40)
  //   .required(),
  // user_name: Yup.string().strict().trim().required(yupMessages.required).max(200),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .label("Last name")
    .max(40)
    .required(),
  email: Yup.string().email("Please enter valid email").required().label("Email").max(40),
  mobile: Yup.string().required(),
  country_iso: Yup.number().required(yupMessages.required),
  profile_image: Yup.mixed()
    .required("Profile image is required.")
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
    ),
});

const Form = (props) => {
  const { disabled = false, item_id, data = {} } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);

  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/consumers/${item_id}`, data) : (data) => post(`/consumers`, data);

    let data = new FormData();
    data.append("first_name", values.first_name.trim());
    if (values.business_name) {
      data.append("business_name", values.business_name.trim());
    }
    // data.append("user_name", values.user_name);
    data.append("last_name", values.last_name.trim());
    data.append("email", values.email);
    data.append("mobile", values.mobile);
    data.append("type", values.type);
    data.append("country_iso", values.country_iso);

    if (values.profile_image && typeof values.profile_image != "string") {
      data.append("profile_image", values.profile_image);
    }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = `/consumers`;
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || "",
      business_name: data.business_name || "",
      // user_name: data.user_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      type: data.type || "",
      mobile: data.mobile || "",
      country_iso: `${data.country_iso || ""}`,
      description: data.description || "",
      profile_image: data.profile_image_url || "",
      wallet: data.wallet || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  let options = [
    { name: "Business", value: "business" },
    { name: "Individual", value: "individual" },
  ];

  const type = (
    <Col className="col-6 mt-1">{selectField(formik, "type", "Type", options, "Please select type", disabled)}</Col>
  );
  const first_name = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="first_name"
        label={"First Name"}
        placeholder="Enter First Name"
        disabled={disabled}
      />
    </Col>
  );
  const business_name = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="business_name"
        label={"Business Name"}
        placeholder="Enter Business Name"
        disabled={disabled}
      />
    </Col>
  );
  // const user_name = (
  //   <Col className="col-6">
  //     <CustomInput
  //       formik={formik}
  //       name="user_name"
  //       label={"User Name"}
  //       placeholder="Enter User Name"
  //       disabled={disabled}
  //     />
  //   </Col>
  // );
  const last_name = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="last_name"
        label={"Last Name"}
        placeholder="Enter Last Name"
        disabled={disabled}
      />
    </Col>
  );

  const wallet = !disabled ? null : (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="wallet"
        child_name="value"
        label={`Wallet Balance (${data?.wallet?.currency || ""})`}
        disabled="true"
      />
    </Col>
  );

  const email = (
    <Col className="col-6">
      <CustomInput formik={formik} name="email" label={"Email"} placeholder="Enter Email" disabled={disabled} />
    </Col>
  );

  const mobileNumber = (
    <CustomMobileInput
      label="Mobile"
      country_iso={formik.values.country_iso || 61}
      number={formik.values.mobile}
      handleChange={(phone, country) => {
        if (country.dialCode !== formik.values.country_iso) {
          formik.setFieldValue("country_iso", country.dialCode);
          formik.setFieldValue("mobile", "");
        } else {
          formik.setFieldValue("mobile", phone.slice(country.dialCode.length));
        }
      }}
      disabled={disabled}
      errorMsg={formik.errors["mobile"]}
      className="col-6"
    />
  );

  const profile_image = (
    <Col className="col-12 my-2">
      <label className="mr-2">Profile Image</label>
      <Row>
        <Col className="col-12">
          <CustomImagePreview file={formik.values.profile_image} />
        </Col>
        {formik.errors && formik.errors.profile_image ? (
          <Col className="col-12">
            <span className="error">{formik.errors.profile_image}</span>
          </Col>
        ) : null}
        {disabled ? null : (
          <Col className="col-12 mt-2">
            <CustomDropzone
              onChange={(acceptedFiles) => formik.setFieldValue("profile_image", acceptedFiles[0])}
              allowedTypes="image/*"
              multiple={false}
            />
          </Col>
        )}
      </Row>
    </Col>
  );

  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {first_name}
            {last_name}
            {type}
            {email}
            {formik.values.type == "business" ? business_name : null}
            {/* {user_name} */}
            {mobileNumber}
            {wallet}
            {profile_image}
            {submit_btn}
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

const CustomersForm = ({ disabled = false, isAdmin = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/consumers/${props.match.params.id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Customer"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Customers", href: "/consumers" }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Customer"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form
            data={data}
            item_id={editInfo ? props.match.params.id : undefined}
            disabled={disabled}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </div>
  );
};

export default CustomersForm;
