import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import CustomSwitch from "@components/Common/Form/CustomSwitch";
import CustomImagePreview from "@components/Common/CustomImagePreview";
import CustomDropzone from "@components/Common/CustomDropzone";
import { selectField } from "components/Common/Form/InputFields";

const FormSchema = Yup.object().shape({
  type: Yup.string().strict().trim().required(yupMessages.required).max(200),
  title: Yup.string().strict().trim().required(yupMessages.required).max(200),
  status: Yup.boolean().required(yupMessages.required),
  file: Yup.mixed()
    .required("File is required.")
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        (value && value.type && (value.type.indexOf("application") > -1 || value.type.indexOf("text") > -1)) ||
        typeof value === "string"
    ),
});

const Form = (props) => {
  const { disabled = false, item_id, data = {}, isAdmin } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/user-manuals/${item_id}`, data) : (data) => post(`/user-manuals`, data);

    let data = new FormData();
    data.append("title", values.title);
    data.append("type", values.type);
    data.append("status", values.status);

    if (values.file && typeof values.file != "string") {
      data.append("file", values.file);
    }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = "/user-manuals";
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      title: data.title || "",
      type: data.type || "",
      description: data.description || "",
      status: typeof data.status === "boolean" ? data.status : true,
      is_verified: typeof data.is_verified === "boolean" ? data.is_verified : true,
      file: data.file_url || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  let options = [
    { name: "All", value: "all" },
    { name: "Vendors", value: "vendor" },
    { name: "Rider", value: "rider" },
    { name: "User", value: "user" },
  ];

  const type = (
    <Col className="col-6 mt-1">{selectField(formik, "type", "Type", options, "Please select type", disabled)}</Col>
  );

  const title = (
    <Col className="col-6">
      <CustomInput formik={formik} name="title" label={"Title"} placeholder="Enter title" disabled={disabled} />
    </Col>
  );

  const file = (
    <Col className="col-12">
      <label className="mr-2">Manual File</label>
      <Row>
        <Col className="col-12">
          <CustomImagePreview file={formik.values.file} />
        </Col>
        {formik.errors && formik.errors.file ? (
          <Col className="col-12">
            <span className="error">{formik.errors.file}</span>
          </Col>
        ) : null}
        {disabled ? null : (
          <Col className="col-12 mt-2">
            <CustomDropzone
              onChange={(acceptedFiles) => formik.setFieldValue("file", acceptedFiles[0])}
              allowedTypes="text/plain, application/pdf, application/msword"
              multiple={false}
            />
          </Col>
        )}
      </Row>
    </Col>
  );

  const status = isAdmin ? (
    <>
      <Col className="col-6">
        <CustomSwitch name={"is_verified"} formik={formik} disabled={disabled} label="Is verified" className="my-1" />
      </Col>
      <Col className="col-6">
        <CustomSwitch name={"status"} formik={formik} disabled={disabled} label="Status" className="my-1" />
      </Col>
    </>
  ) : null;

  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {type}
            {title}
            {file}
            {status}
            {submit_btn}
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

const UserManualForm = ({ disabled = false, isAdmin = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/user-manuals/${props.match.params.id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " User Manual"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "User Manuals", href: "/user-manuals" }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " User Manual"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form
            data={data}
            item_id={editInfo ? props.match.params.id : undefined}
            disabled={disabled}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </div>
  );
};

export default UserManualForm;
