import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "reactstrap";
import { get } from "helpers/api_helper";
import { CustomAlertContext } from "contexts/CustomAlertContext";

// import images
import ConfirmOrders from "../../assets/images/services-icon/confirmOrders.png";
import Earning from "../../assets/images/services-icon/earning.png";
import QuotationRequest from "../../assets/images/services-icon/quotationRequest.png";
import TotalRiders from "../../assets/images/services-icon/totalRiders.png";
import CancelOrders from "../../assets/images/services-icon/cancelOrders.png";

//i18n
import { withTranslation } from "react-i18next";

import { UserContext } from "contexts";
import TotalCountCard from "components/TotalCountCard";
import CustomMetaTags from "components/Common/CustomMetaTags";

export const sortMessage = (mes) => {
  let message = mes;
  if (mes.length >= 40) {
    message = message.slice(0, 40) + "...";
  }
  return message;
};

const WarningMessage = ({ message }) => {
  const [showWarning, setShowWarning] = useState(true);

  return (
    <Alert
      color="warning"
      isOpen={showWarning}
      toggle={() => setShowWarning(!showWarning)}
      style={{ maxWidth: "fit-content", minWidth: "fit-content", margin: "4px" }}
      title={message}
    >
      {sortMessage(message)}
    </Alert>
  );
};

const Dashboard = () => {
  const customAlert = useContext(CustomAlertContext);
  const { getVendorDashboardStatics, dashboardStatics, user } = useContext(UserContext);
  const [warningsList, setWarningsList] = useState([]);
  useEffect(() => {
    window.location.search == "?payment=success"
      ? customAlert.success("Payment successful.")
      : window.location.search == "?payment=failed"
      ? customAlert.error("Payment failed! Please try again.")
      : "";

    getVendorDashboardStatics();

    get(`/vendors/${user.vendor_id}`)
      .then((res) => {
        let msgs = [];
        const { result } = res;

        if (!result?.services?.length) {
          msgs.push("Please add at least one service.");
        }
        if (!result?.category_ids?.length) {
          msgs.push("Please select at least one servicing category.");
        }
        if (!result.business_name) {
          msgs.push("Please add business name.");
        }
        if (!result?.contact?.email) {
          msgs.push("Please add business email.");
        }
        if (!result?.contact?.mobile) {
          msgs.push("Please add business contact number.");
        }
        if (!result.business_reg_certificate_url) {
          msgs.push("Please add business registration certificate.");
        }
        if (!result.logo) {
          msgs.push("Please add business logo for better user outreach.");
        }
        if (!result?.address?.city) {
          msgs.push("Please add address.");
        }
        if (!result?.medias?.length) {
          msgs.push("Please upload at least one media file for better customer engagement.");
        }
        setWarningsList(msgs);
      })
      .catch(() => {});
  }, []);

  const count_cards = (
    <Row>
      <TotalCountCard
        icon={ConfirmOrders}
        count={dashboardStatics?.total_confirmed_orders}
        label={"Confirm Order"}
        redirectLink={"/orders"}
      />
      <TotalCountCard
        icon={CancelOrders}
        count={dashboardStatics?.total_cancelled_orders}
        label={"Cancelled Orders"}
        redirectLink={"/orders"}
      />
      <TotalCountCard
        icon={TotalRiders}
        count={dashboardStatics?.total_delivery_partners}
        label={"Total Riders"}
        redirectLink={"/riders"}
      />
      <TotalCountCard
        icon={QuotationRequest}
        count={dashboardStatics?.total_quotation_requests}
        label={"Total Quotation Requests"}
        redirectLink={"/quotations"}
      />
      <TotalCountCard
        icon={Earning}
        count={dashboardStatics?.total_earning}
        label={"Total Earning"}
        redirectLink={`/vendors/view/${user.vendor_id}?tab=8`}
      />
    </Row>
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <CustomMetaTags title="Dashboard" />
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title text-dark">Dashboard</h6>
              </Col>
              <Col md="4"></Col>
            </Row>
          </div>
          {warningsList.length ? (
            <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "1.25rem" }}>
              {warningsList.map((msg, index) => (
                <WarningMessage message={msg} key={index} />
              ))}
            </div>
          ) : null}
          {count_cards}
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
