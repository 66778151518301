import React, { useState, useContext } from "react";
import { errorHandler, put } from "helpers/api_helper";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import { UserContext } from "contexts/UserContext";
import { EmailVerification } from "@pages/Authentication/Register";
import Wallet from "@pages/Wallet";
// import CustomImagePreview from "@components/Common/CustomImagePreview";
// import CustomDropzone from "@components/Common/CustomDropzone";

const FormSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required()
    .label("First name"),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required()
    .label("Last name"),
  device_id: Yup.string().strict().required(yupMessages.required).max(200),
  type: Yup.string().strict().required(yupMessages.required).max(200),
  passport_number: Yup.string()
    .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid Passport Number")
    .max(20)
    .required()
    .label("Passport Number"),
  profile_image: Yup.mixed()
    .required("Profile image is required.")
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
    ),
});

const VendorProfileUpdate = (props) => {
  const { disabled = false } = props;
  const customAlert = useContext(CustomAlertContext);
  const { user, isAdmin } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  // const [show, setShow] = useState(false);

  // useEffect(() => {
  //   checkProfile();
  // }, []);

  const handleSubmit = (values) => {
    let request = (data) => put(`/consumer/user`, data);
    let data = new FormData();
    data.append("first_name", values.first_name.trim());
    data.append("last_name", values.last_name.trim());
    data.append("device_id", values.device_id);
    data.append("type", values.type);
    data.append("passport_number", values.passport_number.trim());

    if (values.profile_image && typeof values.profile_image != "string") {
      data.append("profile_image", values.profile_image);
    }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = "/profile";
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      device_id: user.device_id || "lkldfjs",
      type: "business",
      profile_image: user.profile_image_url || "",
      passport_number: user.passport_number || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const first_name = (
    <Col className="col-6">
      <CustomInput formik={formik} name="first_name" label={"First Name"} placeholder="Enter first name" />
    </Col>
  );
  const last_name = (
    <Col className="col-6">
      <CustomInput formik={formik} name="last_name" label={"Last Name"} placeholder="Enter last name" />
    </Col>
  );
  const passport_number = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="passport_number"
        label="ID/Passport No"
        type="text"
        placeholder="Enter ID/Passport no"
        disabled={loader}
      />
    </Col>
  );

  const wallet = (
    <Col className="col-9">
      <Wallet user={user} />
    </Col>
  );
  const submit_btn = disabled ? null : (
    <div>
      <Button style={{ float: "right" }} type="submit" color="primary" className="mt-4" disabled={loader}>
        Update Details
      </Button>
    </div>
  );

  return (
    <>
      {isAdmin() ? (
        <Card>
          <CardBody>
            <div style={{ width: "40%" }}>
              <EmailVerification removeDisable={true} />{" "}
            </div>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardBody>
            {wallet}
            <form onSubmit={formik.handleSubmit}>
              <Row>
                {first_name}
                {last_name}
                {passport_number}
                {submit_btn}
              </Row>
            </form>
            <Row>
              <div style={{ width: "50%" }}>
                <EmailVerification removeDisable={true} vendorUpdateVerify={true} />{" "}
              </div>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default VendorProfileUpdate;
