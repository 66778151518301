/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { Link } from "react-router-dom";

import Footer from "@components/AuthLayout/Footer";
import logo from "@assets/images/xturra-logo-512x512.png";
import { APP_NAME } from "@utils/index";
import OtpInput from "react-otp-input";
import VerifyImg from "@assets/images/verify.png";
import { UserContext } from "@contexts";
// import VerifyNumber from "@pages/VerifyNumber";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { CustomTextField, CustomForm } from "@components/Common/Form";
import { CustomAlertContext } from "@contexts/CustomAlertContext";

export const EmailVerification = ({
  handleOnOtpVerifySuccess = () => {},
  disabled = false,
  isRegister = false,
  removeDisable,
}) => {
  const { sendEmailOtp, verifyEmailOtp, user, setUser } = useContext(UserContext);
  const customAlert = useContext(CustomAlertContext);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [emailViewOtp, setEmailViewOtp] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [sendOtpButton, setSendOtpButton] = useState(true);
  const [disableEmailInput, setDisableEmailInput] = useState(disabled);

  useEffect(() => {
    if (!email) setDisableEmailInput(disabled);
  }, [disabled]);

  useEffect(() => {
    if (user.email) {
      setEmail(user.email);
      setEmailVerify(true);
      removeDisable ? setDisableEmailInput(false) : setDisableEmailInput(true);
    }

    if (isRegister && user.mobile && user.email && user.first_name) {
      window.location.href = "/dashboard";
    }
  }, []);

  useEffect(() => {
    removeDisable ? (user.email == email ? setEmailVerify(true) : setEmailVerify(false)) : null;
  }, [email]);

  useEffect(() => {
    if (otp.length == 4) {
      handleVerifyEmailOtp();
    }
  }, [otp]);

  const handleOtpChange = (otp) => {
    setOtp(otp);
    setEmailVerify(false);
  };

  const handleVerifyEmailOtp = () => {
    verifyEmailOtp(email.toLowerCase(), otp)
      .then((response) => {
        if (response) {
          setEmailViewOtp(false);
          setEmailVerify(true);
          setSendOtpButton(false);
          if (removeDisable) {
            setDisableEmailInput(false),
              setOtp(""),
              setUser({
                ...user,
                email: email,
              });
          } else {
            setDisableEmailInput(true);
            if (response?.user?.email && response?.user?.first_name) {
              customAlert.success("You are already a registered user.");
              window.location.href = "/dashboard";
            }
          }
          handleOnOtpVerifySuccess();
        }
      })
      .catch(() => {});
  };

  const otp_input = (
    <div className="mt-3">
      <label>OTP</label>
      <OtpInput
        value={otp}
        onChange={handleOtpChange}
        numInputs={4}
        separator={<span style={{ width: "0px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
          border: "1px solid #ced4da",
          borderRadius: "4px",
          width: "35px",
          height: "35px",
          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          marginRight: "8px",
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none",
        }}
      />
    </div>
  );

  const handleOtpSend = () => {
    // e.preventDefault();

    sendEmailOtp(email.toLowerCase()).then((response) => {
      if (response) {
        setEmailViewOtp(false);
        setSendOtpButton(true);
      } else {
        setEmailViewOtp(true);
        if (sendOtpButton) {
          setSendOtpButton(false);
        }
      }
    });
  };

  const otp_send_btn = (
    <button
      className="btn btn-primary waves-effect waves-light send-otp-button"
      type="submit"
      disabled={disableEmailInput}
    >
      {sendOtpButton ? "Send" : "Re-send"} OTP
    </button>
  );

  return (
    <div className="my-2 position-relative">
      <CustomForm onValidSubmit={handleOtpSend}>
        <CustomTextField
          name="email"
          label="Email"
          className="form-control "
          placeholder="Enter your email"
          type="email"
          required
          onChange={(event) => setEmail(event.target.value)}
          disabled={disableEmailInput}
          validate={{
            required: { value: true, errorMessage: "Please enter your email." },
          }}
          value={email}
        />
        {emailVerify ? <img className="verify-icon" src={VerifyImg} /> : otp_send_btn}
        {emailViewOtp ? otp_input : null}
      </CustomForm>
    </div>
  );
};

const Register = () => {
  const { handleRegisterVendor, user } = useContext(UserContext);
  const [disableRegisterForm, setDisableRegisterForm] = useState(true);

  useEffect(() => {
    // if (user.email && user.mobile) {
    if (user.email) {
      setDisableRegisterForm(false);
    } else if (!user.email) {
      setDisableRegisterForm(true);
    }
  }, [user]);

  const handleValidSubmit = (values) => {
    handleRegisterVendor(values);
  };

  const signup_form = (
    <>
      {/* <VerifyNumber
        handleOnOtpVerifySuccess={() => {
          if (user.email) {
            setDisableRegisterForm(false);
          }
        }}
        isRegister={true}
      /> */}
      <EmailVerification
        handleOnOtpVerifySuccess={() => setDisableRegisterForm(false)}
        // disabled={!user.mobile}
        isRegister={true}
      />
      <CustomForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(v);
        }}
      >
        <CustomTextField
          containerClassName="my-2"
          name="password"
          label="Password"
          type="password"
          required
          placeholder="Enter your password"
          validate={{
            required: { value: true, errorMessage: "Please enter password." },
            pattern: {
              value: "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$",
              errorMessage: "Password must have 1 uppercase, 1 lowercase, 1 special character and 1 number.",
            },
            minLength: { value: 8, errorMessage: "Your password must be between 8 and 16 characters" },
            maxLength: { value: 16, errorMessage: "Your password must be between 8 and 16 characters" },
          }}
          disabled={disableRegisterForm}
        />
        <CustomTextField
          name="confirm_password"
          label="Confirm Password"
          required
          placeholder="Please Confirm Password"
          validate={{
            required: { value: true, errorMessage: "Please confirm password." },
            match: { value: "password", errorMessage: "Confirm Password & Password must be same." },
          }}
          disabled={disableRegisterForm}
          containerClassName="my-2"
        />
        <CustomTextField
          containerClassName="my-2"
          name="first_name"
          label="First Name"
          required
          placeholder="Enter first name"
          validate={{
            required: { value: true, errorMessage: "Please enter first name." },
            maxLength: { value: 25 },
          }}
          disabled={disableRegisterForm}
        />
        <CustomTextField
          containerClassName="my-2"
          name="last_name"
          label="Last Name"
          required
          placeholder="Enter last name"
          validate={{
            required: { value: true, errorMessage: "Please enter last name." },
            maxLength: { value: 25 },
          }}
          disabled={disableRegisterForm}
        />
        <CustomTextField
          containerClassName="my-2"
          name="business_name"
          label="Business Name"
          required
          placeholder="Enter Business Name"
          validate={{
            required: { value: true, errorMessage: "Please enter business name." },
            maxLength: { value: 50 },
          }}
          disabled={disableRegisterForm}
        />
        <CustomTextField
          containerClassName="my-2"
          name="business_link"
          label="Business Link"
          // required
          placeholder="Enter Business Link"
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter business link.",
            },
            pattern: {
              value: new RegExp(
                /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                "i"
              ),
              errorMessage: "Requested value must be a link.",
            },
          }}
          disabled={disableRegisterForm}
        />
        <CustomTextField
          containerClassName="my-2"
          name="passport_number"
          label="ID/Passport No"
          required
          placeholder="Enter your ID/Passport no"
          validate={{
            required: { value: true, errorMessage: "Please enter your ID/Passport No." },
            maxLength: { value: 16 },
          }}
          disabled={disableRegisterForm}
        />
        <div className="mb-1 row">
          <div className="col-12 text-end">
            <button
              className="btn btn-primary w-md waves-effect waves-light"
              type="submit"
              disabled={disableRegisterForm}
            >
              Register
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            By registering you agree to the {APP_NAME}{" "}
            <Link to="/terms-condition" className="link-color">
              Terms of Use
            </Link>
          </div>
        </div>
      </CustomForm>
    </>
  );

  return (
    <React.Fragment>
      <CustomMetaTags title="Register" />
      <div className="account-pages non-auth-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#E8E8E8" }}>
                  <div className="text-center p-4">
                    <h5 className="font-size-20">Welcome to {APP_NAME}</h5>
                    <p>Get your account now.</p>
                    <a href="/" className="logo logo-admin">
                      <img src={logo} height="24" alt="logo" />
                    </a>
                  </div>
                </div>
                <CardBody className="p-4">{signup_form}</CardBody>
              </Card>
              <div className="mt-2 mb-3 text-center">
                Already have an account?{" "}
                <Link to="/login" className="fw-medium link-color">
                  Login
                </Link>{" "}
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Register;
