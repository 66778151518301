import { all, fork } from "redux-saga/effects";

//public
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";

export default function* rootSaga() {
  yield all([ProfileSaga(), LayoutSaga(), fork(calendarSaga)]);
}
