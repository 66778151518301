import CustomMetaTags from "@components/Common/CustomMetaTags";
import { CustomInput } from "@components/Common/Form";
import { CustomAlertContext } from "@contexts/CustomAlertContext";
import { useFormik } from "formik";
import { errorHandler, get, post } from "helpers/api_helper";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Button } from "reactstrap";
import * as Yup from "yup";

const navbar_items = [
  { title: "Home", href: "" },
  { title: "About Us", href: "#about-us" },
  { title: "How It Work", href: "#how-it-works" },
  { title: "Services", href: "#services" },
  { title: "Features", href: "#features" },
  { title: "FAQ", href: "#faq" },
  { title: "Contact", href: "#contact" },
  { title: "Login", href: "/login" },
  { title: "Register", href: "/register" },
];
const contactUsFormSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .required()
    .label("Full name")
    .max(50),
  email: Yup.string().email().required().label("Email").max(40),
  message: Yup.string().required().label("Message").max(200),
});

function ContactUs({ footerDetails }) {
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);

  const handleSubmit = (values, resetForm) => {
    setLoader(true);
    let data = new FormData();
    data.append("email", values.email);
    data.append("name", values.name);
    data.append("message", values.message);
    data.append("role", "user");

    post("/contacts", data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        resetForm();
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
      name: "",
    },
    validationSchema: contactUsFormSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
    enableReinitialize: true,
  });

  return (
    <div className="contact pb-5 p-100" id="contact">
      <div className="container">
        <div className="row">
          <div
            className="col-12 col-xl-7 mx-auto text-center mb-5"
            data-aos="zoom-in"
          >
            <h3 className="font-32 dark-black fw-bold mb-4">Get in touch!</h3>
            <p className="font-18 mb-0">
              We are interested in a long term partnership. When you hire
              Xturra, you get a reliable partner for all your logistic needs.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <h4 className="dark-black fw-500 pb-1">
              Interested in working with us?
            </h4>
            <p className="dark-black fw-500 mb-4 pb-3">
              LET&#39;S TALK AND GET STARTED
            </p>
            <div className="bg-white contact-form mb-4 d-flex align-items-center p-3">
              <img className="me-3" src="image/email.png" alt="" />
              <div className="hr-class">
                <p className="dark-black ms-3 fw-500 mb-2">
                  MAIL TO OUR SALES DEPARTMENT
                </p>
                <a
                  className="dark-black fw-500 text-decoration-none ms-3"
                  href="mailto:info@xturra.com"
                >
                  {footerDetails.email}
                </a>
              </div>
            </div>
            <div className="bg-white mb-4 contact-form d-flex align-items-center p-3">
              <img className="me-3" src="image/phone.png" alt="" />
              <div className="hr-class">
                <p className="dark-black ms-3 fw-500 mb-2">Call Us</p>
                <a
                  className="dark-black fw-500 text-decoration-none ms-3"
                  href="tel:+63-9568222858"
                >
                  {footerDetails.number}
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-7 offset-lg-1">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3 pb-1">
                    <CustomInput
                      formik={formik}
                      name="name"
                      label="Full Name"
                      placeholder="Enter name"
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3 pb-1">
                    <CustomInput
                      formik={formik}
                      name="email"
                      label="Email"
                      placeholder="Enter email"
                      disabled={loader}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-4 pb-3">
                  <CustomInput
                    formik={formik}
                    name="message"
                    label="Message"
                    placeholder="Enter message"
                    disabled={loader}
                  />
                </div>
              </div>
              <Button
                type="submit"
                color="primary"
                className="border-0 font-500 font-18 btn btn-primary bg-black px-5 py-3"
                disabled={loader}
              >
                SEND MESSAGE
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const Home = () => {
  const [loader, setLoader] = useState(true);
  const [faqsData, setFaqsData] = useState([]);
  const [footerDetails, setFooterDetails] = useState([]);

  useEffect(() => {
    import("../assets/scss/bootstrap.min.css").then(() => {
      import("../assets/scss/style.scss").then(() => {
        setLoader(false);
      });
    });
    getFaqs();
    getFooterDetails();
  }, []);

  const getFaqs = () => {
    get("/faqs")
      .then((res) => {
        setFaqsData(res.result.data);
      })
      .catch(() => {
        //
      });
  };

  const getFooterDetails = () => {
    get("/footer-details")
      .then((res) => {
        setFooterDetails(res.result);
      })
      .catch(() => {
        //
      });
  };
  if (loader) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <img src="/126594-truck.gif" height={280} />
      </div>
    );
  }

  const navbarMenus = (
    <div className="offcanvas-body">
      <ul className="navbar-nav align-items-lg-center ms-auto">
        {navbar_items.map((item, index) => (
          <li className="nav-item" key={index}>
            <a
              className="nav-link font-18 fw-500 dark-black mx-2 active primary-color font-600 "
              aria-current="page"
              href={item.href}
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  const navbar = (
    <header className="fixed-top">
      <nav className="navbar navbar-light navbar-expand-lg p-3">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src="image/logo.svg" alt="" style={{ width: "111px" }} />
          </a>
          {/* <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="right-btn">
            <a href="/login" className="me-2">
              Log In
            </a>
            <a href="/register">Register</a>
          </div>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <img src="images/Brand profile/brand-logo.svg" alt="" />
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            {navbarMenus}
          </div>
        </div>
      </nav>
    </header>
  );

  const aboutUs = (
    <div className="services py-5 p-100" id="about-us">
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-lg-row">
          <div
            className="col-xl-6 col-12 ps-0 services-left"
            data-aos="fade-right"
          >
            <img src="image/service.png" className="img-fluid" alt="" />
          </div>
          <div className="col-xl-6 col-12 service-right d-flex align-items-center">
            <div>
              <h3 className="font-36 dark-black fw-bold">
                On Demand Solution Transport
              </h3>
              <p className="py-4 gray-color">
                With our on-demand transport service, you may get the shipping
                solutions you require whenever you need them.
              </p>
              <div className="row">
                <div className="d-flex mb-4">
                  <div
                    className="d-block rounded-circle d-flex justify-content-center align-items-center me-4 service-circle p-3"
                    data-aos="fade-left"
                  >
                    <img
                      className="flip-img"
                      src="image/delivery-time.png"
                      alt=""
                    />
                  </div>
                  <div>
                    <h5 className="fw-600 dark-black">On-Time Delivery</h5>
                    <p className="gray-color">
                      All goods will be delivered on time thanks to the
                      dedication of our carriers.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div
                    className="d-block rounded-circle d-flex justify-content-center align-items-center me-4 service-circle p-3"
                    data-aos="fade-left"
                  >
                    <img className="flip-img" src="image/location.svg" alt="" />
                  </div>
                  <div>
                    <h5 className="fw-600 dark-black">Real-Time Location</h5>
                    <p className="gray-color">
                      Our delivery app allows you to track your goods in
                      real-time, providing you peace of mind throughout the
                      shipping process.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div
                    className="d-block rounded-circle d-flex justify-content-center align-items-center me-4 service-circle p-3"
                    data-aos="fade-left"
                  >
                    <img className="flip-img" src="image/money.png" alt="" />
                  </div>
                  <div>
                    <h5 className="fw-600 dark-black">Secure Payment</h5>
                    <p className="gray-color">
                      To ensure safe and secure payment for all transactions on
                      our marketplace, we employ Stripe solutions and
                      technologies. You can find out about Stripe at stripe.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const howItWorks = (
    <div className="how-it-works py-5 p-100" id="how-it-works">
      <div className="container">
        <div className="row">
          <div className="col-xl-11 col-12 text-center mx-auto">
            <h3 className="font-32 dark-black fw-bold mb-0">
              How Does Transport Delivery App Work?
            </h3>
            <p className="py-4 gray-color font-18">
              By putting you in touch with dependable carriers through our
              delivery app, you can evaluate shipping alternatives and follow
              the progress of your shipments in real time.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-md-4 col-6">
            <div
              className="circle-block d-flex justify-content-center align-items-center mx-auto rounded-circle p-4 bg-white"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/login.png" alt="" />
            </div>
            <p className="mt-4 dark-black text-center fw-500">Login/Signup</p>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div
              className="circle-block d-flex justify-content-center align-items-center mx-auto rounded-circle p-4 bg-white"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/approved.svg" alt="" />
            </div>
            <p className="mt-4 dark-black text-center fw-500">Make a Request</p>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div
              className="circle-block d-flex justify-content-center align-items-center mx-auto rounded-circle p-4 bg-white"
              data-aos="fade-up"
            >
              <img
                className="flip-img"
                src="image/cashless-payment.png"
                alt=""
              />
            </div>
            <p className="mt-4 dark-black text-center fw-500">Payment Option</p>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div
              className="circle-block d-flex justify-content-center align-items-center mx-auto rounded-circle p-4 bg-white"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/delivery-man.png" alt="" />
            </div>
            <p className="mt-4 dark-black text-center fw-500">Confirm Vendor</p>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div
              className="circle-block d-flex justify-content-center align-items-center mx-auto rounded-circle p-4 bg-white"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/delivery.png" alt="" />
            </div>
            <p className="mt-4 dark-black text-center fw-500">Track Courier</p>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div
              className="circle-block d-flex justify-content-center align-items-center mx-auto rounded-circle p-4 bg-white"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/rating.png" alt="" />
            </div>
            <p className="mt-4 dark-black text-center fw-500">
              Ratings & Reviews
            </p>
          </div>
          <div className="col-12 col-lg-10 mx-auto text-center">
            <img className="my-3 img-fluid" src="image/line.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );

  const services = (
    <div className="service-provider pt-5 p-100" id="services">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-12 mx-auto text-center">
            <h5 className="gray-color">For All Types of Service Providers</h5>
            <div className="seprator mx-auto mt-3 mb-3"></div>
            <h3 className="font-32 dark-black fw-bold pt-3 mb-4">
              A Flexible Transport App Solution
            </h3>
            <p className="font-18 mb-0">
              Our transportation app is designed to be flexible and adaptable,
              to meet the unique needs of each individual user or enterprise.
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div
              className="block bg-white py-4 px-3 text-center mt-5 mx-1"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/article1.png" alt="" />
              <h4 className="dark-black mb-0 mt-4">Documents & Parcels</h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div
              className="block bg-white py-4 px-3 text-center mt-5 mx-1"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/article2.png" alt="" />
              <h4 className="dark-black mb-0 mt-4">Food Products</h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div
              className="block bg-white py-4 px-3 text-center mt-5 mx-1"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/article3.png" alt="" />
              <h4 className="dark-black mb-0 mt-4">
                Relocations Packer & Movers
              </h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div
              className="block bg-white py-4 px-3 text-center mt-5 mx-1"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/article4.png" alt="" />
              <h4 className="dark-black mb-0 mt-4">Medicine Express</h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div
              className="block bg-white py-4 px-3 text-center mt-5 mx-1"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/article5.png" alt="" />
              <h4 className="dark-black mb-0 mt-4">
                Household & Corporate Shifting
              </h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div
              className="block bg-white py-4 px-3 text-center mt-5 mx-1"
              data-aos="fade-up"
            >
              <img className="flip-img" src="image/article6.png" alt="" />
              <h4 className="dark-black mb-0 mt-4">Car Carrier</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="first"></div>
    </div>
  );

  const features = (
    <div className="features pb-5 p-100" id="features">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-12 mx-auto text-center">
            <h5 className="gray-color">
              Fully Customizable Features With Unlimited Possibilities
            </h5>
            <div className="seprator mx-auto mt-3 mb-3"></div>
            <h3 className="font-32 dark-black fw-bold pt-3 mb-4">
              On Demand Transport App Features
            </h3>
            <p className="font-18 mb-0">
              Our transport app offers a variety of features including real-time
              tracking, secure payment and on-demand transport solutions.
            </p>
          </div>
          <div className="col-lg-4 col-12 mt-5">
            <div className="d-flex features-left mb-4 mt-4 mb-4">
              <div className="text-end">
                <h4 className="font-22 fw-600 dark-black mb-3">
                  Easy Onboarding
                </h4>
                <p className="gray-color">
                  Customers can register with email, mobile number or use social
                  logins
                </p>
              </div>
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center ms-3">
                <img
                  src="image/icon/item2.svg"
                  className="flip-img"
                  alt=""
                  style={{ width: "28px" }}
                />
              </span>
            </div>
            <div className="d-flex features-left mb-4 mt-4 mb-4">
              <div className="text-end">
                <h4 className="font-22 fw-600 dark-black mb-3">
                  In-app Payment
                </h4>
                <p className="gray-color">
                  Customers can pay with whatever option they like- cards, cash
                  or wallet
                </p>
              </div>
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center ms-3">
                <img
                  src="image/icon/item9.svg"
                  className="flip-img"
                  alt=""
                  style={{ width: "38px" }}
                />
              </span>
            </div>
            <div className="d-flex features-left mb-4 mt-4 mb-4">
              <div className="text-end">
                <h4 className="font-22 fw-600 dark-black mb-3">Book Parcel</h4>
                <p className="gray-color">
                  Customers can select the- to and from location and book the
                  parcel
                </p>
              </div>
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center ms-3">
                <img src="image/icon/item7.svg" className="flip-img" alt="" />
              </span>
            </div>
            <div className="d-flex features-left mb-4 mt-4 mb-4">
              <div className="text-end">
                <h4 className="font-22 fw-600 dark-black mb-3">
                  Real Time ETA
                </h4>
                <p className="gray-color">
                  Customers will get exact arrival time of their driver
                </p>
              </div>
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center ms-3">
                <img src="image/icon/itme3.svg" className="flip-img" alt="" />
              </span>
            </div>
            <div className="d-flex features-left mb-4 mt-4 mb-4">
              <div className="text-end">
                <h4 className="font-22 fw-600 dark-black mb-3">
                  Easy Onboarding
                </h4>
                <p className="gray-color">
                  Customers can register with email, mobile number or use social
                  logins
                </p>
              </div>
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center ms-3">
                <img src="image/icon/item10.svg" className="flip-img" alt="" />
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-12 features-center" data-aos="fade-up">
            <img
              src="image/home-xtrurra.svg"
              className="d-block mx-auto mt-5 img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-4 col-12 mt-5">
            <div className="d-flex mb-4 mt-4 mb-4">
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center me-3">
                <img
                  src="image/icon/item5.svg"
                  className="flip-img"
                  alt=""
                  style={{ width: "38px" }}
                />
              </span>
              <div>
                <h4 className="font-22 fw-600 dark-black mb-3">
                  Live Tracking
                </h4>
                <p className="gray-color">
                  Customers can register with email, mobile number or use social
                  logins
                </p>
              </div>
            </div>
            <div className="d-flex mb-4 mt-4 mb-4">
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center me-3">
                <img
                  src="image/icon/item6.svg"
                  className="flip-img"
                  alt=""
                  style={{ width: "30px" }}
                />
              </span>
              <div>
                <h4 className="font-22 fw-600 dark-black mb-3">
                  Check Booked Parcels List
                </h4>
                <p className="gray-color">
                  Customers can register with email, mobile number or use social
                  logins.
                </p>
              </div>
            </div>
            <div className="d-flex mb-4 mt-4 mb-4">
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center me-3">
                <img
                  src="image/icon/item4.svg"
                  className="flip-img"
                  alt=""
                  style={{ width: "30px" }}
                />
              </span>
              <div>
                <h4 className="font-22 fw-600 dark-black mb-3">
                  Notification Alerts
                </h4>
                <p className="gray-color">
                  Customers can select the- to and from location and book the
                  parcel
                </p>
              </div>
            </div>
            <div className="d-flex mb-4 mt-4 mb-4">
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center me-3">
                <img src="image/icon/item8.svg" className="flip-img" alt="" />
              </span>
              <div>
                <h4 className="font-22 fw-600 dark-black mb-3">
                  Cancel Booking
                </h4>
                <p className="gray-color">
                  Customers will get exact arrival time of their driver
                </p>
              </div>
            </div>
            <div className="d-flex mb-4 mt-4 mb-4">
              <span className="bg-white feature-block rounded-circle p-3 d-flex justify-content-center align-items-center me-3">
                <img src="image/icon/item1.svg" className="flip-img" alt="" />
              </span>
              <div>
                <h4 className="font-22 fw-600 dark-black mb-3">Chat</h4>
                <p className="gray-color">
                  Customers can register with email, mobile number or use social
                  logins
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const faqs = (
    <div className="faq py-5 p-100" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-12 mx-auto text-center mb-5">
            <h3 className="font-32 dark-black fw-bold mb-4">
              Frequently Asked Questions
            </h3>
            <p className="font-18 mb-0">
              Still in a quandary with lot more questions in mind. Unveil here
              the best resolutions:
            </p>
          </div>
        </div>
        <div
          className="accordion accordion-flush"
          id="accordionFlushExample"
          data-aos="fade-up"
        >
          {faqsData.map((item, index) => {
            return (
              <Accordion key={index} className="mb-4">
                <Accordion.Item eventKey={index}>
                  <Accordion.Header
                    className="bg-transparent box-shadow-none"
                    color="#FFFFFF"
                  >
                    {item.title}
                  </Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              // {/* <div className="accordion-item mb-4" key={index}>
              //   <h2 className="accordion-header" id="flush-headingOne">
              //     <button
              //       className="accordion-button collapsed bg-transparent box-shadow-none"
              //       type="button"
              //       data-bs-toggle="collapse"
              //       data-bs-target="#flush-collapseOne"
              //       aria-expanded="false"
              //       aria-controls="flush-collapseOne"
              //     >
              //       <h5 className="fw-500 text-black">{item.title}</h5>
              //     </button>
              //   </h2>
              //   <div
              //     id="flush-collapseOne"
              //     className="accordion-collapse collapse"
              //     aria-labelledby="flush-headingOne"
              //     data-bs-parent="#accordionFlushExample"
              //   >
              //     <div className="accordion-body" dangerouslySetInnerHTML={{ __html: item.description }}></div>
              //   </div>
              // </div> */}
            );
          })}
        </div>
      </div>
    </div>
  );

  const footer = (
    <footer className="py-3 p-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 d-flex justify-content-center justify-content-lg-start my-3 col-lg-3">
            <a href="/">
              <img src="image/logo.svg" alt="" style={{ width: "90px" }} />
            </a>
          </div>
          <div className="col-xl-4 d-flex justify-content-center justify-content-lg-start my-3 col-lg-6 mx-auto d-flex align-items-center footer-center flex-wrap">
            <a
              href="/privacy-policy"
              className="mx-1 text-decoration-none fw-500 dark-black"
            >
              Privacy Policy
            </a>
            <a
              href="/terms-condition"
              className="mx-1 text-decoration-none fw-500 dark-black"
            >
              | Terms & Conditions |
            </a>
            <a
              href="/disclaimer"
              className="mx-1 text-decoration-none fw-500 dark-black"
            >
              Disclaimer
            </a>
          </div>
          <div className="col-xl-4 d-flex justify-content-center justify-content-lg-end my-3 col-lg-3 align-items-center d-flex social-footer">
            {footerDetails.instagram_link ? (
              <a
                href={footerDetails.instagram_link}
                className="d-flex p-3 bg-white custom-border mb-0 rounded-circle text-decoration-none align-items-center justify-content-center ms-2"
              >
                <i className="mdi mdi-instagram text-black font-28"></i>
              </a>
            ) : null}
            {footerDetails.facebook_link ? (
              <a
                href={footerDetails.facebook_link}
                className="d-flex p-3 bg-white custom-border mb-0 rounded-circle text-decoration-none align-items-center justify-content-center ms-2"
              >
                <i className="mdi mdi-facebook text-black font-28"></i>
              </a>
            ) : null}
            {footerDetails.twitter_link ? (
              <a
                href={footerDetails.twitter_link}
                className="d-flex p-3 bg-white custom-border mb-0 rounded-circle text-decoration-none align-items-center justify-content-center ms-2"
              >
                <i className="mdi mdi-twitter text-black font-28"></i>
              </a>
            ) : null}
            {footerDetails.linkedin_link ? (
              <a
                href={footerDetails.linkedin_link}
                className="d-flex p-3 bg-white custom-border mb-0 rounded-circle text-decoration-none align-items-center ms-2 justify-content-center"
              >
                <i className="mdi mdi-linkedin text-black font-28"></i>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </footer>
  );

  return (
    <>
      <CustomMetaTags title="Home" />
      {navbar}
      <div className="banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-6 col-12 d-flex align-items-center">
              <div>
                <h1 className="fw-bold mb-0 dark-black">
                  Solutions For All <br />
                  Your Shipping Needs.
                </h1>
                <p className="mt-4 mb-0 py-2 gray-color font-18">
                  In order to accommodate the various demands of both
                  enterprises and people, our freight marketplace provides a
                  wide choice of shipping and transit solutions.
                </p>
                <div className="social-img" data-aos="fade-right">
                  <a href={footerDetails.play_store_link} className="me-3">
                    <img className="mt-4" src="image/google.png" alt="" />
                  </a>
                  <a href={footerDetails.app_store_link}>
                    <img className="mt-4" src="image/apple.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-xl-6 col-12" data-aos="fade-left">
              <div className="banner-right">
                <img src="image/banner.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {aboutUs}
      {howItWorks}
      {services}
      {features}
      {faqs}
      <div className="download-btn">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 mx-auto text-center mb-5">
              <h3 className="font-32 dark-black fw-bold mb-4">Download App</h3>
              <p className="font-18 mb-0">
                We are interested in a long term partnership. When you hire
                Xturra, you get a reliable partner for all your logistic needs.
              </p>
            </div>
            <div
              className="d-flex justify-content-center align-item-center flex-wrap"
              data-aos="zoom-in"
            >
              <a href={footerDetails.app_store_link} className="mx-2">
                <img src="image/apple.png" alt="" />
              </a>
              <a href={footerDetails.play_store_link} className="mx-2">
                <img src="image/google.png" alt="" />
              </a>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
        <div className="first"></div>
      </div>
      <ContactUs footerDetails={footerDetails} />
      {footer}
    </>
  );
};

export default Home;
