import React, { useState, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Spinner } from "reactstrap";
import { CustomAlertContext } from "contexts";
import { put, errorHandler } from "helpers/api_helper";
import { getPaymentStatusChip } from "@pages/Quotations/QuotationsList";

const Refund = ({ id, color = "info", order }) => {
  const customAlert = useContext(CustomAlertContext);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(false);

  const handleSendMessage = () => {
    let data = new FormData();
    data.append("amount", amount);
    setShowLoading(true);

    put(`/orders/${id}/refund_order_amount`, data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          location.reload();
        }, [1000]);
      })
      .catch((err) => {
        let msg = errorHandler(err);
        customAlert.error(msg);
        setShowLoading(false);
      });
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const messageModal = showModal ? (
    <Modal isOpen={showModal} handleModal={handleModal}>
      <ModalHeader handleModal={handleModal}>Refund Payment</ModalHeader>
      <ModalBody>
        <label>Refund Amount *</label>{" "}
        <small style={{ color: "#5B626B" }}>Maximum refundable amount is {order.payment.final_amount}/-.</small>
        <Input
          type="number"
          onChange={(e) => {
            if (parseFloat(e.target.value) > parseFloat(order.payment.final_amount)) {
              setError(true);
            } else {
              setError(false);
            }
            setAmount(e.target.value);
          }}
          value={amount}
          placeholder="Enter amount"
          max={order.payment.final_amount}
        />
        {error ? (
          <span className="error">{`Amount can't be greater than the total Refundable Amount (${order.payment.final_amount}).`}</span>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSendMessage} disabled={showLoading || error}>
          {showLoading ? <Spinner size="sm" /> : "Issue Refund"}
        </Button>
        <Button onClick={handleModal} disabled={showLoading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;

  if (order.status !== "delivered") {
    return "Not Applicable.";
  }

  if (order.payment.refund_amount) {
    return getPaymentStatusChip(
      order.payment.refund_amount == order.payment.final_amount ? "accepted" : "sent",
      order.payment.refund_amount == order.payment.final_amount ? "Fully Refunded" : "Partial Refunded"
    );
  }

  return (
    <>
      {messageModal}
      <Button color={color} onClick={handleModal}>
        Issue Refund
      </Button>
    </>
  );
};

export default Refund;
