import { Card, CardBody, CardTitle, CardSubtitle, InputGroup, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorHandler, post } from "helpers/api_helper";
import { CustomAlertContext } from "@contexts/CustomAlertContext";
import { useContext } from "react";

const FormSchema = Yup.object().shape({
  top_up: Yup.number().label("Top up").min(1).required(),
});

const Wallet = ({ user }) => {
  const customAlert = useContext(CustomAlertContext);
  const formik = useFormik({
    initialValues: {
      top_up: 0,
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    let data = new FormData();
    data.append("amount", values.top_up);
    let loc = window.location.origin;
    data.append("success_url", loc + "/dashboard?payment=success");
    data.append("cancel_url", loc + "/dashboard?payment=failed");

    post(`/checkout/session`, data)
      .then((res) => {
        window.location.href = res.result;
      })
      .catch((error) => {
        let msg = errorHandler(error);
        customAlert.setToastNotification(msg, "error");
      });
  };

  return (
    <Card
      outline
      style={{
        width: "20rem",
        height: "160px",
        backgroundImage: " linear-gradient(233deg, #E8E8E8 0%, #F5F5F5 100%)",
      }}
    >
      <CardBody style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <div>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Wallet Balance <i className="mdi  mdi-wallet-outline " style={{ paddingLeft: "169px" }}></i>
          </CardSubtitle>
          <CardTitle tag="h5">{user.wallet.currency + " " + user.wallet.value}</CardTitle>
        </div>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <InputGroup>
              <input
                name={"top_up"}
                type={"number"}
                onChange={(e) => formik.setFieldValue("top_up", e.target.value)}
                value={formik.values.top_up}
                className="form-control"
                min={0}
              />
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
                type="submit"
              >
                Top Up <i className="mdi mdi-location-enter" style={{ fontSize: "16px" }} />
              </Button>
            </InputGroup>
          </form>
          <span className="error">{formik?.errors?.top_up}</span>
        </div>
      </CardBody>
    </Card>
  );
};
export default Wallet;
