import React, { useContext, useEffect, useState } from "react";
import { errorHandler, post, put, get } from "helpers/api_helper";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import StarRatings from "react-star-ratings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import RichTextEditor from "@components/Common/Form/RichTextEditor";
import { CustomInput } from "@components/Common/Form";
import CustomSwitch from "@components/Common/Form/CustomSwitch";
import CustomImagePreview from "@components/Common/CustomImagePreview";
import CustomDropzone from "@components/Common/CustomDropzone";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import VendorAddressForm from "./VendorAddressForm";
import CustomMobileInput from "@components/Common/CustomMobileInput";
import Select from "react-select";

const VendorBusinessDetailForm = (props) => {
  const { vendorId, data = {}, isAdmin, tabBar, isViewEdit } = props;
  const [item_id, setItem_id] = useState(props.item_id);
  const customAlert = useContext(CustomAlertContext);
  const [disabled, setDisabled] = useState(props.disabled || false);
  const [type, setType] = useState("submit");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);

  useEffect(() => {
    get(`/categories`).then((res) => {
      const { result } = res;
      let categoryOptions = { options: [{ label: "All categories", value: "", isDisabled: true }] };
      result.data.map((data) => {
        categoryOptions.options.push({ label: data.label, value: data._id });
      });
      setCategoryList(categoryOptions);
      if (data?.category_ids?.length) {
        handleSelectedCategoryList(data.category_ids, categoryOptions);
      }
    });
  }, []);

  const handleSelectedCategoryList = (idsArray, options) => {
    let data = [];

    options.options.map((option) => {
      idsArray.map((id) => {
        if (option.value == id) {
          data.push({ label: option.label, value: option.value });
        }
      });
    });

    setSelectedCategoryList(data);
  };

  const FormSchema = Yup.object().shape({
    business_name: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .label("Business name")
      .max(50)
      .required(),
    description: Yup.string().strict().label("Business description").required().max(2500),
    business_link: Yup.string()
      .matches(
        new RegExp(
          /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
          "i"
        ),
        "Please enter valid business website/social link"
      )
      .label("Business website/social link"),
    status: Yup.boolean().required(yupMessages.required),
    is_verified: Yup.boolean().required(yupMessages.required),
    tax_rate: Yup.number().min(0).label("Local government tax rate").required().max(40),
    password: !item_id ? Yup.string().strict().trim().required(yupMessages.required).max(20) : null,
    logo: Yup.mixed()
      .required("Please upload business logo.")
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
      ),
    business_reg_certificate: Yup.mixed()
      .required("Please upload business registration certificate.")
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
      ),
    cover_image: Yup.mixed()
      .required("Please upload profile cover image.")
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
      ),
    contact: Yup.object().shape({
      email: Yup.string().email().required(yupMessages.required).max(40),
      mobile: Yup.number().required(yupMessages.required),
      country_iso: Yup.number().required(yupMessages.required),
      first_name: !isViewEdit
        ? Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid name")
            .max(40)
            .required(yupMessages.required)
        : null,
      last_name: !isViewEdit
        ? Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid name")
            .max(40)
            .required(yupMessages.required)
        : null,
      // user_name: Yup.string().required(yupMessages.required).max(20),
    }),
    phone: Yup.number().required(yupMessages.required).min(1),
    address: Yup.object().shape({
      line_1: Yup.string().strict().trim().label("Line 1").required().max(200),
      state: Yup.string().strict().trim().label().required("Please search address"),
    }),
  });

  const handleSubmit = (values) => {
    let request = item_id ? (data) => put(`vendors/${item_id}`, data) : (data) => post(`vendors`, data);
    let data = new FormData();
    data.append("business_link", values.business_link.trim());
    data.append("business_name", values.business_name.trim());
    data.append("tax_rate", values.tax_rate);
    data.append("description", values.description.trim());
    data.append("status", values.status);
    data.append("is_verified", values.is_verified);
    data.append("phone", values.phone);
    {
      item_id ? null : data.append("password", values.password);
    }
    data.append("contact", JSON.stringify(values.contact));
    data.append("address", JSON.stringify(values.address));

    if (values.logo && typeof values.logo != "string") {
      data.append("logo", values.logo);
    }
    if (values.business_reg_certificate && typeof values.business_reg_certificate != "string") {
      data.append("business_reg_certificate", values.business_reg_certificate);
    }
    if (values.cover_image && typeof values.cover_image != "string") {
      data.append("cover_image", values.cover_image);
    }
    data.append("category_ids", JSON.stringify(values.category_ids));

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          if (isAdmin) {
            window.location.href = `/vendors`;
          } else {
            window.location.href = `/vendors/view/${vendorId}`;
          }
        }, 2000);
      })
      .catch((err) => {
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      business_link: data.business_link || "",
      business_name: data.business_name || "",
      tax_rate: data.tax_rate || 0,
      description: data.description || "",
      status: typeof data.status === "boolean" ? data.status : true,
      is_verified: typeof data.is_verified === "boolean" ? data.is_verified : true,
      logo: data.logo_url || "",
      password: data.password || "",
      business_reg_certificate: data.business_reg_certificate_url || "",
      cover_image: data.cover_image_url || "",
      phone: data.phone || "",
      category_ids: data.category_ids || [],
      contact: isViewEdit
        ? data.contact || {
            // user_name: "",
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
            country_iso: "",
          }
        : data.contact || {
            email: "",
            mobile: "",
            country_iso: "",
          },
      address: {
        line_1: data?.address?.line_1 || "",
        line_2: data?.address?.line_2 || "",
        city: data?.address?.city || "",
        state: data?.address?.state || "",
        country: data?.address?.country || "",
        zip_code: data?.address?.zip_code || "",
        tag: data?.address?.tag || "office",
        type: data?.address?.type || "business",
        location: {
          type: "Point",
          coordinates: [data?.address?.location?.coordinates[0], data?.address?.location?.coordinates[1]],
        },
      },
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const reviews_count = (
    <Col className="col-6 my-1">
      <label className="mr-2">Reviews Count :</label> {data.reviews_count || "Not Available"}
    </Col>
  );

  const average_rating = (
    <Col className="col-6 my-1">
      <label className="mr-2">Average Rating</label>
      <StarRatings
        rating={data.average_rating || 0}
        starRatedColor="#fdab00"
        numberOfStars={5}
        name="rating"
        starDimension="16px"
        starSpacing="3px"
      />
    </Col>
  );

  const business_name = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="business_name"
        label={"Business Name"}
        placeholder="Enter business name"
        disabled={disabled}
      />
    </Col>
  );

  const business_link = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="business_link"
        label={"Business Link"}
        placeholder="Enter business link"
        disabled={disabled}
      />
    </Col>
  );

  const tax_rate = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="tax_rate"
        label={"Tax Rate"}
        placeholder="Enter tax rate "
        disabled={disabled}
        type="number"
      />
    </Col>
  );

  const description = (
    <Col className="col-12">
      <RichTextEditor
        formik={formik}
        name={"description"}
        placeholder={"Write about your business"}
        label={"About your business*"}
        disabled={disabled}
        className="my-1"
      />
    </Col>
  );

  const logo = (
    <Col className="col-4">
      <label className="mr-2">Logo </label>
      <Row>
        <Col className="col-12">
          <CustomImagePreview height={180} width={180} file={formik.values.logo} />
        </Col>
        {formik.errors && formik.errors.logo ? (
          <Col className="col-12">
            <span className="error">{formik.errors.logo}</span>
          </Col>
        ) : null}
        {disabled ? null : (
          <Col className="col-12 mt-2">
            <CustomDropzone
              onChange={(acceptedFiles) => formik.setFieldValue("logo", acceptedFiles[0])}
              allowedTypes="image/*"
              multiple={false}
            />
          </Col>
        )}
      </Row>
    </Col>
  );

  const business_reg_certificate = (
    <Col className="col-4">
      <label className="mr-2">Business Registration Certificate </label>
      <Row>
        <Col className="col-12">
          <CustomImagePreview height={180} file={formik.values.business_reg_certificate} />
        </Col>
        {formik.errors && formik.errors.business_reg_certificate ? (
          <Col className="col-12">
            <span className="error">{formik.errors.business_reg_certificate}</span>
          </Col>
        ) : null}
        {disabled ? null : (
          <Col className="col-12 mt-2">
            <CustomDropzone
              onChange={(acceptedFiles) => formik.setFieldValue("business_reg_certificate", acceptedFiles[0])}
              allowedTypes="image/*"
              multiple={false}
            />
          </Col>
        )}
      </Row>
    </Col>
  );
  const cover_image = (
    <Col className="col-4">
      <label className="mr-2">Cover Image</label>
      <Row>
        <Col className="col-12">
          <CustomImagePreview height={180} width={180} file={formik.values.cover_image} />
        </Col>
        {formik.errors && formik.errors.cover_image ? (
          <Col className="col-12">
            <span className="error">{formik.errors.cover_image}</span>
          </Col>
        ) : null}
        {disabled ? null : (
          <Col className="col-12 mt-2">
            <CustomDropzone
              onChange={(acceptedFiles) => formik.setFieldValue("cover_image", acceptedFiles[0])}
              allowedTypes="image/*"
              multiple={false}
            />
          </Col>
        )}
      </Row>
    </Col>
  );

  const contact = (
    <Col className="col-12">
      <Row>
        {!isViewEdit ? (
          <>
            <Col className="col-6">
              <CustomInput
                formik={formik}
                name="contact"
                child_name="first_name"
                label={"First Name"}
                placeholder="Enter first name"
                disabled={disabled}
              />
            </Col>
            <Col className="col-6">
              <CustomInput
                formik={formik}
                name="contact"
                child_name="last_name"
                label={"Last Name"}
                placeholder="Enter last name"
                disabled={disabled}
              />
            </Col>
          </>
        ) : null}
        {!tabBar ? (
          <>
            {/* <Col className="col-6">
              <CustomInput
                formik={formik}
                name="contact"
                child_name="user_name"
                label={"Username"}
                placeholder="Enter user name"
                disabled={disabled}
              />
            </Col> */}
            <Col className="col-6">
              <CustomInput
                formik={formik}
                name="password"
                label={"Password"}
                placeholder="Enter Password"
                disabled={disabled}
              />
            </Col>
          </>
        ) : null}
        <CustomMobileInput
          label="Mobile"
          country_iso={formik.values.contact.country_iso || "61"}
          number={formik.values.contact.mobile}
          handleChange={(phone, country) => {
            if (country.dialCode !== formik.values.contact.country_iso) {
              formik.setFieldValue("contact.country_iso", country.dialCode);
              formik.setFieldValue("contact.mobile", "");
            } else {
              formik.setFieldValue("contact.mobile", phone.slice(country.dialCode.length));
            }
          }}
          disabled={disabled}
          errorMsg={
            formik.errors["contact"] && formik.errors["contact"]["mobile"] ? formik.errors["contact"]["mobile"] : ""
          }
          className="col-6"
        />

        <Col className="col-6">
          <CustomInput
            formik={formik}
            name="contact"
            child_name="email"
            label={"Business Email"}
            placeholder="Enter business email"
            disabled={disabled}
          />
        </Col>
        <Col className="col-6">
          <CustomInput
            formik={formik}
            name="phone"
            label={"Business Landline No."}
            placeholder="Enter business landline number"
            disabled={disabled}
            type="number"
          />
        </Col>
      </Row>
    </Col>
  );
  const address = <VendorAddressForm formik={formik} disabled={disabled} item_id={item_id} />;

  const categoriesSelection = (
    <Col className="col-6">
      <Label>Categories</Label>
      <Select
        value={selectedCategoryList}
        onChange={(event) => {
          let values = [];
          if (event && event.map) event.map((item) => values.push(item.value));
          formik.setFieldValue("category_ids", values);
          handleSelectedCategoryList(values, categoryList);
        }}
        options={[categoryList]}
        isMulti
        isDisabled={disabled}
      />
      <span className="error">{formik.errors["category_ids"]}</span>
    </Col>
  );

  const status = isAdmin ? (
    <>
      <Col className="col-6">
        <CustomSwitch name={"is_verified"} formik={formik} disabled={disabled} label="Is verified" className="my-1" />
      </Col>
      <Col className="col-6">
        <CustomSwitch name={"status"} formik={formik} disabled={disabled} label="Status" className="my-1" />
      </Col>
    </>
  ) : null;

  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" style={{ float: "right" }}>
        Submit
      </Button>
    </Col>
  );

  const changeType = () => {
    if (type === "submit") {
      setType("button");
      setItem_id(vendorId);
    } else {
      setType("submit");
      setDisabled(true);
    }
  };
  return (
    <>
      {!tabBar ? (
        <div className="page-content">
          <CustomMetaTags title={"Add" + " Vendor"} />
          <div className="container-fluid">
            <Breadcrumb breadcrumb1={{ title: "Vendors", href: "/vendors" }} breadcrumbItem={"Add" + " Vendor"} />
            <Card>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Row>
                    {business_name}
                    {business_link}
                    {tax_rate}
                    {categoriesSelection}
                    {/* {password} */}
                    {description}
                    {contact}
                    {status}
                    <hr
                      style={{
                        marginTop: "10px",
                      }}
                    />
                    {logo}
                    {business_reg_certificate}
                    {cover_image}
                    {address}
                    {submit_btn}
                  </Row>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      ) : (
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                {isAdmin ? null : disabled ? (
                  <span title="View" className="mx-2 ">
                    <Button
                      style={{ float: "right" }}
                      color="primary"
                      type={type}
                      onClick={() => {
                        setDisabled(false);
                        changeType();
                      }}
                    >
                      Edit <i className="ti-pencil ml-2" />
                    </Button>
                  </span>
                ) : null}

                {!item_id ? reviews_count : null}
                {!item_id ? average_rating : null}
                {business_name}
                {business_link}
                {tax_rate}
                {categoriesSelection}
                {description}
                {contact}
                {/* {password} */}
                {status}
                <hr
                  style={{
                    marginTop: "10px",
                  }}
                />
                {logo}
                {business_reg_certificate}
                {cover_image}
                {address}
                {submit_btn}
              </Row>
            </form>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default VendorBusinessDetailForm;
