import React, { useState, useEffect, useContext } from "react";
import { errorHandler, get, post, put } from "helpers/api_helper";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext, UserContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import CustomImagePreview from "@components/Common/CustomImagePreview";
import CustomDropzone from "@components/Common/CustomDropzone";
import CustomSwitch from "@components/Common/Form/CustomSwitch";
import Select from "react-select";
import CustomMobileInput from "@components/Common/CustomMobileInput";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";

const FormSchema = Yup.object().shape({
  rider_vendor_id: Yup.string().required("Please select a vendor").max(200),
  first_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required("Please enter first name")
    .label("First name"),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required("Please enter last name")
    .label("Last name"),
  email: Yup.string().email().required("Please enter email").max(50).label("Email"),
  status: Yup.boolean().required(yupMessages.required),
  is_verified: Yup.boolean().required(yupMessages.required),
  is_priority_partner: Yup.boolean().required(yupMessages.required),
  mobile: Yup.string().required("Please enter mobile number").label("Mobile"),
  country_iso: Yup.number().required(yupMessages.required),
  profile_image: Yup.mixed()
    .required("Please upload a profile picture")
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
    )
    .label("Profile image"),
});

const RiderForm = (props) => {
  const { disabled = false, item_id, isId, data = {}, tabBar } = props;
  const customAlert = useContext(CustomAlertContext);
  const { user, isAdmin } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  // const [disabled, setDisabled] = useState(props.disabled || false);
  const [vendorsList, setVendorsList] = useState([]);
  const [vendor, setVendor] = useState({ label: "Select vendor", value: "" });

  useEffect(() => {
    if (data?.rider_vendor?.business_name && data?.rider_vendor?.business_name) {
      setVendor({ label: data?.rider_vendor?.business_name, value: data?.rider_vendor?.id });
    }
  }, []);

  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/riders/${item_id}`, data) : (data) => post(`/riders`, data);

    let data = new FormData();
    data.append("first_name", values.first_name.trim());
    data.append("last_name", values.last_name.trim());
    data.append("email", values.email.trim());
    data.append("rider_vendor_id", values.rider_vendor_id);
    data.append("status", values.status);
    data.append("is_verified", values.is_verified);
    data.append("is_priority_partner", values.is_priority_partner);
    data.append("mobile", values.mobile);
    data.append("country_iso", values.country_iso);

    if (values.profile_image && typeof values.profile_image != "string") {
      data.append("profile_image", values.profile_image);
    }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = "/riders";
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      rider_vendor_id: data.rider_vendor_id || "",
      mobile: data.mobile || "",
      status: typeof data.status === "boolean" ? data.status : false,
      is_verified: typeof data.is_verified === "boolean" ? data.is_verified : false,
      is_priority_partner: typeof data.is_priority_partner === "boolean" ? data.is_priority_partner : false,
      country_iso: `${data.country_iso || ""}`,
      description: data.description || "",
      profile_image: data.profile_image_url || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const first_name = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="first_name"
        label={"First Name"}
        placeholder="Enter First Name"
        disabled={disabled}
      />
    </Col>
  );

  const last_name = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="last_name"
        label={"Last Name"}
        placeholder="Enter Last Name"
        disabled={disabled}
      />
    </Col>
  );

  const status = isId ? (
    <Row style={{ marginTop: "4px" }}>
      <Col className="col-4">
        <CustomSwitch name={"is_verified"} formik={formik} disabled={disabled} label="Is verified" className="my-1" />
      </Col>
      <Col className="col-4" style={{ textAlign: "center" }}>
        <CustomSwitch name={"status"} formik={formik} disabled={disabled} label="Activate" className="my-1" />
      </Col>
      <Col className="col-4" style={{ textAlign: "right" }}>
        <CustomSwitch
          name={"is_priority_partner"}
          formik={formik}
          disabled={disabled}
          label="Is Priority Partner"
          className="my-1"
        />
      </Col>
    </Row>
  ) : null;

  const email = (
    <Col className="col-6">
      <CustomInput formik={formik} name="email" label={"Email"} placeholder="Enter Email" disabled={disabled} />
    </Col>
  );

  const mobileNumber = (
    <CustomMobileInput
      label="Mobile"
      country_iso={formik.values.country_iso || 61}
      number={formik.values.mobile}
      handleChange={(phone, country) => {
        if (country.dialCode !== formik.values.country_iso) {
          formik.setFieldValue("country_iso", country.dialCode);
          formik.setFieldValue("mobile", "");
        } else {
          formik.setFieldValue("mobile", phone.slice(country.dialCode.length));
        }
      }}
      disabled={disabled}
      errorMsg={formik.errors["mobile"]}
      className="col-6"
    />
  );

  useEffect(() => {
    get(`/vendors?page_size=10000&fields=business_name,id,is_verified,status,user_id`).then((res) => {
      const { result } = res;
      let vendorOptions = { options: [{ label: "All Vendors", value: "" }] };
      let currentVendor = { label: "Select vendor", value: "" };
      result.data.map((data) => {
        vendorOptions.options.push({ label: data.business_name, value: data._id });
        if (user.vendor_id == data._id) {
          currentVendor = { label: data.business_name, value: data._id };
          formik.setFieldValue("rider_vendor_id", currentVendor.value);
        }
      });
      if (currentVendor.value) {
        setVendor(currentVendor);
      }
      setVendorsList(vendorOptions);
    });
  }, []);

  const dropdown = (
    <Col className="col-6">
      <Label>Vendor</Label>
      <Select
        value={vendor}
        onChange={(event) => {
          formik.setFieldValue("rider_vendor_id", event.value);
          setVendor(event);
        }}
        options={[vendorsList]}
        isDisabled={isAdmin() ? disabled : true}
      />
      <span className="error">{formik.errors["rider_vendor_id"]}</span>
    </Col>
  );

  const profile_image = (
    <Col className="col-12">
      <label className="mr-2">Profile Image</label>
      <Row>
        <Col className="col-12">
          <CustomImagePreview file={formik.values.profile_image} />
        </Col>
        {formik.errors && formik.errors.profile_image ? (
          <Col className="col-12">
            <span className="error">{formik.errors.profile_image}</span>
          </Col>
        ) : null}
        {disabled ? null : (
          <Col className="col-12 mt-2">
            <CustomDropzone
              onChange={(acceptedFiles) => formik.setFieldValue("profile_image", acceptedFiles[0])}
              allowedTypes="image/*"
              multiple={false}
            />
          </Col>
        )}
      </Row>
    </Col>
  );

  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  return (
    <>
      {tabBar ? (
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                {dropdown}
                {email}
                {first_name}
                {last_name}
                {mobileNumber}
                {status}
                {profile_image}
                {submit_btn}
              </Row>
            </form>
          </CardBody>
        </Card>
      ) : (
        <div className="page-content">
          <CustomMetaTags title={"Add" + " Rider"} />
          <div className="container-fluid">
            <Breadcrumb breadcrumb1={{ title: "Riders", href: "/riders" }} breadcrumbItem={"Add" + " Rider"} />
            <Card>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Row>
                    {dropdown}
                    {email}
                    {first_name}
                    {last_name}
                    {mobileNumber}
                    {profile_image}
                    {submit_btn}
                  </Row>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default RiderForm;
