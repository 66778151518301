import React from "react";
import CustomTable from "@components/Common/Table";
import { Card, CardBody } from "reactstrap";
import { UserContext } from "contexts";
import { getPaymentStatusChip } from "@pages/Quotations/QuotationsList";
import { getFormattedDate } from "@utils/index";
import Wallet from "@pages/Wallet";

const columns = [
  {
    dataField: "txn_id",
    text: "Transaction No.",
  },
  {
    dataField: "user",
    text: "Name",
    formatter: (_cell) => (
      <p>
        {_cell?.first_name} {_cell?.last_name}
      </p>
    ),
  },
  {
    dataField: "created_at",
    text: "Created at",
    formatter: (_cell) => getFormattedDate(_cell, true),
  },
  {
    dataField: "title",
    text: "Title",
    formatter: (_cell, _row) =>
      _row?.order?.order_ref_no ? _cell + " (Order No. " + _row?.order?.order_ref_no + ")" : _cell,
  },
  {
    dataField: "type",
    text: "Type",
    formatter: (_cell) => {
      if (_cell === "credit") {
        return getPaymentStatusChip("accepted", _cell);
      } else {
        return getPaymentStatusChip("declined", _cell);
      }
    },
  },
  {
    dataField: "source",
    text: "Source",
  },
  {
    dataField: "currency",
    text: "Amount",
    formatter: (_row, _cell) => {
      return (
        <p>
          {_cell.currency} &nbsp;
          {_cell.value.$numberDecimal}/-
        </p>
      );
    },
  },
];

const TransactionsList = () => {
  let { user, isAdmin } = React.useContext(UserContext);

  return (
    <Card>
      <CardBody>
        {isAdmin() ? null : <Wallet user={user} />}
        <CustomTable
          columns={columns}
          section="transactions"
          fetchUrl={"/transactions" + (isAdmin() ? "" : `?user_id=${user.id}`)}
          vendorId={isAdmin() ? "" : user.id}
          showUserSelection={isAdmin()}
          showTransactionsTypeSelection={isAdmin()}
        />
      </CardBody>
    </Card>
  );
};

export default TransactionsList;
