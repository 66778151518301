import { APP_NAME } from "utils";
import axios from "axios";

//apply base url for axios
const API_URL = "https://xturra.com:5000/api/v1";
export const API_PDF_URL = "https://xturra.com:5002/xturra";
export const API_BASE_URL = "https://xturra.com:5000";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Content-Type"] = "application/json";

const authHeader = () => {
  return {
    headers: {
      authorization: "Bearer " + localStorage.getItem(APP_NAME.toLowerCase() + "_token"),
    },
  };
};

export function get(url, config = {}) {
  return axiosApi
    .get(url, {
      ...config,
      ...authHeader(),
    })
    .then((response) => response.data);
}

export function getNew(url, config = {}) {
  return axiosApi
    .get(url, {
      ...config,
      ...authHeader(),
    })
    .then((response) => response.data)
    .then((response) => response.result || response);
}

export function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, {
      ...config,
      ...authHeader(),
    })
    .then((response) => response.data);
}

export function put(url, data, config = {}) {
  return axiosApi.put(url, data, { ...config, ...authHeader() }).then((response) => response.data);
}

export function del(url, config = {}) {
  return axiosApi.delete(url, { ...config, ...authHeader() }).then((response) => response.data);
}

export const errorHandler = (error) => {
  let msg = "Something went wrong.";
  if (error.response) {
    // client received an error response (5xx, 4xx)
    if (error.response.data && error.response.data.message) {
      msg = error.response.data.message;

      if (error.response.data.errors) {
        Object.values(error.response.data.errors).map((value) => {
          msg += " *" + value;
        });
      }
    }
  } else if (error.request) {
    // client never received a response, or request never left
    msg = "Server is not responding.";
  }

  return msg;
};
