import { get } from "helpers/api_helper";
import React, { useState } from "react";
import { useEffect } from "react";

const Disclaimer = () => {
  const [disclaimer, setDisclaimer] = useState("");

  useEffect(() => {
    import("../../assets/scss/bootstrap.min.css").then(() => {
      import("../../assets/scss/style.scss").then(() => {
        get("/info-pages/slug/disclaimer")
          .then((res) => {
            setDisclaimer(res.result);
          })
          .catch(() => {
            //
          });
      });
    });
  }, []);

  return (
    <React.Fragment>
      <header className="fixed-top">
        <nav className="navbar navbar-light navbar-expand-lg p-3">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src="image/logo.svg" alt="" style={{ width: "111px" }} />
            </a>

            <div style={{ width: "inherit", textAlign: "center" }}>
              <h3>Disclaimer</h3>
            </div>
          </div>
        </nav>
      </header>
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "9rem",
        }}
      >
        <p dangerouslySetInnerHTML={{ __html: disclaimer.content }}></p>
      </div>
    </React.Fragment>
  );
};

export default Disclaimer;
