import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import RichTextEditor from "@components/Common/Form/RichTextEditor";
import { CustomInput } from "@components/Common/Form";

const FormSchema = Yup.object().shape({
  name: Yup.string().strict().required(yupMessages.required),
  content: Yup.string().strict().required(yupMessages.required),
});

const Form = (props) => {
  const { disabled = false, item_id, data = {} } = props;
  const [loader, setLoader] = useState(false);
  const customAlert = useContext(CustomAlertContext);

  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/info-pages/${item_id}`, data) : (data) => post(`/info-pages`, data);

    let data = new FormData();
    data.append("content", values.content.trim());
    data.append("name", values.name.trim());

    request(values)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = "/info-pages";
        }, 2000);
      })
      .catch((err) => {
        customAlert.setToastNotification(errorHandler(err), "error");
        setLoader(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      status: typeof data.status === "boolean" ? data.status : true,
      content: data.content || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <CustomInput formik={formik} name="name" label={"Name"} placeholder="Enter info page name" disabled={true} />
          <div className="mb-3">
            <RichTextEditor
              formik={formik}
              name={"content"}
              placeholder={"Enter Content"}
              label={"Content"}
              disabled={disabled}
            />
          </div>
          {disabled ? null : (
            <div className="d-flex flex-wrap gap-2">
              <Button type="submit" color="primary" disabled={loader}>
                Submit
              </Button>
            </div>
          )}
        </form>
      </CardBody>
    </Card>
  );
};

const InfoPageForm = ({ disabled = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/info-pages/${props.match.params.id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Info Page"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Info Pages", href: "/info-pages" }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Info Page"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form data={data} item_id={editInfo ? props.match.params.id : undefined} disabled={disabled} />
        )}
      </div>
    </div>
  );
};

export default InfoPageForm;
