import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Profile from "./auth/profile/reducer";

//Calendar
import calendar from "./calendar/reducer";

const rootReducer = combineReducers({
  Layout,
  Profile,
  calendar,
});

export default rootReducer;
