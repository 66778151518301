import { Container, Row } from "reactstrap";
import { APP_NAME } from "utils";

const Footer = () => (
  <footer className="footer">
    <Container fluid={true}>
      <Row>
        <div className="col-12">
          © {new Date().getFullYear()} {APP_NAME}
        </div>
      </Row>
    </Container>
  </footer>
);

export default Footer;
