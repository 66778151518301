import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const SidebarComponentList = ({ list }) => {
  return (
    <>
      {list.map((item, i) => (
        <li key={i}>
          <Link to={item.to} className="waves-effect">
            {item.icon}
            <span>{item.label}</span>
          </Link>
        </li>
      ))}
    </>
  );
};

SidebarComponentList.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarComponentList));
