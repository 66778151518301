import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import SendDirectMessage from "@components/Messenger/SendDirectMessage";
import PrimaryColumn from "@components/Common/PrimaryColumn";

const columns = [
  {
    dataField: "first_name",
    text: "First Name",
    formatter: (_cell, _row) => (
      <PrimaryColumn
        section={"consumers"}
        item_id={_row._id}
        label={_row.first_name || "Not Available" + " " + (_row.last_name || "")}
      />
    ),
  },
  {
    dataField: "business_name",
    text: "Business Name",
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "mobile",
    text: "Mobile",
  },
  {
    dataField: "message",
    text: "Message",
    formatter: (_cell, _row) => <SendDirectMessage user_id={_row.id} />,
  },
];
const title = "Customers";

const Customers = () => (
  <div className="page-content">
    <CustomMetaTags title={title} />
    <div className="container-fluid">
      <Breadcrumb breadcrumbItem={title} btnRedirect="/consumers/add" btnLabel="Add New" />
      <Card>
        <CardBody>
          <CustomTable
            columns={columns}
            actions={true}
            edit={true}
            view={true}
            remove={true}
            role="user"
            section="consumers"
            fetchUrl="/consumers"
            searchPlaceholder="Search by name or email"
          />
        </CardBody>
      </Card>
    </div>
  </div>
);

export default Customers;
