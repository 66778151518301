import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { sortMessage } from "@pages/Dashboard";

const Contacts = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "message",
      text: "Message",
      formatter: (_cell) => {
        return <p title={_cell}>{sortMessage(_cell)}</p>;
      },
    },
  ];
  const title = "Contacts";

  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={title} />
        <Card>
          <CardBody>
            <CustomTable columns={columns} section="contacts" fetchUrl="/contacts" showUserRoleSelection={true} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Contacts;
