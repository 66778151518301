import React, { useState, useContext } from "react";
import { errorHandler, post } from "helpers/api_helper";
import { Input, Label } from "reactstrap";
import { CustomAlertContext } from "@contexts/CustomAlertContext";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SendDirectNotification = ({ user_id, label }) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isModal, setIsModal] = useState(false);

  const customAlert = useContext(CustomAlertContext);

  const handleNotification = () => {
    post(`/notifications`, { user_id: user_id, title, message })
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTitle("");
        setMessage("");
        handleNotificationModal();
      })
      .catch((err) => {
        customAlert.setToastNotification(errorHandler(err), "error");
        setTitle("");
        setMessage("");
        handleNotificationModal();
      });
  };

  const handleNotificationModal = () => {
    setIsModal(!isModal);
  };
  const notificationModal = (
    <Modal isOpen={isModal} handleNotificationModal={handleNotificationModal}>
      <ModalHeader handleNotificationModal={handleNotificationModal}>Send Notification to {label}</ModalHeader>
      <ModalBody>
        <Label>Title</Label>
        <Input onChange={(e) => setTitle(e.target.value)} placeholder="Enter title" />
        <Label md="2">Description</Label>
        <Input onChange={(e) => setMessage(e.target.value)} placeholder="Type description more then 50 words" />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleNotification} disabled={!(title.length && message.length > 10)}>
          Send
        </Button>
        <Button color="primary" onClick={handleNotificationModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <>
      <Button onClick={handleNotificationModal} size="sm" color="info">
        Send Notification to {label}
      </Button>
      {notificationModal}
    </>
  );
};

export default SendDirectNotification;
