import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getFullDetails } from "@utils/index";

const UserManuals = ({ isAdmin = true }) => {
  const columns = [
    {
      dataField: "title",
      text: "Title",
      formatter: (_cell, _row) => {
        return getFullDetails("user-manuals", _row._id, _cell);
      },
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "file_url",
      text: "Uploaded File",
      formatter: (_cell) => (
        <a href={_cell} target="_blank" rel="noreferrer">
          <i className="ti-file mr-1" />
          Manual
        </a>
      ),
    },
  ];
  const VendorSideColumns = [
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "file_url",
      text: "Uploaded File",
      formatter: (_cell) => (
        <a href={_cell} target="_blank" rel="noreferrer">
          <i className="ti-file mr-1" />
          Manual
        </a>
      ),
    },
  ];

  const title = "User Manuals";

  return (
    <div className="page-content">
      <CustomMetaTags title={title} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={title} btnRedirect={isAdmin ? "/user-manuals/add" : ""} btnLabel="Add New" />
        <Card>
          <CardBody>
            <CustomTable
              columns={isAdmin ? columns : VendorSideColumns}
              actions={isAdmin ? true : false}
              edit={isAdmin ? true : false}
              view={true}
              remove={isAdmin ? true : false}
              section="user-manuals"
              fetchUrl={isAdmin ? "/user-manuals" : "/user-manuals?type=vendor"}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default UserManuals;
