import React, { useState, useEffect } from "react";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { get } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import VendorBusinessDetailForm from "@components/Vendors/VendorBusinessDetailForm";
import classnames from "classnames";
import OffersList from "../Offers/OffersList";
import Jobs from "@pages/Jobs/JobsList";
import Quotations from "@pages/Quotations/QuotationsList";
import Attachments from "@pages/Attachments/index";
import Availabilities from "@pages/Availabilities";
import StripeConnect from "@pages/StripeConnect";
import SubscribeList from "@pages/Subscribe/SubscibeList";
import Services from "@pages/Services/ServicesList";
import { useLocation, Link } from "react-router-dom";
import PersonalInformation from "@pages/PersonalInformation";

const VendorDetails = ({ disabled = false, isAdmin = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);
  const [activeTab, toggleTab] = useState("1");

  const { search } = useLocation();
  const getActiveTab = () => new URLSearchParams(search).get("tab") || "1";

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);
      get(`vendor/${props.match.params.id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    toggleTab(getActiveTab());
  }, [search]);

  const getTabHeader = (label, number) => {
    return (
      <Link to={"/vendors/view/" + data.id + "?tab=" + number}>
        {/* <Link to={"/vendors/edit/" + data.id + "?tab=" + number}> */}
        <NavItem key={number}>
          <NavLink
            className={classnames({
              active: activeTab === number,
            })}
          >
            {label}
          </NavLink>
        </NavItem>
      </Link>
    );
  };

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Vendor"} />
      <div className="container-fluid">
        {isAdmin ? (
          <Breadcrumb
            breadcrumb1={{ title: "Vendors", href: "/vendors" }}
            breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Vendor"}
          />
        ) : (
          <Breadcrumb breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Vendor"} />
        )}
        {loading ? (
          !(<SpinnerLoader />)
        ) : (
          <>
            <Nav tabs>
              {getTabHeader("Business Info.", "1")}
              {getTabHeader("Services", "2")}
              {getTabHeader("Offers", "3")}
              {getTabHeader("Jobs", "4")}
              {getTabHeader("Quotations", "5")}
              {getTabHeader("Attachments", "6")}
              {getTabHeader("Availabilities", "7")}
              {getTabHeader("Connected Account", "8")}
              {getTabHeader("Promotion Subscriptions", "9")}
              {isAdmin ? getTabHeader("Personal Info.", "10") : null}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <VendorBusinessDetailForm
                  data={data}
                  vendorId={props.match.params.id}
                  item_id={editInfo ? props.match.params.id : undefined}
                  disabled={disabled}
                  isViewEdit={props.match.params.id}
                  isAdmin={isAdmin}
                  tabBar={true}
                />
              </TabPane>
              <TabPane tabId="2">{activeTab == 2 ? <Services vendorId={data.id} /> : null}</TabPane>
              <TabPane tabId="3">{activeTab == 3 ? <OffersList isAdmin={isAdmin} vendorId={data.id} /> : null}</TabPane>
              <TabPane tabId="4">{activeTab == 4 ? <Jobs vendorId={data.id} /> : null}</TabPane>
              <TabPane tabId="5">{activeTab == 5 ? <Quotations vendorId={data.id} /> : null}</TabPane>
              <TabPane tabId="6">{activeTab == 6 ? <Attachments vendorId={data.id} /> : null}</TabPane>
              <TabPane tabId="7">{activeTab == 7 ? <Availabilities vendorId={data.id} /> : null}</TabPane>
              <TabPane tabId="8">{activeTab == 8 ? <StripeConnect vendorId={data.id} /> : null}</TabPane>
              <TabPane tabId="9">{activeTab == 9 ? <SubscribeList vendor={data} /> : null}</TabPane>
              {isAdmin ? (
                <TabPane tabId="10">
                  {activeTab == 10 ? <PersonalInformation vendorId={data.id} vendor={data} /> : null}
                </TabPane>
              ) : null}
            </TabContent>
          </>
        )}
      </div>
    </div>
  );
};

export default VendorDetails;
