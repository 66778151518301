import React from "react";

const SpinnerLoader = ({ local = false }) =>
  local ? (
    <div className="spinner-local">
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden" />
      </div>
    </div>
  ) : (
    <div className="spinner-border spinner text-warning" role="status">
      <span className="visually-hidden" />
    </div>
  );

export default SpinnerLoader;
