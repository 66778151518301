import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";

const columns = [
  {
    dataField: "order_ref_no",
    text: "Order Number",
  },
  {
    dataField: "review",
    text: "Review",
  },
  {
    dataField: "rating",
    text: "Rating",
  },
];

const Reviews = ({ riderId }) => {
  return (
    <Card>
      <CardBody>
        <CustomTable columns={columns} actions={false} section="reviews" fetchUrl={`/reviews?user_id=${riderId}`} />
      </CardBody>
    </Card>
  );
};

export default Reviews;
