import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { CustomInput } from "@components/Common/Form";
// import CustomImagePreview from "@components/Common/CustomImagePreview";
// import CustomDropzone from "@components/Common/CustomDropzone";

const FormSchema = Yup.object().shape({
  label: Yup.string()
    .strict()
    .matches(/^[A-Za-z ]*$/, "Please enter valid Title")
    .label("Title")
    .max(50),
});

const Form = (props) => {
  const { disabled = false, item_id, data = {} } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/categories/${item_id}`, data) : (data) => post(`/categories`, data);

    let data = new FormData();
    data.append("label", values.label.trim());

    // if (values.icon && typeof values.icon != "string") {
    //   data.append("icon", values.icon);
    // }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = "/categories";
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      label: data.label || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const label = (
    <Col>
      <CustomInput
        formik={formik}
        name="label"
        label={"Title"}
        placeholder="Enter category title"
        disabled={disabled}
      />
    </Col>
  );

  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {label}
            {submit_btn}
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

const CategoryForm = ({ disabled = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/categories/${props.match.params.id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Category"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Categories", href: "/categories" }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Category"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form data={data} item_id={editInfo ? props.match.params.id : undefined} disabled={disabled} />
        )}
      </div>
    </div>
  );
};

export default CategoryForm;
