import React, { useContext, useState, useEffect } from "react";
import { put, get, errorHandler } from "helpers/api_helper";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import CustomMobileInput from "@components/Common/CustomMobileInput";
import CustomDropzone from "@components/Common/CustomDropzone";
import CustomImagePreview from "@components/Common/CustomImagePreview";
import SpinnerLoader from "@components/Loader/SpinnerLoader";

const FormSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required()
    .label("First name"),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required()
    .label("Last name"),
  password: Yup.string().strict().trim().max(200),
  passport_number: Yup.string()
    .matches(/^[A-Za-z0-9 ]*$/, "Please enter valid Passport Number")
    .max(20)
    .required()
    .label("Passport Number"),
  email: Yup.string().strict().trim().required(yupMessages.required).max(200),
  mobile: Yup.string().required("required"),
  country_iso: Yup.number().required(yupMessages.required),
  profile_image: Yup.mixed()
    .required("Profile image is required.")
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
    ),
});

const PersonalInformation = ({ vendor, vendorId }) => {
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    get("vendor/profile/" + vendor._id)
      .then((res) => {
        const { result } = res;
        setData(result);
      })
      .finally(() => {
        setInitialLoader(false);
      });
  }, []);

  const handleSubmit = (values) => {
    setLoader(true);
    let request = (data) => put(`vendor/profile/${vendor._id}`, data);

    let data = new FormData();
    data.append("first_name", values.first_name.trim());
    data.append("last_name", values.last_name.trim());
    data.append("email", values.email);
    data.append("mobile", values.mobile);
    data.append("type", "business");
    data.append("country_iso", values.country_iso);
    if (values.password) {
      data.append("password", values.password);
    }
    data.append("passport_number", values.passport_number);

    if (values.profile_image && typeof values.profile_image != "string") {
      data.append("profile_image", values.profile_image);
    }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = `/vendors/view/${vendorId}?tab=10`;
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      type: data.type || "",
      mobile: data.mobile || "",
      country_iso: `${data.country_iso || ""}`,
      password: data.password || "",
      profile_image: data.profile_image_url || "",
      passport_number: data.passport_number || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const first_name = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="first_name"
        label={"First Name"}
        placeholder="Enter First Name"
        disabled={loader}
      />
    </Col>
  );

  const last_name = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="last_name"
        label={"Last Name"}
        placeholder="Enter Last Name"
        disabled={loader}
      />
    </Col>
  );

  const email = (
    <Col className="col-6">
      <CustomInput formik={formik} name="email" label={"Email"} placeholder="Enter Email" disabled={loader} />
    </Col>
  );

  const passport_number = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="passport_number"
        label="ID/Passport No"
        type="text"
        placeholder="Enter ID/Passport no"
        disabled={loader}
      />
    </Col>
  );
  const password = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        name="password"
        label="Password"
        type="text"
        placeholder="Enter password"
        disabled={loader}
      />
    </Col>
  );

  const mobile = (
    <CustomMobileInput
      label="Mobile"
      country_iso={formik.values.country_iso || 61}
      number={formik.values.mobile}
      handleChange={(phone, country) => {
        if (country.dialCode !== formik.values.country_iso) {
          formik.setFieldValue("country_iso", country.dialCode);
          formik.setFieldValue("mobile", "");
        } else {
          formik.setFieldValue("mobile", phone.slice(country.dialCode.length));
        }
      }}
      disabled={loader}
      errorMsg={formik.errors["mobile"]}
      className="col-6"
    />
  );

  const profile_image = (
    <Col className="col-12 my-2">
      <label className="mr-2">Profile Image</label>
      <Row>
        <Col className="col-12">
          <CustomImagePreview file={formik.values.profile_image} />
        </Col>
        {formik.errors && formik.errors.profile_image ? (
          <Col className="col-12">
            <span className="error">{formik.errors.profile_image}</span>
          </Col>
        ) : null}
        <Col className="col-12 mt-2">
          <CustomDropzone
            onChange={(acceptedFiles) => formik.setFieldValue("profile_image", acceptedFiles[0])}
            allowedTypes="image/*"
            multiple={false}
          />
        </Col>
      </Row>
    </Col>
  );

  const submit_btn = (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  if (initialLoader) {
    return <SpinnerLoader />;
  }

  return (
    <>
      <Card>
        <CardBody>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              {first_name}
              {last_name}
              {email}
              {mobile}
              {passport_number}
              {password}
              {profile_image}
              {submit_btn}
            </Row>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default PersonalInformation;
