/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/

import BootstrapTable from "react-bootstrap-table-next";
import React, { useState, useEffect, useContext } from "react";
import { errorHandler, getNew } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import ReactPaginate from "react-paginate";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { del, get } from "helpers/api_helper";
import { CustomAlertContext } from "@contexts/CustomAlertContext";
import Select from "react-select";
import { Col, Row, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { handleAppendQueryParam } from "@utils/index";

const PageSize = ({ onSizeChange = () => {}, disabled = false }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [size, setSize] = useState(10);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
  ];

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} disabled={disabled} direction="up">
      <DropdownToggle>
        {size} {<i className={dropdownOpen ? "mdi mdi-chevron-down" : "mdi mdi-chevron-up"} />}
      </DropdownToggle>
      <DropdownMenu>
        {sizePerPageList.map((item, index) => (
          <DropdownItem
            key={index}
            onClick={() => {
              setSize(item.value);
              onSizeChange(item.value);
            }}
          >
            {item.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const SearchBox = ({ placeholder = "Search by", onSearch = () => {} }) => {
  const [keyword, setKeyword] = useState("");

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSearch(keyword.toLowerCase());
      }}
    >
      <InputGroup style={{ height: "38px" }}>
        <div
          style={{ backgroundColor: "unset", height: "38px" }}
          className="input-group-text mdi mdi-close c-pointer"
          onClick={() => {
            setKeyword("");
            onSearch("");
          }}
        />
        <input
          type="text"
          className="form-control"
          id="autoSizingInputGroup"
          placeholder={placeholder}
          value={keyword}
          onChange={(event) => setKeyword(event.target.value)}
        />
        <div
          className="input-group-text mdi mdi-magnify c-pointer"
          onClick={() => onSearch(keyword.toLowerCase())}
          style={{ backgroundColor: "unset" }}
        />
      </InputGroup>
    </form>
  );
};

const TransactionsTypeSelection = ({ handleChange = () => {} }) => {
  const [state, setState] = useState({ label: "All Transactions", value: "" });

  let transactionsOptions = [
    { label: "All Transactions", value: "" },
    { label: "Debit Transactions", value: "debit" },
    { label: "Credit Transactions", value: "credit" },
  ];

  return (
    <Select
      value={state}
      onChange={(event) => {
        setState(event);
        handleChange(event.value);
      }}
      options={transactionsOptions}
    />
  );
};

const UserRoleSelection = ({ handleChange = () => {} }) => {
  const [state, setState] = useState({ label: "All Roles", value: "" });

  let roleOptions = [
    { label: "All Roles", value: "" },
    { label: "Vendor", value: "vendor" },
    { label: "Rider", value: "rider" },
    { label: "User", value: "user" },
  ];

  return (
    <Select
      value={state}
      onChange={(event) => {
        setState(event);
        handleChange(event.value);
      }}
      options={roleOptions}
    />
  );
};

const UserSelection = ({ handleChange = () => {} }) => {
  const [user, setUser] = useState({ label: "All Users", value: "" });
  const [usersList, setUsersList] = useState([]);
  useEffect(() => {
    get(`/consumers?roles=user,rider,vendor&page_size=10000`).then((res) => {
      const { result } = res;
      let userOptions = { options: [{ label: "All Users", value: "" }] };
      result.data.map((data) => {
        if (data.first_name)
          userOptions.options.push({
            label: data.first_name + (data.last_name ? " " + data.last_name : ""),
            value: data._id,
          });
      });
      setUsersList(userOptions);
    });
  }, []);

  return (
    <Select
      value={user}
      onChange={(event) => {
        setUser(event);
        handleChange(event.value);
      }}
      options={[usersList]}
    />
  );
};

const VendorSelection = ({ handleChange = () => {} }) => {
  const [vendor, setVendor] = useState({ label: "All Vendors", value: "" });
  const [vendorsList, setVendorsList] = useState([]);

  useEffect(() => {
    get(`/vendors?page_size=10000`).then((res) => {
      const { result } = res;
      let vendorOptions = { options: [{ label: "All Vendors", value: "" }] };
      result.data.map((data) => {
        vendorOptions.options.push({ label: data.business_name, value: data._id });
      });
      setVendorsList(vendorOptions);
    });
  }, []);

  return (
    <Select
      value={vendor}
      onChange={(event) => {
        setVendor(event);
        handleChange(event.value);
      }}
      options={[vendorsList]}
    />
  );
};

const CustomTable = ({
  columns = [],
  initialData = [],
  actions = false,
  edit = false,
  view = false,
  riderId = "",
  vendorId = "",
  section = "",
  role = "",
  remove = false,
  fetchUrl = "",
  pagination = false,
  searchPlaceholder = "",
  showVendorSelection = false,
  showUserSelection = false,
  showTransactionsTypeSelection = false,
  showUserRoleSelection = false,
  initialQueryParams = {},
}) => {
  const [list, setList] = useState(initialData);

  const customAlert = useContext(CustomAlertContext);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [activeFilterVendorId, setActiveFilterVendorId] = useState(vendorId);
  const [activeFilterUserId, setActiveFilterUserId] = useState("");
  const [activeFilterTransactionsType, setActiveFilterTransactionsType] = useState("");
  const [activeFilterUserRole, setActiveFilterUserRole] = useState(role);
  const [loading, setIsLoading] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [tempItem, setTempItem] = useState({});

  useEffect(() => {
    if (vendorId) setActiveFilterVendorId(vendorId);
  }, [vendorId]);

  useEffect(() => {
    setIsLoading(true);
    getPages(true);
  }, []);

  useEffect(() => {
    if (initialData.length) {
      setIsLoading(false);
      setList(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    getPages();
  }, [activeFilterTransactionsType, activeFilterUserId, activeFilterVendorId, activeFilterUserRole]);

  const getPages = (initial = false, page_no = currentPageNumber, page_size = pageSize, search = searchKey) => {
    if (fetchUrl) {
      let url = fetchUrl;

      url = url + ((url.includes("?") ? "&" : "?") + `page_no=${page_no}&page_size=${page_size}`);

      if (search) {
        url = handleAppendQueryParam(url, "search", encodeURIComponent(search));
      }
      if (riderId) {
        url = url + ((url.includes("?") ? "&" : "?") + `rider_id=${riderId}`);
      }

      if (activeFilterVendorId) {
        url = url + ((url.includes("?") ? "&" : "?") + `vendor_id=${activeFilterVendorId}`);
      }

      if (activeFilterUserId) {
        url = url + ((url.includes("?") ? "&" : "?") + `user_id=${activeFilterUserId}`);
      }

      if (activeFilterTransactionsType) {
        url = url + ((url.includes("?") ? "&" : "?") + `type=${activeFilterTransactionsType}`);
      }

      if (activeFilterUserRole) {
        url = url + ((url.includes("?") ? "&" : "?") + `roles=${activeFilterUserRole}`);
      }

      if (initialQueryParams && Object.keys(initialQueryParams).length) {
        Object.keys(initialQueryParams).map((key) => {
          url = handleAppendQueryParam(url, key, initialQueryParams[key]);
        });
      }

      getNew(url)
        .then((result) => {
          if (result.meta_data) {
            if (typeof result.meta_data.page_no == "number") {
              setCurrentPageNumber(result.meta_data.page_no);
            }

            if (typeof result.meta_data.total_pages == "number") {
              setTotalPageCount(result.meta_data.total_pages);
            }
          }
          if (result.data !== undefined) {
            setList(result.data || []);
            if (initial) setIsLoading(false);
          } else {
            setList(result || []);
            if (initial) setIsLoading(false);
          }
        })
        .catch((err) => {
          customAlert.setToastNotification(errorHandler(err), "error");
          setIsLoading(false);
        });
    }
  };

  const handleShowConfirmationModal = (item) => {
    setIsOpenConfirmationModal(true);
    setTempItem(item);
  };

  const handleDeleteConfirm = () => {
    setIsOpenConfirmationModal(false);
    let delRequestUrl = fetchUrl + `/${tempItem.id || tempItem._id}`;
    if (vendorId) {
      delRequestUrl = handleAppendQueryParam(delRequestUrl, "vendor_id", vendorId);
    }

    del(delRequestUrl)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        getPages();
      })
      .catch((err) => {
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };

  columns = [
    {
      dataField: "S.No.",
      text: "S.No.",
      formatter: (_cell, _row, rowIndex, _formatExtraData) => {
        let currentIndex = rowIndex + 1;

        if (currentPageNumber > 1) {
          return currentIndex + pageSize * (currentPageNumber - 1);
        } else {
          return currentIndex;
        }
      },
    },
    ...columns,
  ];

  if (actions) {
    columns.push({
      dataField: "actions",
      text: "Actions",
      formatter: (_cell, item, _rowIndex, _formatExtraData) => {
        return (
          <>
            {edit ? (
              <span title="Edit" className=" mx-2">
                {vendorId == "" ? (
                  <Link to={`/${section}/edit/${item._id}`} title="Edit">
                    <i className="ti-pencil ml-2"></i>
                  </Link>
                ) : (
                  <Link to={`/${section}/edit/${item._id}?vendor_id=${vendorId}`} title="Edit">
                    <i className="ti-pencil ml-2"></i>
                  </Link>
                )}
              </span>
            ) : null}
            {view ? (
              <span title="View" className="mx-2">
                {vendorId == "" ? (
                  <Link to={`/${section}/view/${item._id}`} title="View">
                    <i className="ti-eye"></i>
                  </Link>
                ) : (
                  <Link to={`/${section}/view/${item._id}?vendor_id=${vendorId}`} title="View">
                    <i className="ti-eye"></i>
                  </Link>
                )}
              </span>
            ) : null}
            {remove ? (
              <span title="Delete" className="mx-2">
                <i
                  title="Delete"
                  className="ti-trash c-pointer"
                  style={{ color: "#fdab00" }}
                  onClick={() => handleShowConfirmationModal(item)}
                ></i>
              </span>
            ) : null}
          </>
        );
      },
    });
  }

  if (loading) {
    return <SpinnerLoader local={true} />;
  }
  return (
    <>
      {searchPlaceholder ||
      showVendorSelection ||
      showUserSelection ||
      showUserRoleSelection ||
      showTransactionsTypeSelection ? (
        <Row style={{ display: "flex", justifyContent: "end" }}>
          {showTransactionsTypeSelection ? (
            <Col md={2} style={{ paddingLeft: "0px" }}>
              <TransactionsTypeSelection
                handleChange={(value) => {
                  setCurrentPageNumber(1);
                  setActiveFilterTransactionsType(value);
                }}
              />
            </Col>
          ) : null}
          {showUserSelection ? (
            <Col md={2} style={{ paddingLeft: "0px" }}>
              <UserSelection
                handleChange={(value) => {
                  setCurrentPageNumber(1);
                  setActiveFilterUserId(value);
                }}
              />
            </Col>
          ) : null}
          {showUserRoleSelection ? (
            <Col md={2} style={{ paddingLeft: "0px" }}>
              <UserRoleSelection
                handleChange={(value) => {
                  setCurrentPageNumber(1);
                  setActiveFilterUserRole(value);
                }}
              />
            </Col>
          ) : null}
          {showVendorSelection ? (
            <Col md={3} style={{ paddingLeft: "0px" }}>
              <VendorSelection
                handleChange={(value) => {
                  setCurrentPageNumber(1);
                  setActiveFilterVendorId(value);
                }}
              />
            </Col>
          ) : null}
          {searchPlaceholder ? (
            <Col md={4} style={{ paddingLeft: "0px" }}>
              <SearchBox
                placeholder={searchPlaceholder}
                onSearch={(searchKey) => {
                  setSearchKey(searchKey);
                  getPages(false, 1, pageSize, searchKey);
                }}
              />
            </Col>
          ) : null}
        </Row>
      ) : null}
      <BootstrapTable
        keyField="id"
        data={list}
        columns={columns}
        striped
        hover
        wrapperClasses="table-responsive my-2"
        noDataIndication={"No data available."}
      />
      {fetchUrl ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {pagination ? null : (
            <PageSize
              onSizeChange={(sizePerPage) => {
                setPageSize(sizePerPage);
                setCurrentPageNumber(1);
                getPages(false, 1, sizePerPage);
              }}
              disabled={!list.length}
            />
          )}
          {totalPageCount > 1 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={(event) => {
                getPages(false, event.selected + 1);
              }}
              pageRangeDisplayed={4}
              pageCount={totalPageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              activeClassName="active"
              activeLinkClassName="active-link"
              previousClassName="page"
              previousLinkClassName="page-link"
              nextClassName="page"
              nextLinkClassName="page-link"
            />
          )}
          {remove ? (
            <ConfirmationModal
              isOpenModal={isOpenConfirmationModal}
              toggleModal={setIsOpenConfirmationModal}
              deleteConfirmItem={handleDeleteConfirm}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default CustomTable;
