import React, { useState, useEffect, useContext } from "react";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import CustomImagePreview from "@components/Common/CustomImagePreview";
import CustomDropzone from "@components/Common/CustomDropzone";
import { selectField } from "components/Common/Form/InputFields";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getVendorId } from "@utils/index";

const FormSchema = Yup.object().shape({
  type: Yup.string().strict().trim().required(yupMessages.required).max(200),
  tag_id: Yup.string().strict().trim().required(yupMessages.required).max(200),
  title: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid Service Name")
    .max(30)
    .required()
    .label("Service name"),
  // title: Yup.string().strict().required(yupMessages.required).max(200),
  price: Yup.number().min(0).required(yupMessages.required).max(100000),
  icon: Yup.mixed()
    .required("Required *")
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => (value && value.type && value.type.indexOf("image") > -1) || typeof value === "string"
    ),
});

const Form = (props) => {
  let vendor_id = getVendorId();
  const { disabled = false, item_id, data = {}, tagData = [{}] } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);
  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id ? (data) => put(`/services/${item_id}`, data) : (data) => post(`/services`, data);

    let data = new FormData();

    data.append("title", values.title.trim());
    data.append("type", values.type);
    data.append("tag_id", values.tag_id);
    data.append("price", values.price);
    data.append("vendor_id", vendor_id);

    if (values.icon && typeof values.icon != "string") {
      data.append("icon", values.icon);
    }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = `/vendors/view/${vendor_id}?tab=2`;
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        let msg = errorHandler(err);
        customAlert.setToastNotification(msg, "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      title: data.title || "",
      type: data.type || "",
      tag_id: data.tag_id || "",
      price: data.price || "",
      description: data.description || "",
      icon: data.icon_url || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  let options = [
    { name: "Commercial", value: "commercial" },
    { name: "Interstate", value: "interstate" },
    { name: "Local", value: "local" },
  ];

  const type = (
    <Col className="col-6 mt-1">{selectField(formik, "type", "Type", options, "Please select type", disabled)}</Col>
  );

  const title = (
    <Col className="col-6">
      <CustomInput formik={formik} name="title" label={"Name"} placeholder="Enter title" disabled={disabled} />
    </Col>
  );

  const price = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        type="number"
        min={0}
        name="price"
        label={"Price"}
        placeholder="Enter price"
        disabled={disabled}
      />
    </Col>
  );

  const tagId = (
    <Col className="col-6 mt-1">{selectField(formik, "tag_id", "Tag", tagData, "Please select tag", disabled)}</Col>
  );
  const icon = (
    <Col className="col-12">
      <label className="mr-2">Icon</label>
      <Row>
        <Col className="col-12">
          <CustomImagePreview file={formik.values.icon} />
        </Col>
        {formik.errors && formik.errors.icon ? (
          <Col className="col-12">
            <span className="error">{formik.errors.icon}</span>
          </Col>
        ) : null}
        {disabled ? null : (
          <Col className="col-12 mt-2">
            <CustomDropzone
              onChange={(acceptedFiles) => formik.setFieldValue("icon", acceptedFiles[0])}
              allowedTypes="image/*"
              multiple={false}
            />
          </Col>
        )}
      </Row>
    </Col>
  );
  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );

  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {type}
            {title}
            {price}
            {tagId}
            {icon}
            {submit_btn}
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

const ServicesForm = ({ disabled = false, isAdmin = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);

  let vendor_id = getVendorId();

  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/services/${props.match.params.id}?vendor_id=${vendor_id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
    get(`/tags`).then((res) => {
      const { result } = res;
      result.data.map((data) => setTagOptions((prev) => [...prev, { name: data.label, value: data._id }]));
    });
  }, []);
  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Service"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Vendor", href: `/vendors/view/${vendor_id}` }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Service"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form
            data={data}
            item_id={editInfo ? props.match.params.id : undefined}
            disabled={disabled}
            isAdmin={isAdmin}
            tagData={tagOptions}
          />
        )}
      </div>
    </div>
  );
};

export default ServicesForm;
