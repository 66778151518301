import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "@components/Common/Breadcrumb";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { errorHandler, get, post, put } from "helpers/api_helper";
import SpinnerLoader from "@components/Loader/SpinnerLoader";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import { selectField } from "components/Common/Form/InputFields";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getVendorId } from "@utils/index";
import CustomSwitch from "@components/Common/Form/CustomSwitch";

const Form = (props) => {
  let vendor_id = getVendorId();
  const { disabled = false, item_id, data = {}, isId } = props;
  const customAlert = useContext(CustomAlertContext);
  const [loader, setLoader] = useState(false);

  function addDays(date, number) {
    const newDate = new Date(date);
    return new Date(newDate.setDate(newDate.getDate() + number));
  }

  const FormSchema = Yup.object().shape({
    code: Yup.string()
      .strict()
      .trim()
      .required(yupMessages.required)
      .max(20)
      .matches(/^[a-zA-Z-0-9]+$/, "Code Shouldn't contain special characters"),
    type: Yup.string().strict().trim().required(yupMessages.required).max(200),
    label: Yup.string().strict().required(yupMessages.required).max(50, "Title must be at most 50 characters"),
    value: Yup.number()
      .positive()
      .required(yupMessages.required)
      .when("type", {
        is: "percentage",
        then: Yup.number().max(100),
      }),
    max_discount_value: Yup.number()
      .positive()
      .required(yupMessages.required)
      .when("type", {
        is: "percentage",
        then: Yup.number().max(100),
      })
      .label("Max Discount Value"),
    max_redeemable_count: Yup.number()
      .positive()
      .required(yupMessages.required)
      .max(5000000)
      .label("Max Redeemable Count"),
    started_at: Yup.date().required(yupMessages.required),
    expired_at: Yup.date().required(yupMessages.required),
    min_cart_value: Yup.number().positive().required(yupMessages.required).max(5000000).label("Min cart value "),
    status: isId ? Yup.boolean().required(yupMessages.required) : null,
  });

  const handleSubmit = (values) => {
    setLoader(true);
    let request = item_id
      ? (data) => put(`/offers/${item_id}?vendor_id=${vendor_id}`, data)
      : (data) => post(`/offers`, data);

    let data = new FormData();
    data.append("label", values.label);
    data.append("type", values.type);
    data.append("value", values.value);
    data.append("code", values.code);
    isId ? data.append("status", values.status) : null;
    data.append("max_discount_value", values.max_discount_value);
    data.append("max_redeemable_count", values.max_redeemable_count);
    data.append("started_at", values.started_at); //.toISOString()
    data.append("expired_at", values.expired_at);
    data.append("min_cart_value", values.min_cart_value);

    if (!item_id) {
      data.append("vendor_id", vendor_id);
    }

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.location.href = `/vendors/view/${vendor_id}?tab=3`;
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };
  const formik = useFormik({
    initialValues: {
      label: data.label || "",
      type: data.type || "",
      value: data.value || "",
      code: data.code || "",
      max_discount_value: data.max_discount_value || "",
      max_redeemable_count: data.max_redeemable_count || "",
      started_at: data.started_at || new Date(),
      expired_at: data.expired_at || addDays(new Date(), 30),
      min_cart_value: data.min_cart_value || "",
      status: typeof data.status === "boolean" ? data.status : false,
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  let options = [
    { name: "percentage", value: "percentage" },
    { name: "flat", value: "flat" },
  ];

  const type = (
    <Col className="col-6 mt-1">{selectField(formik, "type", "Type", options, "Please select type", disabled)}</Col>
  );
  const status = isId ? (
    // <Row style={{ marginTop: "4px" }}>
    <Col className="col-4" style={{ textAlign: "left" }}>
      <CustomSwitch name={"status"} formik={formik} disabled={disabled} label="Activate" className="my-1" />
    </Col>
  ) : null; // </Row>;

  const value = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        type="number"
        name="value"
        label={"Value"}
        placeholder="Enter Value"
        disabled={disabled}
      />
    </Col>
  );

  const label = (
    <Col className="col-6 ">
      <CustomInput formik={formik} name="label" label={"Title"} placeholder="Enter title" disabled={disabled} />
    </Col>
  );

  const code = (
    <Col className="col-6">
      <CustomInput formik={formik} name="code" label={"Code"} placeholder="Enter code" disabled={disabled} />
    </Col>
  );
  const max_discount_value = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        type="number"
        name="max_discount_value"
        label={"Max discount value"}
        placeholder="Enter Max discount value"
        disabled={disabled}
      />
    </Col>
  );
  const max_redeemable_count = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        type="number"
        name="max_redeemable_count"
        label={"Max redeemable count"}
        placeholder="Enter Max redeemable count"
        disabled={disabled}
      />
    </Col>
  );

  const started_at = (
    <Col className="col-6">
      <Label>Starting date</Label>
      <DatePicker
        minDate={new Date()}
        dateFormat="dd-MMM-yyyy"
        formik={formik}
        name="started_at"
        selected={new Date(formik.values.started_at)}
        onChange={(date) => {
          formik.setFieldValue("started_at", date);
          if (new Date(formik.values.started_at).getTime() < date.getTime()) {
            formik.setFieldValue("expired_at", date);
          }
        }}
        disabled={disabled}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    </Col>
  );

  const expired_at = (
    <Col className="col-6">
      <Label className="mt-6">Expiry date</Label>
      <DatePicker
        minDate={new Date(formik.values.started_at)}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        maxDate={new Date(2030, 1, 29)}
        dateFormat="dd-MMM-yyyy"
        formik={formik}
        name="expired_at"
        selected={new Date(formik.values.expired_at)}
        onChange={(date) => formik.setFieldValue("expired_at", date)}
        disabled={disabled}
      />
    </Col>
  );
  const min_cart_value = (
    <Col className="col-6">
      <CustomInput
        formik={formik}
        type="number"
        name="min_cart_value"
        label={"Min cart value"}
        placeholder="Enter Min cart value"
        disabled={disabled}
      />
    </Col>
  );

  const submit_btn = disabled ? null : (
    <Col className="col-12">
      <Button type="submit" color="primary" className="mt-4" disabled={loader}>
        Submit
      </Button>
    </Col>
  );
  return (
    <Card>
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {started_at}
            {expired_at}
            {type}
            {value}
            {label}
            {code}
            {max_discount_value}
            {max_redeemable_count}
            {min_cart_value}
            {status}
            {submit_btn}
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

const OffersForm = ({ disabled = false, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editInfo, setEditInfo] = useState(false);

  let vendor_id = getVendorId();
  useEffect(() => {
    if (props.match.params.id && props.match.params.id !== "add") {
      if (!disabled) {
        setEditInfo(true);
      }
      setLoading(true);

      get(`/offers/${props.match.params.id}?vendor_id=${vendor_id}`).then((res) => {
        const { result } = res;
        setData(result);
        setLoading(false);
      });
    }
  }, []);

  return (
    <div className="page-content">
      <CustomMetaTags title={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Offer"} />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Vendor", href: `/vendors/view/${vendor_id}` }}
          breadcrumbItem={(editInfo ? "Edit" : disabled ? "View" : "Add") + " Offer"}
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Form
            data={data}
            item_id={editInfo ? props.match.params.id : undefined}
            isId={props.match.params.id}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default OffersForm;
