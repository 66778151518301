import React from "react";
import { Card, CardBody, Row } from "reactstrap";
import ListTopBar from "@components/Common/ListTopBar";
import { getFormattedDate } from "@utils/index";
import BootstrapTable from "react-bootstrap-table-next";

const Columns = [
  {
    dataField: "S.No.",
    text: "S. No.",
    formatter: (_cell, _row, rowIndex) => {
      return <p key={rowIndex}>{++rowIndex}</p>;
    },
  },
  {
    dataField: "promotion_id",
    text: "Promotion",
    formatter: (_cell) => <a href={`/promotions/view/${_cell}`}>{_cell}</a>,
  },
  {
    dataField: "pay_per_click_amt",
    text: "Per Click Amount",
  },
  {
    dataField: "total_amount",
    text: "Total Amount",
  },
  {
    dataField: "started_at",
    text: "Started date",
    formatter: (_cell) => getFormattedDate(_cell),
  },
  {
    dataField: "expired_at",
    text: "Expired date",
    formatter: (_cell) => getFormattedDate(_cell),
  },
];

const SubscribeList = ({ vendor }) => (
  <Card>
    <CardBody>
      <ListTopBar btnLabel="Add More Subscription" btnRedirect={"/promotions"} />
      <Row>
        {vendor?.promotions ? (
          <BootstrapTable
            keyField="id"
            data={vendor.promotions}
            columns={Columns}
            striped
            hover
            wrapperClasses="table-responsive my-2"
            noDataIndication={"No data found."}
          />
        ) : null}
      </Row>
    </CardBody>
  </Card>
);

export default SubscribeList;
