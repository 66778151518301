import React, { useState, useContext } from "react";
import { errorHandler, put } from "helpers/api_helper";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useFormik } from "formik";
import { CustomAlertContext } from "contexts";
import { yupMessages } from "helpers/validations/messages";
import { CustomInput } from "@components/Common/Form";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import VendorProfileUpdate from "./VendorProfileUpdate";
import * as Yup from "yup";
// import YupPassword from "yup-password";
// YupPassword(Yup);

const FormSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must have 1 uppercase, 1 lowercase, 1 special character and 1 number.")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("New password is required"),
  confirm_password: Yup.string().oneOf([Yup.ref("password"), null], "Confirm Password & New Password must be same."),
  old_password: Yup.string().strict().trim().required(yupMessages.required).max(50),
});

const ProfileForm = (props) => {
  const { disabled = false, data = {} } = props;
  const customAlert = useContext(CustomAlertContext);

  const [loader, setLoader] = useState(false);
  const handleSubmit = (values) => {
    let request = (data) => put(`/update_password`, data);
    let data = new FormData();
    data.append("old_password", values.old_password);
    data.append("password", values.password);
    data.append("confirm_password", values.confirm_password);

    request(data)
      .then((res) => {
        customAlert.setToastNotification(res.message);
        setTimeout(() => {
          setLoader(false);
          window.history.back();
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        customAlert.setToastNotification(errorHandler(err), "error");
      });
  };

  const formik = useFormik({
    initialValues: {
      old_password: data.old_password || "",
      password: data.password || "",
      confirm_password: data.confirm_password || "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const old_password = (
    <Col className="col-4">
      <CustomInput
        formik={formik}
        name="old_password"
        label={"Current Password"}
        placeholder="Enter current password"
        disabled={disabled}
      />
    </Col>
  );

  const password = (
    <Col className="col-4">
      <CustomInput formik={formik} name="password" label={"New Password"} placeholder="Enter new password" />
    </Col>
  );
  const confirm_password = (
    <Col className="col-4">
      <CustomInput
        formik={formik}
        name="confirm_password"
        label={"Confirm Password"}
        placeholder="Enter confirm password"
      />
    </Col>
  );

  const submit_btn = disabled ? null : (
    <div>
      <Button style={{ float: "right" }} type="submit" color="primary" className="mt-4" disabled={loader}>
        Update Password
      </Button>
    </div>
  );

  return (
    <div className="page-content">
      <CustomMetaTags title={"Edit" + " Profile"} />
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={" My Profile"} />
        <VendorProfileUpdate />
        <Card>
          <CardBody>
            <h5>Change password</h5>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                {old_password}
                {password}
                {confirm_password}
                {submit_btn}
              </Row>
            </form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
export default ProfileForm;
