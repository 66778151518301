// import { useState } from "react"
import { Input } from "reactstrap";
import RichTextEditor from "./RichTextEditor";

export const textField = (
  formik,
  name,
  label,
  placeholder = false,
  // className = false,
  type = "text",
  obj = false,
  disabled = false
) => {
  let field_name = name;
  let field_value = formik.values[name];
  let field_error = formik.errors ? formik.errors[name] : "";
  if (obj && typeof obj.index == "number") {
    name = `${obj.p_name}[${obj.index}].${[obj.sub_name]}`;

    field_value =
      formik.values[obj.p_name] && formik.values[obj.p_name][obj.index]
        ? formik.values[obj.p_name][obj.index][obj.sub_name]
        : field_value;
    field_error =
      formik.errors && formik.errors[obj.p_name] && formik.errors[obj.p_name][obj.index]
        ? formik.errors[obj.p_name][obj.index][obj.sub_name]
        : field_error;
  } else if (obj && !obj.index) {
    name = [obj.p_name][obj.sub_name];
    field_value = formik.values[obj.p_name] ? formik.values[obj.p_name][obj.sub_name] : field_value;
    field_error = formik.errors && formik.errors[obj.p_name] ? formik.errors[obj.p_name][obj.sub_name] : field_error;
  }

  return (
    <div className="mb-3">
      <label htmlFor={field_name}>{label}</label>
      <input
        name={field_name}
        type={type}
        onChange={formik.handleChange}
        value={field_value}
        placeholder={placeholder}
        className="form-control "
        min={0}
        disabled={disabled}
      />
      <span className="error">{field_error}</span>
    </div>
  );
};

export const richTextField = (
  formik,
  name,
  label,
  placeholder = false
  // className = false,
  // type = "text"
) => {
  return (
    <RichTextEditor
      formik={formik}
      name={name}
      // onChange={formik.handleChange}
      // value={formik.values[name]}
      placeholder={placeholder}
      // className="form-control "
      label={label}
    />
  );
};

export const selectField = (
  formik,
  name,
  label,
  options,
  placeholder = false,
  disabled = false
  // className = false
) => {
  return (
    <div className="mb-1">
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="form-control"
        disabled={disabled}
      >
        <option value="">{placeholder}</option>
        {options.map((op, i) => (
          <option key={i} value={op.value}>
            {op.name}
          </option>
        ))}
      </select>
      {formik.errors[name] && <span className="error">{formik.errors[name]}</span>}
    </div>
  );
};

export const textArea = (
  formik,
  name,
  label,
  // placeholder = false,
  // className = false,
  // type = "text",
  obj = false
  // disabled = false
) => {
  let field_name = name;
  // let field_value = formik.values[name]
  let field_error = formik.errors ? formik.errors[name] : "";
  if (obj && typeof obj.index == "number") {
    name = `${obj.p_name}[${obj.index}].${[obj.sub_name]}`;

    // field_value =
    //   formik.values[obj.p_name] && formik.values[obj.p_name][obj.index]
    //     ? formik.values[obj.p_name][obj.index][obj.sub_name]
    //     : field_value
    field_error =
      formik.errors && formik.errors[obj.p_name] && formik.errors[obj.p_name][obj.index]
        ? formik.errors[obj.p_name][obj.index][obj.sub_name]
        : field_error;
  } else if (obj && !obj.index) {
    name = [obj.p_name][obj.sub_name];
    // field_value = formik.values[obj.p_name]
    //   ? formik.values[obj.p_name][obj.sub_name]
    //   : field_value
    field_error = formik.errors && formik.errors[obj.p_name] ? formik.errors[obj.p_name][obj.sub_name] : field_error;
  }
  // const [textareabadge, settextareabadge] = useState(0)
  // const [textcount, settextcount] = useState(0)
  // function textareachange(event) {
  //   const count = event.target.value.length
  //   if (count > 0) {
  //     settextareabadge(true)
  //   } else {
  //     settextareabadge(false)
  //   }
  //   settextcount(event.target.value.length)
  // }
  return (
    <>
      <label htmlFor={field_name}>{label}</label>
      <Input
        type="textarea"
        id="textarea"
        // onChange={e => {
        //   // textareachange(e)
        // }}
        name={field_name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        maxLength="225"
        rows="3"
        placeholder="Enter you Answer."
      />
      {/* {textareabadge ? (
        <span className="badgecount badge badge-success">
          {" "}
          {textcount} / 225{" "}
        </span>
      ) : null} */}
      <span className="error">{field_error}</span>
    </>
  );
};
