import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomTable from "@components/Common/Table";
import ListTopBar from "@components/Common/ListTopBar";
import { getFullDetails } from "@utils/index";

const Jobs = (props) => {
  const columns = [
    {
      dataField: "title",
      text: "Title",
      formatter: (_cell, _row) => {
        return getFullDetails("jobs", _row._id, _cell, props.vendorId);
      },
    },
    {
      dataField: "total_applicants",
      text: "Applicant's Count",
    },
  ];

  return (
    <Card>
      <CardBody>
        <ListTopBar btnRedirect={`/jobs/add?vendor_id=${props.vendorId}`} />
        <CustomTable
          columns={columns}
          actions={true}
          edit={true}
          view={true}
          remove={true}
          vendorId={props.vendorId}
          section="jobs"
          fetchUrl="/jobs"
        />
      </CardBody>
    </Card>
  );
};

export default Jobs;
