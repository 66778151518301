import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";

const TotalCountCard = ({ icon, label, count = 0, redirectLink }) => (
  <Col xl={4} md={6}>
    <Link to={redirectLink}>
      <Card className="mini-stat card-background-color text-dark">
        <CardBody>
          <div className="">
            <div className="float-start mini-stat-img me-4 bg-white">
              <img src={icon} alt="" />
            </div>
            <h5 className="font-size-16 text-uppercase mt-0 text-dark">{label}</h5>
            <h4 className="fw-bold font-size-24">{count || 0}</h4>
          </div>
          <div className="pt-2">
            <div className="float-end">
              <i className="mdi mdi-arrow-right h5"></i>
            </div>
          </div>
        </CardBody>
      </Card>
    </Link>
  </Col>
);

export default TotalCountCard;
