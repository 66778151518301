import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import VendorSidebarContent from "./VendorSidebarContent";
import AdminSidebarContent from "./AdminSidebarContent";

const Sidebar = (props) => (
  <React.Fragment>
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        {props.role == "admin" ? <AdminSidebarContent /> : <VendorSidebarContent />}
      </div>
    </div>
  </React.Fragment>
);

Sidebar.propTypes = {
  type: PropTypes.string,
};

export default withRouter(withTranslation()(Sidebar));
