import React from "react";
import Breadcrumb from "components/Common/Breadcrumb";
import TransactionsList from "./TransactionsList";
import CustomMetaTags from "@components/Common/CustomMetaTags";

const Transactions = () => {
  return (
    <>
      <div className="page-content">
        <CustomMetaTags title="Transactions" />
        <div className="container-fluid">
          <Breadcrumb title={"Transactions"} breadcrumbItem="Transactions" />
          <TransactionsList items={[]} status={false} />
        </div>
      </div>
    </>
  );
};

export default Transactions;
