import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

// import images
import TotalOrdersIcon from "@assets/images/services-icon/totalOrders.png";
import TotalCustomerIcon from "@assets/images/services-icon/totalCustomer.png";
import DeliveredOrdersIcon from "@assets/images/services-icon/deliveredOrders.png";
import DeliveryPartnersIcon from "@assets/images/services-icon/deliveryPartners.png";
import TotalSellIcon from "@assets/images/services-icon/totalSell.png";
import CancelOrdersIcon from "@assets/images/services-icon/cancelOrders.png";
import TotalVendorIcon from "@assets/images/services-icon/totalVendor.png";
import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next";

import LineAreaChart from "@components/Common/LineAreaChart";

import TotalCountCard from "@components/TotalCountCard";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import { UserContext } from "@contexts";

const AdminDashboard = () => {
  const { getAdminDashboardStatics, dashboardStatics } = useContext(UserContext);

  useEffect(() => {
    getAdminDashboardStatics();
  }, []);

  const count_cards = (
    <Row>
      <TotalCountCard
        icon={TotalSellIcon}
        count={dashboardStatics?.today_total_sell}
        label={"Total Sell"}
        redirectLink={"/orders"}
      />
      <TotalCountCard
        icon={TotalOrdersIcon}
        count={dashboardStatics?.total_order}
        label={"Total Orders"}
        redirectLink={"/orders"}
      />
      <TotalCountCard
        icon={DeliveredOrdersIcon}
        count={dashboardStatics?.total_delivered_orders}
        label={"Total Delivered Orders"}
        redirectLink={"/orders"}
      />
      <TotalCountCard
        icon={CancelOrdersIcon}
        count={dashboardStatics.total_cancelled_orders}
        label={"Total Cancelled Orders"}
        redirectLink={"/orders"}
      />
      <TotalCountCard
        icon={TotalCustomerIcon}
        count={dashboardStatics.total_customer}
        label={"Total Customers"}
        redirectLink={"/consumers"}
      />
      <TotalCountCard
        icon={DeliveryPartnersIcon}
        count={dashboardStatics.total_delivery_partners}
        label={"Total Delivery Partners"}
        redirectLink={"/riders"}
      />
      <TotalCountCard
        icon={TotalVendorIcon}
        count={dashboardStatics.total_vendors}
        label={"Total Vendors"}
        redirectLink={"/vendors"}
      />
    </Row>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <CustomMetaTags title="Dashboard" />
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title text-dark"> Dashboard</h6>
              </Col>
            </Row>
            {count_cards}
            <Row>
              <Col xl={9}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4 text-dark"> Earning</h4>
                    <Row>
                      <Col lg={12}>
                        <div>
                          <LineAreaChart data={dashboardStatics} />
                        </div>
                      </Col>
                    </Row>
                    <h4 style={{ textAlign: "center" }} className="card-title mb-4 text-dark">
                      Months
                    </h4>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

AdminDashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AdminDashboard);
