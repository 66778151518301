import React from "react";
import { Card, CardBody } from "reactstrap";
import { UserContext } from "contexts";
import CustomTable from "@components/Common/Table";
import CustomMetaTags from "@components/Common/CustomMetaTags";
import Breadcrumb from "@components/Common/Breadcrumb";
import { getPaymentStatusChip } from "@pages/Quotations/QuotationsList";
import { getFullDetails } from "@utils/index";
import { getFormattedDate } from "@utils/index";
import Refund from "./Refund";
const adminColumns = [
  {
    dataField: "order_ref_no",
    text: "Orders No.",
    formatter: (_cell, _row) => {
      return getFullDetails("orders", _row._id, _cell);
    },
  },
  {
    dataField: "sender.pickup_time",
    text: "Pickup Schedule",
    formatter: (_cell) => {
      return getFormattedDate(_cell, true);
    },
  },
  {
    dataField: "status",
    text: "Order Status",
    formatter: (_cell) => {
      if (_cell == "delivered") {
        return getPaymentStatusChip("accepted", _cell);
      } else if (_cell == "cancelled") {
        return getPaymentStatusChip("declined", _cell);
      } else if (_cell == "picked-up") {
        return getPaymentStatusChip("sent", _cell);
      }
      return getPaymentStatusChip("confirmed", _cell);
    },
  },
  {
    dataField: "items",
    text: "Item's",
    formatter: (_cell) => {
      return <p>{_cell.length}</p>;
    },
  },
  {
    dataField: "vendor",
    text: "Vendor",
    formatter: (_cell) => {
      if (_cell?.length) {
        return <p style={{ textTransform: "capitalize" }}>{_cell[0].business_name}</p>;
      } else {
        return <p>Not available</p>;
      }
    },
  },
  {
    dataField: "payment",
    text: "Vendor Payment",
    formatter: (_cell, _row) => {
      if (_row.status == "cancelled") {
        return "Not Applicable";
      }

      if (_row.pay_vendor_status) {
        return getPaymentStatusChip("accepted", "Paid (" + (_row.payment.vendor_transferable_amount || 0) + ")");
      }

      if (_row.vendor_transfer_error_msg) {
        return getPaymentStatusChip("declined", "Failed");
      }

      return getPaymentStatusChip("sent", "Pending (" + (_row.payment.vendor_transferable_amount || 0) + ")");
    },
  },
  {
    dataField: "_id",
    text: "Refund",
    formatter: (_cell, _row) => <Refund id={_cell} order={_row} />,
  },
];
const vendorColumns = [
  {
    dataField: "order_ref_no",
    text: "Orders No.",
    formatter: (_cell, _row) => {
      return getFullDetails("orders", _row._id, _cell);
    },
  },
  {
    dataField: "sender.pickup_time",
    text: "Pickup Schedule",
    formatter: (_cell) => {
      return getFormattedDate(_cell, true);
    },
  },
  {
    dataField: "items",
    text: "Item's",
    formatter: (_cell) => {
      return <p>{_cell?.length}</p>;
    },
  },
  {
    dataField: "status",
    text: "Order Status",
    formatter: (_cell) => {
      if (_cell == "delivered") {
        return getPaymentStatusChip("accepted", _cell);
      } else if (_cell == "cancelled") {
        return getPaymentStatusChip("declined", _cell);
      } else if (_cell == "picked-up") {
        return getPaymentStatusChip("sent", _cell);
      }
      return getPaymentStatusChip("confirmed", _cell);
    },
  },
  {
    dataField: "payment",
    text: "Vendor Payment",
    formatter: (_cell, _row) => {
      if (_row.status == "cancelled") {
        return "Not Applicable";
      }

      if (_row.pay_vendor_status) {
        return getPaymentStatusChip("accepted", "Paid (" + (_row.payment.vendor_transferable_amount || 0) + ")");
      }

      if (_row.vendor_transfer_error_msg) {
        return getPaymentStatusChip("declined", "Failed");
      }

      return getPaymentStatusChip("sent", "Pending (" + (_row.payment.vendor_transferable_amount || 0) + ")");
    },
  },
  {
    dataField: "payment",
    text: "Vendor Payment",
    formatter: (_cell, _row) => {
      if (_row.status == "cancelled") {
        return "Not Applicable";
      }

      if (_row.pay_vendor_status) {
        return getPaymentStatusChip("accepted", "Paid (" + (_row.payment.vendor_transferable_amount || 0) + ")");
      }

      if (_row.vendor_transfer_error_msg) {
        return getPaymentStatusChip("declined", "Failed");
      }

      return getPaymentStatusChip("sent", "Pending (" + (_row.payment.vendor_transferable_amount || 0) + ")");
    },
  },
];

const Orders = (props) => {
  let { user, isAdmin } = React.useContext(UserContext);

  const title = "Orders";
  return (
    <>
      {props.riderId ? (
        <Card>
          <CardBody>
            <CustomTable
              columns={isAdmin() ? adminColumns : vendorColumns}
              actions={true}
              view={true}
              riderId={props.riderId}
              section="orders"
              fetchUrl={`/orders`}
              searchPlaceholder="Search by order number"
              vendorId={isAdmin() ? "" : user.vendor_id}
            />
          </CardBody>
        </Card>
      ) : (
        <div className="page-content">
          {<CustomMetaTags title={title} />}
          <div className="container-fluid">
            {<Breadcrumb breadcrumbItem={title} />}
            <Card>
              <CardBody>
                <CustomTable
                  columns={isAdmin() ? adminColumns : vendorColumns}
                  actions={true}
                  view={true}
                  riderId={props.riderId}
                  section="orders"
                  fetchUrl={`/orders`}
                  searchPlaceholder="Search by order number"
                  showVendorSelection={isAdmin()}
                  vendorId={isAdmin() ? "" : user.vendor_id}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default Orders;
